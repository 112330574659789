import React, { useState } from "react";
import DetailsVotesPageResultGridTable from "../DetailsVotesPageResultGridTable/DetailsVotesPageResultGridTable";
import DetailsVotesPageResultVotesCardQuestionGraphGrid from "../DetailsVotesPageResultVotesCardQuestionGraph/DetailsVotesPageResultVotesCardQuestionGraphGrid";
import {useGetComments} from "../../hooks/useGetComments";
import DetailsVoteResultsComments from "../DetailsVoteResultsComments/DetailsVoteResultsComments";

const DetailsVotesPageResultQuestionGrid = (props) => {
  const { question, constants, commentsSpecialOpinion } = props;

  const [graphResult, setGraphResult] = useState(true);
  const [tableResult, setTableResult] = useState(false);
  const comment = useGetComments(commentsSpecialOpinion, question.id);

  function toggleGraphShow() {
    setGraphResult(true);
    setTableResult(false);
  }

  function toggleTableShow() {
    setTableResult(true);
    setGraphResult(false);
  }

  return (
    <div className="details-votes-page-result-question-grid">
      <div className="details-votes-page-result-question-grid__title-container">
        <h3 className="details-votes-page-result-question-grid__title">
          {question.title}
        </h3>
        <h5 className="details-votes-page-result-question-grid__rule">
          {constants.DETAILS_VOTE.RESULTS_SELECT_VARIANTS_ANSWER}
        </h5>
      </div>
      <div className="details-votes-page-result-votes-card__switch-table-gistogramma">
        <div
          onClick={toggleGraphShow}
          className={
            graphResult
              ? "switch-table-gistogramma__gistogramma active"
              : "switch-table-gistogramma__gistogramma"
          }
        ></div>
        <div
          onClick={toggleTableShow}
          className={
            tableResult
              ? "switch-table-gistogramma__table active"
              : "switch-table-gistogramma__table"
          }
        ></div>
      </div>
      {tableResult && (
        <div className="details-votes-page-result-question-grid__tables-container">
          {question.answers.map((answer) => (
            <DetailsVotesPageResultGridTable key={answer.id} answer={answer} />
          ))}
        </div>
      )}
      {graphResult && (
        <DetailsVotesPageResultVotesCardQuestionGraphGrid
          answersTemplateGrid={question.answers}
        />
      )}
        {comment && comment.length !== 0 && <DetailsVoteResultsComments comment={comment}/>}
    </div>
  );
};
export default DetailsVotesPageResultQuestionGrid;
