import React, { useEffect, useRef, useState } from "react";
import * as Organizations from "../../Api/Organizations";
import { Validation } from "../../utils/Validation/Validation";
import row_input_select_role from "../../img/Auth_icon_row_select_role.svg";
import statusActiveIcon from "../../img/statusSubscribeActive.svg";
import statusBlockedIcon from "../../img/statusSubscribeBlocked.svg";
import statusExpiredIcon from "../../img/statusSubscribeExpired.svg";
import subscribeLockIcon from "../../img/subscribeLockIcon.svg";
import subscribeUnLockIcon from "../../img/subscribeUnLockIcon.svg";
import { useOnClickOutsideModal } from "../../utils/CustomHooks/useOutsideModal/useOutsideModal";
import AuthenticationSettings from "./AuthenticationSettings";
import AuthenticationSettingsTable from "./AuthenticationSettingsTable";

const SubscriptionSettings = (props) => {
  const { constants, requestHelper, org, authAs, reloadOrgPage } = props;

  const votersLimit = Validation();
  const eventsLimit = Validation();
  const smsLimit = Validation();
  const subdomain = Validation();
  const statusSubscribe = Validation();
  const sendMessageRef = useRef(null);
  const userTokenRef = useRef(null);

  const [subscriptionFrom, setSubscriptionFrom] = useState("");
  const [subscriptionTo, setSubscriptionTo] = useState("");
  const [isMessageCheckboxActive, setMessageCheckboxActive] = useState(false);
  const [isUserTokenActive, setUserTokenActive] = useState(
    org?.config?.orgPermissions?.gettingUserTokensEnabled
  );
  const [isActiveSaveButton, setActiveSaveButton] = useState(false);
  const [isActiveSelectListOption, setActiveSelectListOption] = useState(false);
  const [isSelectedOption, setSelectedOption] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isNotActiveStatus, setNotActiveStatus] = useState(false);
  const [isNotOrgPermission, setNotOrgPermission] = useState(false);

  useOnClickOutsideModal(isActiveSelectListOption, () =>
    setActiveSelectListOption(false)
  );

  const isOptionSelectList = [
    {
      value: "Опция1",
    },
    {
      value: "Опция2",
    },
    {
      value: "Опция3",
    },
  ];

  function formatDateSubscription(serverDate) {
    const localDate = new Date(serverDate.toString());
    const defaultDate = localDate.getDate();
    const date = `${
      defaultDate.toString().length === 1
        ? `${"0" + defaultDate}`
        : `${defaultDate}`
    }`;
    const defaultMonth = localDate.getMonth() + 1;
    const month = `${
      defaultMonth.toString().length === 1
        ? `${"0" + defaultMonth}`
        : `${defaultMonth}`
    }`;
    const year = localDate.getFullYear();
    return `${year + "-" + month + "-" + date}`;
  }

  function subscriptionFromChange(evt) {
    setSubscriptionFrom(evt.target.value);
  }

  function subscriptionToChange(evt) {
    setSubscriptionTo(evt.target.value);
  }

  function handleMessageCheckboxActive() {
    if (isMessageCheckboxActive) {
      setMessageCheckboxActive(false);
    } else {
      setMessageCheckboxActive(true);
    }
  }

  function handleUserToken() {
    setUserTokenActive(!isUserTokenActive);
  }

  // useEffect(() => {
  //     let isMounted = true;
  //     if (subdomain.value !== '') {
  //         isMounted && (sendMessageRef.current.style.pointerEvents = 'auto');
  //         isMounted && (sendMessageRef.current.style.opacity = '0.9');
  //     } else {
  //         isMounted && (sendMessageRef.current.style.pointerEvents = 'none');
  //         isMounted && (sendMessageRef.current.style.opacity = '0.3');
  //         isMounted && setMessageCheckboxActive(false);
  //     }
  //     return () => {
  //         isMounted = false;
  //     }
  // },[subdomain.value])

  // Раскомментировать метод, если нужно прибавлять к дате 1 день для отправки на API.

  // function datePlus (date) {
  //     const defaultDate = new Date(date);
  //     const datePlus = defaultDate.setDate(defaultDate.getDate() + 1);
  //     const newDate = new Date(datePlus);
  //     const dateForSend = `${newDate.getFullYear() + '.' + (newDate.getMonth() + 1) + '.' + newDate.getDate()}`;
  //     return dateForSend;
  // }

  const onSetCurrentStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return statusActiveIcon;
      case "BLOCKED":
        return statusBlockedIcon;
      case "EXPIRED":
        return statusExpiredIcon;
      default:
        return status;
    }
  };

  const onHandleActiveDisActiveStatus = (status) => {
    const startTime =
      subscriptionFrom !== "" ? subscriptionFrom.split("-").join(".") : null;
    const endTime =
      subscriptionTo !== "" ? subscriptionTo.split("-").join(".") : null;
    const body = {
      permission: {
        startTime: startTime,
        endTime: endTime,
        eventsLimit: eventsLimit.value,
        votersLimit: votersLimit.value,
        smsLimit: smsLimit.value,
        subdomain: subdomain.value,
        status: status,
      },
      org_id: org.id,
    };
    requestHelper(Organizations.addSubscription, body)
      .then((res) => {
        reloadOrgPage();
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (org?.config?.orgPermissions !== undefined) {
      if (
        org?.config?.orgPermissions.status === "ACTIVE" &&
        authAs === "ROLE_SUPER_ADMIN"
      ) {
        isMounted &&
          votersLimit.setValue(org?.config?.orgPermissions?.votersLimit);
        isMounted &&
          eventsLimit.setValue(org?.config?.orgPermissions?.eventsLimit);
        isMounted && smsLimit.setValue(org?.config?.orgPermissions?.smsLimit);
        isMounted && subdomain.setValue(org?.config?.orgPermissions?.subdomain);

        isMounted &&
          setSubscriptionFrom(
            formatDateSubscription(org?.config?.orgPermissions?.startTime)
          );
        isMounted &&
          setSubscriptionTo(
            formatDateSubscription(org?.config?.orgPermissions?.endTime)
          );
        isMounted &&
          statusSubscribe.setValue(org?.config?.orgPermissions?.status);
        isMounted && setNotActiveStatus(false);
      } else if (
        org?.config?.orgPermissions.status === "BLOCKED" &&
        authAs === "ROLE_SUPER_ADMIN"
      ) {
        isMounted &&
          votersLimit.setValue(org?.config?.orgPermissions?.votersLimit);
        isMounted &&
          eventsLimit.setValue(org?.config?.orgPermissions?.eventsLimit);
        isMounted && smsLimit.setValue(org?.config?.orgPermissions?.smsLimit);
        isMounted && subdomain.setValue(org?.config?.orgPermissions?.subdomain);
        isMounted &&
          setSubscriptionFrom(
            formatDateSubscription(org?.config?.orgPermissions?.startTime)
          );
        isMounted &&
          setSubscriptionTo(
            formatDateSubscription(org?.config?.orgPermissions?.endTime)
          );
        isMounted &&
          statusSubscribe.setValue(org?.config?.orgPermissions?.status);
        isMounted && setNotActiveStatus(true);
      } else if (authAs !== "ROLE_SUPER_ADMIN") {
        isMounted &&
          votersLimit.setValue(org?.config?.orgPermissions?.votersLimit);
        isMounted &&
          eventsLimit.setValue(org?.config?.orgPermissions?.eventsLimit);
        isMounted && smsLimit.setValue(org?.config?.orgPermissions?.smsLimit);
        isMounted && subdomain.setValue(org?.config?.orgPermissions?.subdomain);
        isMounted &&
          setSubscriptionFrom(
            formatDateSubscription(org?.config?.orgPermissions?.startTime)
          );
        isMounted &&
          setSubscriptionTo(
            formatDateSubscription(org?.config?.orgPermissions?.endTime)
          );
        isMounted &&
          statusSubscribe.setValue(org?.config?.orgPermissions?.status);
        isMounted && setNotActiveStatus(true);
      }
      setNotOrgPermission(false);
    } else {
      setNotOrgPermission(true);
    }
    return () => {
      isMounted = false;
    };
  }, [org?.config?.orgPermissions]);

  useEffect(() => {
    let isMounted = true;
    if (smsLimit.value !== 0) {
      isMounted && setMessageCheckboxActive(true);
    } else {
      isMounted && setMessageCheckboxActive(false);
    }
    return () => {
      isMounted = false;
    };
  }, [smsLimit.value]);

  useEffect(() => {
    let isMounted = true;

    if (
      votersLimit.value !== org?.config?.orgPermissions?.votersLimit &&
      isNotActiveStatus === false
    ) {
      isMounted && setActiveSaveButton(true);
    } else if (
      eventsLimit.value !== org?.config?.orgPermissions?.eventsLimit &&
      isNotActiveStatus === false
    ) {
      isMounted && setActiveSaveButton(true);
    } else if (
      subscriptionFrom !==
        formatDateSubscription(org?.config?.orgPermissions?.startTime) &&
      isNotActiveStatus === false
    ) {
      isMounted && setActiveSaveButton(true);
    } else if (
      subscriptionTo !==
        formatDateSubscription(org?.config?.orgPermissions?.endTime) &&
      isNotActiveStatus === false
    ) {
      isMounted && setActiveSaveButton(true);
    } else if (
      smsLimit.value !== org?.config?.orgPermissions?.smsLimit &&
      isNotActiveStatus === false
    ) {
      isMounted && setActiveSaveButton(true);
    } else if (
      subdomain.value !== org?.config?.orgPermissions?.subdomain &&
      isNotActiveStatus === false
    ) {
      isMounted && setActiveSaveButton(true);
    } else if (
      isUserTokenActive !==
      org?.config?.orgPermissions?.gettingUserTokensEnabled
    ) {
      isMounted && setActiveSaveButton(true);
    } else {
      isMounted && setActiveSaveButton(false);
    }
    return () => {
      isMounted = false;
    };
  }, [
    votersLimit.value,
    eventsLimit.value,
    smsLimit.value,
    subdomain.value,
    subscriptionFrom,
    subscriptionTo,
    isNotActiveStatus,
    isUserTokenActive,
  ]);

  function compareDate(firstDate, secondDate, isSoft) {
    return isSoft ? firstDate <= secondDate : firstDate < secondDate;
  }

  function saveChanges() {
    const numberRegExp = /[^0-9]/;
    if (!compareDate(subscriptionFrom, subscriptionTo, false)) {
      setErrorMessage(
        `${constants.ORG_SETTINGS.SUBSCRIBE_ERROR_START_DATE_MESSAGE}`
      );
    } else if (
      votersLimit.value === "" ||
      eventsLimit.value === "" ||
      smsLimit.value === "" ||
      numberRegExp.test(votersLimit.value) ||
      numberRegExp.test(eventsLimit.value) ||
      numberRegExp.test(smsLimit.value)
    ) {
      setErrorMessage(
        `${constants.ORG_SETTINGS.SUBSCRIBE_ERROR_EMPTY_FIELDS_MESSAGE}`
      );
    } else {
      const startTime =
        subscriptionFrom !== "" ? subscriptionFrom.split("-").join(".") : null;
      const endTime =
        subscriptionTo !== "" ? subscriptionTo.split("-").join(".") : null;

      // если нужно прибавить к дате 1 день, использовать startDate и endDate вместо startTime и endTime для отправки на API
      // так как API почему-то возвращает дату за минусом 1 день. Уточнить почему...

      // const startDate = datePlus(startTime);
      // const endDate = datePlus(endTime);
      // console.log(startDate);
      // console.log(endDate);

      const body = {
        permission: {
          eventsLimit: eventsLimit.value,
          votersLimit: votersLimit.value,
          startTime: startTime,
          endTime: endTime,
          smsLimit: smsLimit.value,
          subdomain: subdomain.value,
          status: statusSubscribe.value,
          gettingUserTokensEnabled: isUserTokenActive,
        },
        org_id: org.id,
      };
      requestHelper(Organizations.addSubscription, body)
        .then((res) => {
          reloadOrgPage();
        })
        .catch((err) => {
          throw new Error(err.message);
        });
      setErrorMessage("");
      setActiveSaveButton(false);
    }
  }

  const onHandleSelectOption = (value) => {
    setSelectedOption(value);
  };

  return (
    <div className="subscription-settings">
      {isNotOrgPermission ? (
        <span className="subscription-settings-not-date-subscription">
          {constants.ORG_SETTINGS.SUBSCRIPTION_SETTINGS_NOT_DATA_SUBSCRIPTION}
        </span>
      ) : (
        <>
          <div className="subscription-settings-header">
            <div className="subscription-settings-header__status">
              <h2 className="subscription-settings__heading">
                {constants.ORG_SETTINGS.SUBSCRIPTION_SETTINGS}
              </h2>
              <img
                className="subscription-settings-header__status-icon"
                src={onSetCurrentStatus(org?.config?.orgPermissions?.status)}
                alt={constants.GENERAL.ALT_ICON}
              />
              {authAs === "ROLE_SUPER_ADMIN" && (
                <img
                  onClick={() =>
                    onHandleActiveDisActiveStatus(
                      isNotActiveStatus ? "ACTIVE" : "BLOCKED"
                    )
                  }
                  className="subscription-settings-header__lock-icon"
                  src={
                    isNotActiveStatus ? subscribeLockIcon : subscribeUnLockIcon
                  }
                  title={isNotActiveStatus ? "Активировать" : "Заблокировать"}
                  alt={constants.GENERAL.ALT_ICON}
                />
              )}
            </div>
            <div
              className={
                isNotActiveStatus
                  ? "subscription-settings-header__select-option-not-active"
                  : "subscription-settings-header__select-option"
              }
            >
              {authAs === "ROLE_SUPER_ADMIN" && (
                <div
                  onClick={() =>
                    setActiveSelectListOption(!isActiveSelectListOption)
                  }
                  className="add-new-vote__time-zone-select-container"
                >
                  <p className="add-new-vote__time-zone-select-value">
                    {isSelectedOption === ""
                      ? "Выбрать опции"
                      : isSelectedOption}
                  </p>
                  <img
                    className="add-new-vote__time-zone-select-arrow"
                    src={row_input_select_role}
                    alt="Стрелочка открытия меню"
                  />
                  <div
                    className={
                      isActiveSelectListOption
                        ? "add-new-vote__time-zone-options-container"
                        : "add-new-vote__time-zone-options-container hidden"
                    }
                  >
                    {isOptionSelectList.map((el, i) => {
                      return (
                        <div
                          key={i}
                          className="add-new-vote__time-zone-option-container"
                          onClick={() => onHandleSelectOption(el.value)}
                        >
                          <p className="add-new-vote__time-zone-option">
                            {el.value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={
              isNotActiveStatus
                ? "subscription-settings-general-settings active"
                : "subscription-settings-general-settings"
            }
          >
            <div className="subscription-settings__date-main-continer">
              <div className="subscription-settings__date-continer">
                <p className="subscription-settings__date-text">
                  {constants.ORG_SETTINGS.DATE_START}
                </p>
                <input
                  type="date"
                  className="subscription-settings__date-input"
                  onChange={(e) => subscriptionFromChange(e)}
                  value={subscriptionFrom}
                />
              </div>
              <div className="subscription-settings__date-continer">
                <p className="subscription-settings__date-text">
                  {constants.ORG_SETTINGS.DATE_END}
                </p>
                <input
                  type="date"
                  className="subscription-settings__date-input"
                  onChange={(e) => subscriptionToChange(e)}
                  value={subscriptionTo}
                />
              </div>
            </div>
            <div className="subscription-settings__permissions-main-container">
              <div className="subscription-settings__permission-container">
                <p className="subscription-settings__permission-heading">
                  {constants.ORG_SETTINGS.VOTERS_LIMIT}
                </p>
                <input
                  type="number"
                  className="subscription-settings__permission-input"
                  name="voters-limit"
                  defaultValue={votersLimit.value}
                  onChange={votersLimit.onChange}
                />
              </div>
              <div className="subscription-settings__permission-container">
                <p className="subscription-settings__permission-heading">
                  {constants.ORG_SETTINGS.EVENTS_LIMIT}
                </p>
                <input
                  type="number"
                  className="subscription-settings__permission-input"
                  name="events-limit"
                  defaultValue={eventsLimit.value}
                  onChange={eventsLimit.onChange}
                />
              </div>
            </div>
            <div className="subscription-settings__permissions-main-container">
              <div className="subscription-settings__permission-container">
                <p className="subscription-settings__permission-heading">
                  {constants.ORG_SETTINGS.ADD_DOMAIN}
                </p>
                <input
                  type="text"
                  className="subscription-settings__permission-input"
                  name="domain-name"
                  defaultValue={subdomain.value}
                  onChange={subdomain.onChange}
                />
              </div>
            </div>
          </div>
          <div
            className={
              isNotActiveStatus
                ? "subscription-settings__message-container active"
                : "subscription-settings__message-container"
            }
          >
            <div
              ref={sendMessageRef}
              className="subscription-settings__message-checkbox-container"
              onClick={handleMessageCheckboxActive}
            >
              <div
                className={`subscription-settings__message-checkbox ${
                  isMessageCheckboxActive &&
                  "subscription-settings__message-checkbox_active"
                }`}
              />
              <p className="subscription-settings__message-checkbox-text">
                {constants.ORG_SETTINGS.MESSAGE_CHECKBOX}
              </p>
            </div>
            {isMessageCheckboxActive && (
              <div className="subscription-settings__message-input-container">
                <p className="subscription-settings__message-input-heading">
                  {constants.ORG_SETTINGS.MESSAGE_INPUT}
                </p>
                <input
                  type="number"
                  className="subscription-settings__message-input"
                  name="message-limit"
                  value={smsLimit.value}
                  onChange={smsLimit.onChange}
                />
              </div>
            )}
          </div>
          {authAs === "ROLE_SUPER_ADMIN" && (
              <>
                <div
                    ref={userTokenRef}
                    className="subscription-settings__message-checkbox-container"
                    onClick={handleUserToken}
                    style={{marginTop: 15}}
                >
                  <div
                      className={`subscription-settings__message-checkbox ${
                          isUserTokenActive &&
                          "subscription-settings__message-checkbox_active"
                      }`}
                  />
                  <p className="subscription-settings__message-checkbox-text">
                    Получать токен пользователей
                  </p>
                </div>
              </>
          )}

          {isActiveSaveButton && (
              <div className="subscription-settings-save-changes-button-errpr-message">
                <button
                    className="subscription-settings__save-changes-button"
                    onClick={saveChanges}
                >
                  {constants.ORG_SETTINGS.BUTTON_SAVE_ADMINS_CHANGE}
                </button>
                <p className="subscription-settings__error-message">
                  {isErrorMessage}
                </p>
              </div>
          )}
          {authAs === "ROLE_SUPER_ADMIN" && (
              <>
                <AuthenticationSettings
                    constants={constants}
                />
                <AuthenticationSettingsTable/>
              </>
          )}
        </>
      )}
    </div>
  );
};
export default SubscriptionSettings;
