import React, {useEffect, useMemo, useRef, useState} from "react";
import iconCloseModal from "../../img/AddNewVoteQuestuionTypeIconCloseModal.svg";
import iconPlus from "../../img/AddNewVoteQuestuionTypeIconPlus.svg";
import iconBasket from "../../img/AddNewVoteQuestuionTypeIconBasket.svg";
import iconRulePlus from "../../img/AddNewVoteIconPlus.svg";
import iconExcel from "../../img/AddNewVoteIconExcel.svg";
import Slider from '@mui/material/Slider';
import AddMaterials from "../AddMaterials/AddMaterials";
import row_input_select_role from "../../img/Auth_icon_row_select_role.svg";
import {Validation} from "../../utils/Validation/Validation";
import * as excel from "xlsx";
import useSetRuleQuestionEQ from "../../utils/CustomHooks/useSetRuleQuestionEQ/useSetRuleQuestionEQ";
import useSetRuleQuestionGT from "../../utils/CustomHooks/useSetRuleQuestionGT/useSetRuleQuestionGT";
import useSetRuleQuestionLT from "../../utils/CustomHooks/useSetRuleQuestionLT/useSetRuleQuestionLT";
import useSetRuleQuestionLE from "../../utils/CustomHooks/useSetRuleQuestionLE/useSetRuleQuestionLE";
import useSetRuleQuestionGE from "../../utils/CustomHooks/useSetRuleQuestionGE/useSetRuleQuestionGE";

const AddNewVoteTypeNone = (props) => {

    const {
        onCloseModal,
        constants,
        requestHelper,
        questionsList,
        addQuestion,
        questionForEdit,
        changeEditQuestion
    } = props;

    const [activeRuleSelect, setActiveRuleSelect] = useState(false);
    const questionTitle = Validation();
    const [materials, setMaterials] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [rows, setRows] = useState([]);
    const [startValueInput, setStartValueInput] = useState('');
    const [endValueInput, setEndValueInput] = useState('');
    const [valueRange, setValueRange] = useState([1, 1]);
    const {valueRuleEQ} = useSetRuleQuestionEQ(startValueInput, endValueInput, activeRuleSelect);
    const {valueRulesLT} = useSetRuleQuestionLT(startValueInput, endValueInput, activeRuleSelect, rows);
    const {valueRuleGT} = useSetRuleQuestionGT(startValueInput, endValueInput, activeRuleSelect, rows);
    const {valueRuleLE} = useSetRuleQuestionLE(startValueInput, endValueInput, activeRuleSelect, rows);
    const {valueRuleGE} = useSetRuleQuestionGE(startValueInput, endValueInput, activeRuleSelect, rows);
    const startValueRef = useRef(null);
    const endValueRef = useRef(null);
    
    // const [activeRuleRestriction, setActiveRuleRestriction] = useState(false);
    // const [rules, setRules] = useState([]);

    // const selectRules = [
    //     {typeRule: 'pick_eq', nameRule: `${constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_CHOOSE_EXACTLY}`},
    //     {typeRule: 'pick_lt', nameRule: `${constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_CHOOSE_LESS_THAN}`},
    //     {typeRule: 'pick_gt', nameRule: `${constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_CHOOSE_MORE_THAN}`},
    //     {typeRule: 'pick_le', nameRule: `${constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_CHOOSE_LESS_OR_EXACTLY}`},
    //     {typeRule: 'pick_ge', nameRule: `${constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_CHOOSE_MORE_OR_EXACTLY}`}
    // ]

    function onActiveRulesSelect() {
        const getElemRange = document.querySelector('.MuiSlider-track');
        const getElemThumb = document.querySelector('.MuiSlider-thumb');
        if(activeRuleSelect === false) {
            getElemRange.style.width = '100%';
            getElemRange.style.left = '0%';
            getElemThumb.style.left = '0%';
            setStartValueInput(Number('1'));
            setEndValueInput(rows.length);
            setActiveRuleSelect(true)
            setErrorMessage('');
            setValueRange([1, rows.length]);
        } else {
            setActiveRuleSelect(false)
        }
    }

    function valuetext(value) {
        return value;
    }

    const handleChangeInputRange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            if(newValue[0] > endValueInput) {
                return;
            } else {
                setValueRange([Math.min(newValue[0], valueRange[1]), valueRange[1]]);
                setStartValueInput(newValue[0]);
            }
        } else {
            if(endValueInput > rows.length) {
                return;
            } else {
                setValueRange([valueRange[0], Math.max(newValue[1], valueRange[0])]);
                setEndValueInput(newValue[1]);
            }
        }
    };

    const handleChangeStartValueInput = (value) => {
        let getNumber = value;

        if(!getNumber.match(/^\d+$/)) {
            setStartValueInput('');
        } else if(endValueInput === '') {
            if(Number(getNumber) > valueRange[1]) {
                return;
            } else {
                setStartValueInput(Number(getNumber));
                setValueRange([Number(getNumber), valueRange[1]]);
                startValueRef.current = Number(getNumber);
            }
        } else if (Number(getNumber) > Number(endValueInput)) {
            return;
        } else if (Number(getNumber) === 0) {
            setStartValueInput(Number('1'));
            setValueRange([1, valueRange[1]]);
            startValueRef.current = Number(getNumber);
        } else {
            setStartValueInput(Number(getNumber));
            setValueRange([Number(getNumber), valueRange[1]]);
            startValueRef.current = Number(getNumber);
        }
    }

    const handleChangeEndValueInput = (value) => {
        let getNumber = value;

        if(!getNumber.match(/^\d+$/)) {
            setEndValueInput('');
        } else if (Number(getNumber) > rows.length) {
            return;
        } else if(startValueInput === '') {
            if(Number(getNumber) < valueRange[0]) {
                return;
            } else {
                setEndValueInput(Number(getNumber));
                setValueRange([valueRange[0], Number(getNumber)]);
                endValueRef.current = Number(getNumber);
            }
        } else if(Number(getNumber) < startValueInput) {
            return;
        } else if (Number(getNumber) === 0) {
            setEndValueInput(Number('1'));
            setValueRange([valueRange[0], valueRange[1]]);
            endValueRef.current = Number(getNumber);
        } else {
            setEndValueInput(Number(getNumber));
            setValueRange([valueRange[0], Number(getNumber)]);
            endValueRef.current = Number(getNumber);
        }
    }

    function onEditRules() {
        if(rows.length -1 < endValueInput) {
            if(startValueInput > endValueInput - 1) {
                setStartValueInput(rows.length - 1);
                setEndValueInput(rows.length - 1);
                setValueRange([valueRange[0], rows.length - 1]);
            } else {
                setEndValueInput(rows.length - 1);
                setValueRange([valueRange[0], rows.length - 1]);
            }
        }
    }

    useEffect(() => {

        let isMounted = true;

        const getElemThumbArray = document.querySelectorAll('.MuiSlider-thumb');
        const getElemRange = document.querySelector('.MuiSlider-track');

        if(rows.length === 1) {
            isMounted && setStartValueInput(Number('1'));
            isMounted && setEndValueInput(Number('1'));
            isMounted && setValueRange([1, 1]);
            getElemRange.style.width = '100%';
            getElemRange.style.left = '0%';
            getElemThumbArray[0].style.left = '0%';
            getElemThumbArray[1].style.left = '100%';
        }

        if(rows.length > 1 && startValueInput === endValueInput) {
            if(startValueInput === rows.length && endValueInput === rows.length) {
                getElemThumbArray[0].style.left = '97%';
                getElemRange.style.width = '3%';
                getElemRange.style.left = '97%';
            } else if(startValueInput === 1 && endValueInput === 1) {
                getElemThumbArray[1].style.left = '3%';
                getElemRange.style.width = '3%';
            }
            // } else {
            //     console.log('wewew')
            //     if(startValueRef.current !== startValueInput) {
            //         return ;
            //     } else {
            //         getElemRange.style.width = "3%";
            //         let getCurrentElemThumb = getElemThumbArray[1].style.left;
            //         getElemThumbArray[1].style.left = `${Math.floor(getCurrentElemThumb.slice(0, -1)) + 3}% `;
            //     }
            // }
        }

        return () => {
            isMounted = false;
        }

    }, [rows.length, startValueInput, endValueInput]);

    function idGenerate(arr) {
        if (arr.length < 1) {
            return 1;
        } else {
            const allIdArray = arr.map((arr) => {
                return arr.id
            });
            return Math.max(...allIdArray) + 1;
        }
    }

    // function addEmptyRules() {
    //     const rule = {
    //         id: idGenerate(rules)
    //     }
    //     setRules([...rules, rule])
    // }
    //
    // function deleteRules(id) {
    //     if(rules.length !== 1) {
    //         const filteredArray = rules.filter(el => el.id !== id)
    //         setRules(filteredArray)
    //     }
    // }

    function onSelectFileHandler(e) {
        const files = e.target.files,
            f = files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = e.target.result;
            const workbook = excel.read(data, { type: "binary" });
            const wsname = workbook.SheetNames[0];
            const ws = workbook.Sheets[wsname];
            const dataWs = excel.utils.sheet_to_json(ws, { header: 1 });

            const newARR = dataWs.reduce((acc, newEl, i) => {
                if (!newEl[0]) return acc;
                return [
                    ...acc,
                    {
                        id: i,
                        value: newEl[0],
                    },
                ];
            }, []);

            window.LoadLength = newARR.length;
            setRows(newARR);
        };
        reader.readAsBinaryString(f);
    }

    function addEmptyRow() {
        const row = {
            id: idGenerate(rows),
            value: ""
        }
        setRows([...rows, row]);
    }

    function deleteRow(id) {
        if (rows.length !== 1) {
            const filteredArray = rows.filter(el => el.id !== id);
            setRows(filteredArray);
        }
        onEditRules();
    }

    function questionForEditRules(rules, options) {
        if (rules.pick_eq !== -1) {
            setValueRange([rules.pick_eq, rules.pick_eq]);
            setStartValueInput(rules.pick_eq);
            setEndValueInput(rules.pick_eq);
            setActiveRuleSelect(true);
        } else if(rules.pick_lt !== -1 && rules.pick_gt !== -1) {
            setValueRange([rules.pick_gt, rules.pick_lt]);
            setStartValueInput(rules.pick_gt);
            setEndValueInput(rules.pick_lt);
            setActiveRuleSelect(true);
        } else if(rules.pick_le !== -1) {
            setValueRange([1, rules.pick_le]);
            setStartValueInput(1);
            setEndValueInput(rules.pick_le);
            setActiveRuleSelect(true);
        } else if(rules.pick_ge) {
            setValueRange([rules.pick_ge, options.rows.length]);
            setStartValueInput(rules.pick_ge);
            setEndValueInput(options.rows.length);
            setActiveRuleSelect(true);
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (questionForEdit?.id) {
            isMounted && questionTitle.setValue(questionForEdit?.title);
            isMounted && setMaterials(questionForEdit?.materials.map(function (item, index) {
                return {...item, id: index + 1}
            }));
            isMounted && setRows(questionForEdit?.options?.rows?.map(function (item, index) {
                return {...item, id: index + 1}
            }));
            questionForEditRules(questionForEdit?.rules, questionForEdit?.options)
            isMounted && setEditMode(true);
        } else {
            isMounted && addEmptyRow();
            // addEmptyRules();
        }
        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line
    }, [questionForEdit])

    function rowsValidate() {
        const rowsValidation = () => {
            for (let val of rows) {
                for (let key in val) {
                    if (!val[key]) {
                        return false;
                    }
                }
            }
        }
        const isRowsValid = rowsValidation();
        if (isRowsValid === false) {
            setErrorMessage(constants.ADD_NEW_VOTE.ROWS_ERR);
            return false;
        } else {
            setErrorMessage('');
            return ruleSetValidation();
        }
    }

    function materialsValidate(materials) {
        const materialsValidation = () => {
            for (let val of materials) {
                for (let key in val) {
                    if (!val[key]) {
                        return false;
                    }
                }
            }
        }
        const isMaterialsValid = materialsValidation();
        if (isMaterialsValid === false) {
            setErrorMessage(constants.ADD_NEW_VOTE.QUESTION_MATERIALS_ERR);
            return false;
        } else {
            setErrorMessage('');
            return rowsValidate();
        }
    }

    function questionValidation(materials) {
        if (questionTitle.value !== '') {
            setErrorMessage('');
            return materialsValidate(materials);
        } else {
            setErrorMessage(constants.ADD_NEW_VOTE.QUESTION_TITLE_ERROR);
            return false;
        }
    }

    function ruleSetValidation() {
        if(activeRuleSelect === false) {
            setErrorMessage('Необходимо добавить правило!');
            return false;
        } else if(startValueInput === '' || endValueInput === '') {
            setErrorMessage('Необходимо выбрать интервал ответов!');
            return false;
        } else {
            setErrorMessage('');
            return true;
        }
    }

    function onClose() {
        onCloseModal();
        setErrorMessage('');
        setMaterials([]);
        setRows([]);
        // setRules([])
        questionTitle.setValue('');
        setEditMode(false);
    }

    function addNewQuestion() {
        const materialsForValidation = [];
        materials.forEach((material) => {
            if (material.type === "link") {
                const data = {
                    title: material.title,
                    type: material.type,
                    value: material.valueLink
                }
                materialsForValidation.push(data);
            } else {
                const data = {
                    title: material.title,
                    type: material.type,
                    value: material.valueDoc
                }
                materialsForValidation.push(data);
            }
        })
        const isQuestionValid = questionValidation(materialsForValidation);
        if (isQuestionValid) {
            const question = {
                id: isEditMode ? questionForEdit.id : idGenerate(questionsList),
                template: "none",
                title: questionTitle.value,
                options: {
                    rows: rows,
                    columns: []
                },
                materials: materials,
                is_required_grid_rows: false,
                rules: {
                    pick_eq: valueRuleEQ,
                    pick_lt: valueRulesLT,
                    pick_gt: valueRuleGT,
                    pick_le: valueRuleLE,
                    pick_ge: valueRuleGE
                }
            }
            if (isEditMode) {
                changeEditQuestion(question);
            } else {
                addQuestion(question);
            }
            onClose();
        }
    }

    function addEmptyMaterial() {
        const material = {
            id: idGenerate(materials),
            title: "",
            type: "link",
            valueLink: "",
            valueDoc: "",
            selectedFileName: constants.ADD_NEW_ORG.ADD_NEW_ORG_SELECT_FILE,
            isFileSelected: false
        }
        setMaterials([...materials, material]);
    }

    function deleteMaterial(id) {
        const filteredArray = materials.filter(el => el.id !== id);
        setMaterials(filteredArray);
    }

    function changeMaterialType(id, type, isEvent) {
        if (!isEvent) {
            const foundedMaterial = materials.find(el => el.id === id);
            const filteredArray = materials.filter(el => el.id !== id);
            foundedMaterial.type = type;
            filteredArray.push(foundedMaterial);
            setMaterials(filteredArray);
        }
    }

    function rowInputChange(e, id) {
        const foundedEl = rows.find(el => el.id === id);
        const filteredArray = rows.filter(el => el.id !== id);
        foundedEl.value = e.target.value
        filteredArray.push(foundedEl);
        setRows(filteredArray);
    }

    function linkInputChange(e, id) {
        const foundedEl = materials.find(el => el.id === id);
        const filteredArray = materials.filter(el => el.id !== id);
        foundedEl.valueLink = e.target.value
        filteredArray.push(foundedEl);
        setMaterials(filteredArray);
    }

    function titleInputChange(e, id) {
        const foundedEl = materials.find(el => el.id === id);
        const filteredArray = materials.filter(el => el.id !== id);
        foundedEl.title = e.target.value
        filteredArray.push(foundedEl);
        setMaterials(filteredArray);
    }

    function changeDocLink(id, fileName, link) {
        const foundedEl = materials.find(el => el.id === id);
        const filteredArray = materials.filter(el => el.id !== id);
        foundedEl.isFileSelected = true;
        foundedEl.selectedFileName = fileName;
        foundedEl.valueDoc = link;
        filteredArray.push(foundedEl);
        setMaterials(filteredArray);
    }

    return (
        <div className="add-new-vote-type-none__container active">
            <div className="add-new-vote-type-none">
                <div className="add-new-vote-type-none__title">
                    <h3 className="add-new-vote-type-none__title-number-question">
                        {!isEditMode ? `${constants.ADD_NEW_VOTE.QUESTION} #${questionsList.length + 1}` : `${constants.ADD_NEW_VOTE.QUESTION} #${questionForEdit?.indexQuestion + 1}`}
                    </h3>
                    <img onClick={onClose} className="add-new-vote-type-none__title-icon-close" src={iconCloseModal} alt={constants.GENERAL.ALT_ICON} />
                </div>
                <h5 className="add-new-vote-type-none__title-current-type-question">{constants.ADD_NEW_VOTE.ADD_NEW_VOTE_QUESTION_NONE}</h5>
                <div className="add-new-vote-type-none__name-question">
                    <label className="add-new-vote-type-none__name-question-label">
                        {constants.ADD_NEW_VOTE.QUESTION_TYPE_NAME_QUESTION}
                        <span className="add-new-vote__red-star"> *</span>
                    </label>
                    <input className="add-new-vote-type-none__name-question-input"
                           type={'text'}
                           placeholder={constants.ADD_NEW_VOTE.QUESTION_TYPE_NAME_QUESTION_PLACEHOLDER_ENTER_YOUR_QUESTION}
                           value={questionTitle.value}
                           onChange={questionTitle.onChange}
                    />
                </div>
                <div className="add-new-vote-type-none__types-variants-answer">
                    <div className="add-new-vote-type-none__types-variants-answer-input-text">
                        <h3 className="add-new-vote-type-none__types-variants-answer-title">
                            {constants.ADD_NEW_VOTE.QUESTION_TYPE_VARIANTS_ANSWER}
                        </h3>
                        {rows.sort(function (a, b) {
                            const aId = a.id;
                            const bId = b.id;
                            if (aId < bId)
                                return -1
                            if (aId > bId)
                                return 1
                            return 0
                        }).map((row) => (
                            <div key={row.id} className="add-new-vote-type-none__type-input-block">
                                <input
                                    placeholder={constants.ADD_NEW_VOTE.QUESTION_TYPE_VARIANTS_ANSWER_PLACEHOLDER_ENTER_VARIANT}
                                    className="add-new-vote-type-none__type-input-text"
                                    value={row.value}
                                    onChange={(e) => rowInputChange(e, row.id)}
                                />
                                <div className="add-new-vote-type-none__type-input-icons">
                                    <img onClick={addEmptyRow} className="add-new-vote-type-none__type-input-gray-plus" src={iconPlus} alt={constants.GENERAL.ALT_ICON} />
                                    <img onClick={() => deleteRow(row.id)} className="add-new-vote-type-none__type-input-gray-basket" src={iconBasket} alt={constants.GENERAL.ALT_ICON} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="add-new-vote-type-none__rules-block">
                        <div onClick={onActiveRulesSelect} className={isEditMode ? "add-new-vote-type-none__title-rules-block-disable" : "add-new-vote-type-none__title-rules-block"}>
                            <img className="add-new-vote-type-none__rules-icon-plus" src={iconRulePlus} alt={constants.GENERAL.ALT_ICON} />
                            <p className="add-new-vote-type-none__rules-add-rule-label">{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_TITLE}</p>
                        </div>
                        {/*{rules.sort(function (a, b) {*/}
                        {/*    const aId = a.id;*/}
                        {/*    const bId = b.id;*/}
                        {/*    if (aId < bId)*/}
                        {/*        return -1*/}
                        {/*    if (aId > bId)*/}
                        {/*        return 1*/}
                        {/*    return 0*/}
                        {/*}).map((el) => (*/}
                        {/*<div key={el.id} className={activeRuleSelect ? "add-new-vote-type-none__select-rules-block active" : "add-new-vote-type-none__select-rules-block"}>*/}
                        {/*<div className="add-new-vote-type-none__select-rules-block-restriction">*/}
                        {/*    <h3 className="add-new-vote-type-none__select-rules-title">{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES}</h3>*/}
                        {/*    <div className="add-new-vote-type-none__select-rules-restriction">*/}
                        {/*        <div onClick={() => setActiveRuleRestriction(!activeRuleRestriction)} className="add-new-vote__time-zone-select-container _question-type">*/}
                        {/*            <p className="add-new-vote__time-zone-select-value">{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_CHOOSE_EXACTLY}</p>*/}
                        {/*            <img className="add-new-vote__time-zone-select-arrow" src={row_input_select_role} alt={constants.GENERAL.ALT_ICON} />*/}
                        {/*            <div className={activeRuleRestriction === el.id ? "add-new-vote__time-zone-options-container _question-type-select" : "add-new-vote__time-zone-options-container _question-type-select hidden"}>*/}
                        {/*                <p className="add-new-vote__time-zone-option _question-type-option">{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_CHOOSE_LESS_THAN}</p>*/}
                        {/*                <p className="add-new-vote__time-zone-option">{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_CHOOSE_MORE_THAN}</p>*/}
                        {/*                <p className="add-new-vote__time-zone-option">{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_CHOOSE_LESS_OR_EXACTLY}</p>*/}
                        {/*                <p className="add-new-vote__time-zone-option">{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_CHOOSE_MORE_OR_EXACTLY}</p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="add-new-vote-type-none__select-rules-restriction-input-buttons">*/}
                        {/*            <input className="add-new-vote-type-none__select-rules-restriction-input"*/}
                        {/*                   type="number"*/}
                        {/*                   placeholder='1'*/}
                        {/*                   min={1}*/}
                        {/*                   step={1}*/}
                        {/*                   value={positionValue.value}*/}
                        {/*                   onChange={(e) => changePositionValue(e)}*/}
                        {/*            />*/}
                        {/*            <div className="add-new-vote-type-none__type-input-icons">*/}
                        {/*                <img onClick={addEmptyRules} className="add-new-vote-type-none__type-input-gray-plus" src={iconPlus} alt={constants.GENERAL.ALT_ICON} />*/}
                        {/*                <img onClick={() => deleteRules(el.id)} className="add-new-vote-type-none__type-input-gray-basket" src={iconBasket} alt={constants.GENERAL.ALT_ICON} />*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={activeRuleSelect ? "add-new-vote-type-none__select-rules-block-range active" : "add-new-vote-type-none__select-rules-block-range"}>
                            <h3 className="add-new-vote-type-none__select-rules-title">{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES}</h3>
                            <div className="add-new-vote-type-none__select-rules-inputs-number"><p>{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_SELECT_INTERVAL_FROM}</p>
                                <input className="add-new-vote-type-none__select-rules-input"
                                       type="text"
                                       value={startValueInput}
                                       disabled={false}
                                       onChange={(e) => handleChangeStartValueInput(e.target.value)}
                                />
                                <p>{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_SELECT_INTERVAL_BEFORE}</p>
                                <input className="add-new-vote-type-none__select-rules-input"
                                       type="text"
                                       value={endValueInput}
                                       disabled={false}
                                       onChange={(e) => handleChangeEndValueInput(e.target.value)}
                                />
                                <p>{constants.ADD_NEW_VOTE.QUESTION_TYPE_RULES_SELECT_SELECT_INTERVAL_ANSWER_OPTIONS}</p>
                                {/*<div className="add-new-vote-type-none__type-input-icons">*/}
                                {/*    <img className="add-new-vote-type-none__type-input-gray-plus" src={iconPlus} alt={constants.GENERAL.ALT_ICON} />*/}
                                {/*    <img className="add-new-vote-type-none__type-input-gray-basket" src={iconBasket} alt={constants.GENERAL.ALT_ICON} />*/}
                                {/*</div>*/}
                            </div>
                            <div className="add-new-vote-type-none__select-rules-range active">
                                <Slider
                                    getAriaLabel={() => 'Minimum distance'}
                                    value={valueRange}
                                    onChange={handleChangeInputRange}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                    disableSwap
                                    step={1}
                                    min={1}
                                    max={rows.length}
                                />
                            </div>
                        </div>
                        {/*</div>*/}
                        {/*))}*/}
                    </div>
                    <div className="add-new-vote-type-none__import-excel-block">
                        <label htmlFor="file" className="add-new-vote-type-none__import-excel-btn">
                            <img className="add-new-vote-type-none__icon-excel" src={iconExcel} alt={constants.GENERAL.ALT_ICON} />{constants.ADD_NEW_VOTE.EXPAND_LIST_IMPORT_EXCEL}</label>
                        <input type="file" name="file" id="file" className="add-new-vote-type-none__input-file" onChange={(e) => onSelectFileHandler(e)}/>
                    </div>
                    <div className="add-new-vote-type-none__add-materials-vote">
                        <AddMaterials
                            constants={constants}
                            eventMaterials={materials}
                            isEvent={false}
                            addEmptyMaterial={addEmptyMaterial}
                            changeMaterialType={changeMaterialType}
                            linkInputChange={linkInputChange}
                            titleInputChange={titleInputChange}
                            changeDocLink={changeDocLink}
                            deleteMaterial={deleteMaterial}
                            requestHelper={requestHelper}
                        />
                    </div>
                    <p className="add-new-vote-type-same-positions__error-message">{errorMessage}</p>
                    <div className="add-new-vote-type-none__add-button-block">
                        <button onClick={addNewQuestion} className="add-new-vote-type-none__add-btn">{isEditMode ? constants.ADD_NEW_VOTE.SAVE_CHANGES : constants.ADD_NEW_VOTE.ADD_NEW_VOTE_ADD_QUESTION_BTN}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddNewVoteTypeNone;