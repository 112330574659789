import {useEffect, useState} from "react";

export const useSetCountPagePagination = (isUserProfile) => {

    const [countPage, setCountPage] = useState(0);

    useEffect(() => {

        let isMounted = true;

        if (isUserProfile && isUserProfile.events !== undefined) {
            isMounted && setCountPage(0);
            isMounted && setCountPage(Math.ceil(isUserProfile.events.length / 10));
        } else {
            isMounted && setCountPage(0);
        }

        return () => {
            isMounted = false;
        }

    }, [isUserProfile]);

    return countPage
};