import React, {useState, useEffect} from "react";
import optionRow from "../../img/Auth_icon_row_select_role.svg";
import {Validation} from "../../utils/Validation/Validation";
import {useMutation} from "@tanstack/react-query";
import {updateUserFromSuperAdmin} from "../../Api/auth-service";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";

const ProfileUserUpdateUserPersonalData = (props) => {

    const {
        constants,
        allUsers
    } = props;

    const { runUpdateDataEvents } = useCurrentUserContext();
    const firstName = Validation();
    const lastName = Validation();
    const secondName = Validation();
    const email = Validation();
    const phone = Validation();
    const [isOptionsOpen, setOptionsOpen] = useState(false);
    const [isDisableBtn, setDisableBtn] = useState(true);
    const [resultForRender, setResultsForRender] = useState([]);
    const [message, setMessage] = useState({
        isError: false,
        text: ''
    });
    const [selectUser, setSelectUser] = useState(
        {
            nameUser: '',
            dataUser: {}
        }
    );

    const updateUserMutation = useMutation({
        mutationFn: ({body}) => {
            return updateUserFromSuperAdmin(body)
        },
        onSuccess: () => {
            setDisableBtn(true);
            setSelectUser({
                nameUser: '',
                dataUser: {}
            });
            runUpdateDataEvents();
            firstName.setValue('');
            lastName.setValue('');
            secondName.setValue('');
            email.setValue('');
            phone.setValue('');
            setMessage({
                isError: false,
                text: 'Данные сохранены!'
            });
            setTimeout(() => {
                setMessage({
                    isError: false,
                    text: ''
                });
            }, 2000)
        },
        onError: (error) => {
            if (error.response.status === 400 || error.response.data.status === 'failure') {
                if (error.response.data.text.includes('is not correct')) {
                    setMessage({
                        isError: true,
                        text: 'Некорректные данные'
                    })
                } else if(error.response.data.text.includes('должно иметь формат адреса электронной почты')) {
                    setMessage({
                        isError: true,
                        text: 'Email должен иметь формат адреса электронной почты'
                    })
                } else if(error.response.data.text.includes('Incorrect format')) {
                    setMessage({
                        isError: true,
                        text: 'Некорректный формат номера телефона'
                    })
                } else {
                    setMessage(
                        {
                            isError: true,
                            text: `${error.response.data.text}`
                        })
                }
            } else {
                setMessage(
                    {
                        isError: true,
                        text: `${error.response.data.text}`
                    })
            }
        }
    });

    useEffect(() => {
        let isMounted = true;

        isMounted && setResultsForRender(allUsers && allUsers.length !== 0 ? allUsers : []);

        return () => {
            isMounted = false;
        }
    },[allUsers])

    function handleOptionsOpen() {
        if (isOptionsOpen) {
            setOptionsOpen(false);
        } else {
            setOptionsOpen(true);
        }
    }

    function onSelectClick(user) {
        setSelectUser({...selectUser,
            nameUser: user.fullName,
            dataUser: user
        });
        firstName.setValue(user.firstName);
        lastName.setValue(user.lastName);
        secondName.setValue(user.secondName);
        email.setValue(user.email ? user.email : '');
        phone.setValue(user.phone ? user.phone : '');
        setOptionsOpen(false);
        setResultsForRender(allUsers && allUsers.length !== 0 ? allUsers : []);
        setDisableBtn(true);
    }

    function onHandleInput(e) {
        setSelectUser({...selectUser,
            nameUser: e.currentTarget.value
        });

        if (e.currentTarget.value === "") {
            onActiveButton(resultForRender, e.currentTarget.value)
            setOptionsOpen(false);
            setResultsForRender(allUsers && allUsers.length !== 0 ? allUsers : []);
        } else {
            setMessage({
                isError: false,
                text: ""
            });
            const resultsForRender = [];
            allUsers.forEach((user) => {
                if (user.fullName.toLowerCase().includes(selectUser.nameUser.toLowerCase())) {
                    resultsForRender.push(user);
                }
            });
            setOptionsOpen(true);
            setResultsForRender(resultsForRender);
        }
    }

    function onActiveButton(results, user) {
        if(results.length === 0 || user === '') {
            setDisableBtn(true);
        }
    }

    function onHandleSave() {
        const body = {
            id: selectUser.dataUser.id,
            email: email.value === selectUser.dataUser.email ? "" : email.value,
            phone: phone.value === selectUser.dataUser.phone ? "" : phone.value,
            firstName: firstName.value,
            secondName: secondName.value,
            lastName: lastName.value
        }
        updateUserMutation.mutate({body: body})
    }

    useEffect(() => {
        let isMounted = true;
        if (firstName.value !== '' || secondName.value !== '' || lastName.value !== '' || email.value !== '' || phone.value !== '') {
            isMounted && setMessage('');
        }
        return () => {
            isMounted = false;
        }
    },[firstName.value, secondName.value, lastName.value, email.value, phone.value])

    function onHandleInputUserPhone(value) {
        if (!value.match(/^\d+$/)) {
            phone.setValue('');
            return;
        }
            phone.setValue(value);
            setDisableBtn(false);

    }

    function onHandleInputUserName(e, nameField) {
        switch (nameField) {
            case 'lastName': lastName.setValue(e.currentTarget.value)
                setDisableBtn(false);
                break;
            case 'firstName': firstName.setValue(e.currentTarget.value)
                setDisableBtn(false);
                break;
            case 'secondName': secondName.setValue(e.currentTarget.value)
                setDisableBtn(false);
                break;
            case 'email': email.setValue(e.currentTarget.value)
                setDisableBtn(false);
                break;
            case 'phone': onHandleInputUserPhone(e.currentTarget.value)
                break;
            default: return;
        }
    }

    return (
        <div className="profile-user-update-data">
            <p className="edit-main-data-user__title-change-pass">Сброс данных пользователя</p>
            <div className="edit-main-data-user__time-zone-main-container">
                <p className="edit-main-data-user__time-zone-heading">
                    Выберите пользователя
                    <span className="reg-main-block__red-star-heading_span">*</span>
                </p>
                <div
                    className="edit-main-data-user__time-zone-select-container"
                >
                    <input
                        type="text"
                        className="profile-user-update-data__select-value"
                        value={selectUser.nameUser}
                        onChange={(e) => onHandleInput(e)}
                    />
                    <img
                        onClick={handleOptionsOpen}
                        className="edit-main-data-user__time-zone-select-arrow profile-user-update-data__select-arrow"
                        src={optionRow}
                        alt="Стрелочка открытия меню"
                    />
                    {isOptionsOpen && (
                        <div className="edit-main-data-user__time-zone-options-container">
                            {resultForRender.length !== 0 ? (
                                <>
                                    {resultForRender?.map((user, index) => (
                                        <p
                                            className="edit-main-data-user__time-zone-option"
                                            key={index}
                                            onClick={() => onSelectClick(user)}
                                        >
                                            {user.fullName}
                                        </p>
                                    ))}
                                </>
                            ) : (
                                <p className="profile-user-update-data__not-found-option">
                                    Пользователь не найден!
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <span className="edit-main-data-user__time-zone-main-container__error-message"></span>
            </div>
            <div className="edit-main-data-user__surname">
                <label className="edit-main-data-user__surname-label">Фамилия</label>
                <input className="edit-main-data-user__surname-field-input"
                       placeholder="Введите фамилию"
                       value={lastName.value}
                       onChange={(e) => onHandleInputUserName(e, 'lastName')}
                />
            </div>
            <div className="edit-main-data-user__surname">
                <label className="edit-main-data-user__surname-label">Имя</label>
                <input className="edit-main-data-user__surname-field-input"
                       placeholder="Введите имя"
                       value={firstName.value}
                       onChange={(e) => onHandleInputUserName(e, 'firstName')}
                />
            </div>
            <div className="edit-main-data-user__surname">
                <label className="edit-main-data-user__surname-label">Отчество</label>
                <input className="edit-main-data-user__surname-field-input"
                       placeholder="Введите отчество"
                       value={secondName.value}
                       onChange={(e) => onHandleInputUserName(e, 'secondName')}
                />
            </div>
            <div className="edit-main-data-user__surname">
                <label className="edit-main-data-user__surname-label">E-mail</label>
                <input className="edit-main-data-user__surname-field-input"
                       placeholder="Введите e-mail"
                       value={email.value}
                       onChange={(e) => onHandleInputUserName(e, 'email')}
                />
            </div>
            <div className="edit-main-data-user__surname">
                <label className="edit-main-data-user__surname-label">Телефон</label>
                <input className="edit-main-data-user__surname-field-input"
                       placeholder={"7xxxxxxxxxx"}
                       type="text"
                       name="phone"
                       required
                       minLength="11"
                       maxLength="11"
                       value={phone.value}
                       onChange={(e) => onHandleInputUserName(e, 'phone')}
                />
            </div>
            <button
                disabled={isDisableBtn}
                onClick={onHandleSave}
                className={isDisableBtn ? "edit-main-data-user__button-save profile-user-update-data__button-not-active" : "edit-main-data-user__button-save"}
            >
                {constants.PROFILE_USER.PROFILE_USER_SAVE_BTN}
            </button>
            <p className={message.isError ? "profile-user-update-data__message-error" : "profile-user-update-data__message-success"}>{message.text}</p>
        </div>
    )
}
export default ProfileUserUpdateUserPersonalData;