import row_input_select_role from "../../img/Auth_icon_row_select_role.svg";
import { useOnClickOutsideModal } from "../../utils/CustomHooks/useOutsideModal/useOutsideModal";
import { useState, useEffect } from "react";
import { setCorrectNameProtocol } from "../../utils/SetCorrectNameProtocol/setCorrectNameProtocol";
import { useParams } from "react-router-dom";
import * as Events from "../../Api/Events";

const ChangeProtocol = ({
    currentEventDataProtocol,
    requestHelper,
    getCurrentEvent,
    setMessageError,
    currentEventData
}) => {

  const [isSelectedProtocol, setSelectedProtocol] = useState("");
  const [isProtocolSelectList, setProtocolSelectList] = useState([]);
  const [isActiveSelectProtocol, setActiveSelectProtocol] = useState(false);

  useOnClickOutsideModal(isActiveSelectProtocol, () =>
    setActiveSelectProtocol(false)
  );

  useEffect(() => {
    let isMounted = true;
    if (currentEventData) {
      isMounted && setSelectedProtocol(currentEventDataProtocol);
      isMounted && onAddDefaultProtocolInProtocolList();
    }
    return () => {
      isMounted = false;
    };
  }, [currentEventDataProtocol, currentEventData]);

  const onAddDefaultProtocolInProtocolList = () => {
    setProtocolSelectList([
      ...currentEventData.config.protocol.org_template_links
    ]);
  };

  const { id: eventId } = useParams();

  const changeProtocolHandler = () => {
    setMessageError('');
    requestHelper(Events.changeProtocol, {
      eventId: eventId,
      orgId: currentEventData.config.org_id,
      eventTemplateLink: isSelectedProtocol,
    })
      .then((res) => {
        getCurrentEvent();
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  return (
    <>
      <h3 className="details-votes-page-result-question-row__title">
        Изменить шаблон протокола
      </h3>
      <div className="change-protocol-container">
        <div
          style={{ flexShrink: 0 }}
          onClick={() => setActiveSelectProtocol(!isActiveSelectProtocol)}
          className="change-protocol__time-zone-select-container"
        >
          <p className="change-protocol__time-zone-select-value">
            {setCorrectNameProtocol(isSelectedProtocol)}
          </p>
          <img
            className="change-protocol__time-zone-select-arrow"
            src={row_input_select_role}
            alt="Стрелочка открытия меню"
          />

          <div
            className={
              isActiveSelectProtocol
                ? "change-protocol__time-zone-options-container"
                : "change-protocol__time-zone-options-container hidden"
            }
          >
            {isProtocolSelectList.map((protocol, i) => {
              return (
                <div
                  key={i}
                  className="change-protocol__time-zone-option-container"
                  onClick={() => setSelectedProtocol(protocol)}
                >
                  <p className="change-protocol__time-zone-option">
                    {setCorrectNameProtocol(protocol)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <button
          className="change-protocol__recreate-protocol-btn"
          onClick={changeProtocolHandler}
        >
          Сохранить
        </button>
      </div>
    </>
  );
};

export default ChangeProtocol;
