import { useState, useReducer, useEffect } from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import GeneralTitleAllPages from "../GeneralTitleAllPages/GeneralTitleAllPages";
import iconProfilePhoto from "../../img/ProfileUserPersonalDataIcon.svg";
import iconTime from "../../img/ProfilelUserIconTime.svg";
import optionRow from "../../img/Auth_icon_row_select_role.svg";
import timeZone from "../../utils/TimeZoneData/TimeZoneRu.json";
import hideIconPass from "../../img/Auth_show_pass_icon.svg";
import showIconPass from "../../img/Auth_hidden_pass.svg";
import iconStarSecretary from "../../img/ProfileUserIconStar.svg";
import iconRowList from "../../img/ProfileUserIconRow.svg";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import useShowEditProfileButton from "../../utils/CustomHooks/useShowEditProfileButton/useShowEditProfileButton";
import { Validation } from "../../utils/Validation/Validation";
import useFindTimeZoneProfileUser from "../../utils/CustomHooks/useFindTimeZoneProfileUser/useFindTimeZoneProfileUser";
import useActiveOrgListProfileUser
  from "../../utils/CustomHooks/useActiveOrgListProfileUser/useActiveOrgListProfileUser";
import {useSetCountPagePagination} from "../../hooks/useSetCountPagePagination";
import ProfileUserUpdateUserData from "./ProfileUserUpdateUserData";
import ProfileUserUpdateUserPersonalData from "./ProfileUserUpdateUserPersonalData";

const ProfileUser = (props) => {

  const {
    constants,
    isUserProfile,
    handleCurrentEvents,
    onHandleGroupClick,
    onOrgSettingsClick,
    handleChangeDataUser,
    isUserProfileErrorMessage,
    setUserProfileErrorMessage,
    authAs,
    allUsers
  } = props;

  const { currentUser } = useCurrentUserContext();
  const lastName = Validation();
  const firstName = Validation();
  const secondName = Validation();
  const newPass = Validation();
  const repeatNewPass = Validation();
  const [editDataUser, setEditDataUser] = useState(false);
  const [btnActiveOrgGroup, setBtnActiveOrgGroup] = useState(true);
  const [btnActiveVotes, setBtnActiveVotes] = useState(false);
  const [timeZoneLocation, setTimeZoneLocation] = useState(
      "(UTC+3) Россия - Москва - московское время"
  );
  const [timeZoneValue, setTimeZoneValue] = useState(3);
  const [isTimeZoneOptionsOpen, setTimeZoneOptionsOpen] = useState(false);
  const [showListGroup, setShowListGroup] = useState(false);
  const [showListOrg, setShowListOrg] = useState(false);
  const [isEditTimeZone, setEditTimeZone] = useState(false);
  const [changeTypeNewPass, dispatchNewPass] = useReducer(reducer, {
    changeTypeNewPass: false,
  });
  const [changeTypeRepeatPass, dispatchRepeatPass] = useReducer(reducer, {
    changeTypeRepeatPass: false,
  });
  const [isShowEditProfileUser, setShowEditProfileUser] =
      useShowEditProfileButton(currentUser, isUserProfile);
  const [isFindTimeZoneCurrentUser, setFindTimeZoneCurrentUser] =
      useFindTimeZoneProfileUser(isUserProfile.utcOffset);
  const { activeListOrgEvent } = useActiveOrgListProfileUser(isUserProfile, currentUser);
  const [isShowSaveNewDataUser, setShowSaveNewDataUser] = useState(false);
  const [eventItems, setEventItems] = useState(isUserProfile.events !== undefined && isUserProfile.events.length > 10 ? isUserProfile.events.slice(0, 10) : isUserProfile.events);
  const [page, setPage] = useState(1);
  const countPage = useSetCountPagePagination(isUserProfile);

  function paginationEventsArray(array, chunk) {
    const newArray = [];
    for (let i = 0; i < array.length; i += chunk) {
      newArray.push(array.slice(i, i + chunk));
    }
    return newArray;
  }

  const handleChangePage = (event, value) => {
    setPage(value);
    setEventItems([]);
    const partsEventsArray = paginationEventsArray(isUserProfile.events, 10);
    setEventItems(partsEventsArray[value - 1]);
  };

  const bodyChangeDataUser = {
    first_name:
        firstName.value !== "" ? firstName.value : isUserProfile?.first_name,
    last_name:
        lastName.value !== "" ? lastName.value : isUserProfile?.last_name,
    second_name:
        secondName.value !== "" ? secondName.value : isUserProfile?.second_name,
    password: newPass.value,
    repeatPassword: repeatNewPass.value,
    utcOffset: isEditTimeZone ? timeZoneValue : isUserProfile?.utcOffset,
    userFields: isUserProfile?.userFields,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "toggleTypePass":
        return { changeTypePass: !state.changeTypePass };
      case "toggleTypeNewPass":
        return { changeTypeNewPass: !state.changeTypeNewPass };
      case "toggleTypeRepeatPass":
        return { changeTypeRepeatPass: !state.changeTypeRepeatPass };
      default:
        throw new Error();
    }
  }

  function onHandleCloseEditUserData() {
    setUserProfileErrorMessage("");
    setEditDataUser(false);
    lastName.setValue("");
    firstName.setValue("");
    secondName.setValue("");
    newPass.setValue("");
    repeatNewPass.setValue("");
    setEditTimeZone(false);
    setShowSaveNewDataUser(false);
  }

  function toggleActiveHide() {
    setBtnActiveOrgGroup(true);
    setBtnActiveVotes(false);
  }

  function toggleArchiveShow() {
    setBtnActiveOrgGroup(false);
    setBtnActiveVotes(true);
  }

  function onSelectTimeZoneClick(location) {
    setTimeZoneValue(location.VALUE);
    setTimeZoneLocation(location.LABEL);
  }

  function onCheckUserCurrentTimezone() {
    if (isUserProfile?.utcOffset === String(timeZoneValue)) {
      setEditTimeZone(false);
    } else {
      setEditTimeZone(true);
    }
  }

  useEffect(() => {
    let isMounted = true;
    isMounted && onCheckUserCurrentTimezone();
    return () => {
      isMounted = false;
    };
  }, [timeZoneValue]);

  function handleTimeZoneOptionsOpen() {
    if (isTimeZoneOptionsOpen) {
      setTimeZoneOptionsOpen(false);
    } else {
      setTimeZoneOptionsOpen(true);
    }
  }

  useEffect(() => {
    let isMounted = true;
    isMounted && onHandleCloseEditUserData();
    return () => {
      isMounted = false;
    };
  }, [isUserProfile]);

  useEffect(() => {
    let isMounted = true;
    if (
        lastName.value !== "" ||
        firstName.value !== "" ||
        secondName.value !== ""
    ) {
      isMounted && setShowSaveNewDataUser(true);
    } else if (newPass.value !== "" && repeatNewPass.value !== "") {
      isMounted && setShowSaveNewDataUser(true);
    } else if (isEditTimeZone) {
      isMounted && setShowSaveNewDataUser(true);
    } else {
      setUserProfileErrorMessage("");
      isMounted && setShowSaveNewDataUser(false);
    }
    return () => {
      isMounted = false;
    };
  }, [
    isShowSaveNewDataUser,
    lastName.value,
    firstName.value,
    secondName.value,
    newPass.value,
    repeatNewPass.value,
    isEditTimeZone,
  ]);

  return (
      <div className="container__profile-user _container">
        <GeneralTitleAllPages
            firstLetter={constants.GENERAL_TITLE.GENERAL_TITLE_FIRTSLETTER}
            secondLetter={
              constants.GENERAL_TITLE.GENERAL_TITLE_SECONDLETTER_GROUP_USERS
            }
            titleName={constants.GENERAL_TITLE.GENERAL_TITLE_TITLENAME_PROFILE}
        />
        <div className="profile-user">
          <div className="profile-user__personal-data-update-user-data">
            <div className="profile-user__personal-data-user">
              <img
                  alt={"иконка"}
                  src={iconProfilePhoto}
                  className="personal-data-user__icon-photo"
              />
              <div
                  className={
                    editDataUser
                        ? "personal-data-user__main-data-user active"
                        : "personal-data-user__main-data-user"
                  }
              >
                <div>
                  <p className="main-data-user__username">
                    {isUserProfile?.last_name} {isUserProfile?.first_name}{" "}
                    {isUserProfile?.second_name}
                  </p>
                  <p className="main-data-user__email">{isUserProfile?.email}</p>
                  <div className="main-data-user__timezone">
                    <img
                        alt={"иконка часы"}
                        src={iconTime}
                        className="main-data-user__timezone-icon"
                    />
                    {isUserProfile?.utcOffset !== undefined ? (
                        <p className="main-data-user__timezone-current">
                          {isFindTimeZoneCurrentUser?.LABEL}
                        </p>
                    ) : (
                        <p className="main-data-user__timezone-current">
                          {constants.PROFILE_USER.PROFILE_USER_NOT_UTC_TIMEZONE}
                        </p>
                    )}
                  </div>
                </div>
                {isShowEditProfileUser && (
                    <button
                        onClick={() => setEditDataUser(true)}
                        className="main-data-user__edit-button"
                    >
                      {constants.PROFILE_USER.PROFILE_USER_EDIT_BTN}
                    </button>
                )}
              </div>
              <div
                  className={
                    editDataUser
                        ? "personal-data-user__edit-main-data-user active"
                        : "personal-data-user__edit-main-data-user"
                  }
              >
                <p className="edit-main-data-user__email">{isUserProfile?.email}</p>
                <p className="edit-main-data-user__title-change-data">
                  {constants.PROFILE_USER.PROFILE_USER_CHANGE_PERSONAL_DATA}
                </p>
                <div className="edit-main-data-user__surname">
                  <label className="edit-main-data-user__surname-label">
                    {constants.PROFILE_USER.PROFILE_USER_SURNAME}
                  </label>
                  <input
                      className="edit-main-data-user__surname-field-input"
                      placeholder={isUserProfile?.last_name}
                      value={lastName.value}
                      onChange={lastName.onChange}
                  />
                </div>
                <div className="edit-main-data-user__name">
                  <label className="edit-main-data-user__name-label">
                    {constants.PROFILE_USER.PROFILE_USER_NAME}
                  </label>
                  <input
                      className="edit-main-data-user__name-field-input"
                      placeholder={isUserProfile?.first_name}
                      value={firstName.value}
                      onChange={firstName.onChange}
                  />
                </div>
                <div className="edit-main-data-user__middle-name">
                  <label className="edit-main-data-user__middle-name-label">
                    {constants.PROFILE_USER.PROFILE_USER_MIDDLE_NAME}
                  </label>
                  <input
                      className="edit-main-data-user__middle-name-field-input"
                      placeholder={isUserProfile?.second_name}
                      value={secondName.value}
                      onChange={secondName.onChange}
                  />
                </div>
                <div className="edit-main-data-user__time-zone-main-container">
                  <p className="edit-main-data-user__time-zone-heading">
                    {constants.PROFILE_USER.PROFILE_USER_CHANGE_TIMEZONE}
                    <span className="reg-main-block__red-star-heading_span">*</span>
                  </p>
                  <div
                      className="edit-main-data-user__time-zone-select-container"
                      onClick={handleTimeZoneOptionsOpen}
                  >
                    <p className="edit-main-data-user__time-zone-select-value">
                      {timeZoneLocation}
                    </p>
                    <img
                        className="edit-main-data-user__time-zone-select-arrow"
                        src={optionRow}
                        alt="Стрелочка открытия меню"
                    />
                    {isTimeZoneOptionsOpen && (
                        <div className="edit-main-data-user__time-zone-options-container">
                          {timeZone?.map((location, index) => (
                              <p
                                  className="edit-main-data-user__time-zone-option"
                                  key={index}
                                  onClick={() => onSelectTimeZoneClick(location)}
                              >
                                {location.LABEL}
                              </p>
                          ))}
                        </div>
                    )}
                  </div>
                  <span className="edit-main-data-user__time-zone-main-container__error-message"></span>
                </div>
                <p className="edit-main-data-user__title-change-pass">
                  {constants.PROFILE_USER.PROFILE_USER_CHANGE_PASS}
                </p>
                {/*<div className="edit-main-data-user__pass">*/}
                {/*    <label className="edit-main-data-user__pass-label">{constants.PROFILE_USER.PROFILE_USER_PASS}</label>*/}
                {/*    <input type={changeTypePass.changeTypePass ? 'text' : 'password'} className="edit-main-data-user__pass-input" />*/}
                {/*    <img onClick={() => dispatchPass({ type: 'toggleTypePass' })} src={changeTypePass.changeTypePass ? showIconPass : hideIconPass} alt={'иконка скрыть/показать пароль'} className="edit-main-data-user__pass-icon-show" />*/}
                {/*</div>*/}
                <div className="edit-main-data-user__new-pass">
                  <label className="edit-main-data-user__new-pass-label">
                    {constants.PROFILE_USER.PROFILE_USER_NEW_PASS}
                  </label>
                  <input
                      className="edit-main-data-user__new-pass-input"
                      type={changeTypeNewPass.changeTypeNewPass ? "text" : "password"}
                      value={newPass.value}
                      onChange={newPass.onChange}
                  />
                  <img
                      onClick={() => dispatchNewPass({type: "toggleTypeNewPass"})}
                      src={
                        changeTypeNewPass.changeTypeNewPass
                            ? showIconPass
                            : hideIconPass
                      }
                      alt={"иконка скрыть/показать пароль"}
                      className="edit-main-data-user__new-pass-icon-show"
                  />
                </div>
                <div className="edit-main-data-user__repeat-pass">
                  <label className="edit-main-data-user__repeat-pass-label">
                    {constants.PROFILE_USER.PROFILE_USER_REPEAT_PASS}
                  </label>
                  <input
                      className="edit-main-data-user__repeat-pass-input"
                      type={
                        changeTypeRepeatPass.changeTypeRepeatPass
                            ? "text"
                            : "password"
                      }
                      value={repeatNewPass.value}
                      onChange={repeatNewPass.onChange}
                  />
                  <img
                      onClick={() =>
                          dispatchRepeatPass({type: "toggleTypeRepeatPass"})
                      }
                      src={
                        changeTypeRepeatPass.changeTypeRepeatPass
                            ? showIconPass
                            : hideIconPass
                      }
                      alt={"иконка скрыть/показать пароль"}
                      className="edit-main-data-user__repeat-pass-icon-show"
                  />
                </div>
                <p className="profile-user__error-message">
                  {isUserProfileErrorMessage}
                </p>
                {isShowSaveNewDataUser ? (
                    <>
                      <button
                          className="edit-main-data-user__button-save"
                          onClick={() =>
                              handleChangeDataUser(isUserProfile, bodyChangeDataUser)
                          }
                      >
                        {constants.PROFILE_USER.PROFILE_USER_SAVE_BTN}
                      </button>
                      <button
                          className="edit-main-data-user__button-save edit-main-data-user__button-save--mr-top"
                          onClick={() => setEditDataUser(false)}
                      >
                        {constants.PROFILE_USER.PROFILE_USER_CANCEL_BTN}
                      </button>
                    </>
                ) : (
                    <button
                        className="edit-main-data-user__button-save"
                        onClick={() => onHandleCloseEditUserData()}
                    >
                      {constants.PROFILE_USER.PROFILE_USER_CANCEL_BTN}
                    </button>
                )}
              </div>
            </div>
            {authAs === 'ROLE_SUPER_ADMIN'
                &&
                <>
                  <ProfileUserUpdateUserData
                      constants={constants}
                      allUsers={allUsers}
                  />
                  <ProfileUserUpdateUserPersonalData
                      constants={constants}
                      allUsers={allUsers}
                  />
                </>
            }
          </div>
          <div className="profile-user__organizations-groups-users-votes">
            <div className="votes-page-switch-buttons">
              <div>
                <h2
                    onClick={() => {
                      toggleActiveHide();
                    }}
                    className={
                      btnActiveOrgGroup
                          ? "active-votes-page-switch-buttons__button"
                          : "votes-page-switch-buttons__button"
                    }
                >
                <span className="profile-user__switch-button">
                  {constants.PROFILE_USER.PROFILE_USER_ORG_GROUP_USERS}
                </span>
                  <span className="profile-user__switch-button_mobile">
                  {constants.PROFILE_USER.PROFILE_USER_ORG_GROUP_USERS_MOBILE}
                </span>
                </h2>
              </div>
              <div>
                <h2
                    onClick={() => {
                      toggleArchiveShow();
                    }}
                    className={
                      btnActiveVotes
                          ? "active-votes-page-switch-buttons__button"
                          : "votes-page-switch-buttons__button"
                    }
                >
                  {constants.PROFILE_USER.PROFILE_USER_VOTES}
                </h2>
              </div>
            </div>
            {btnActiveOrgGroup && (
                <>
                  {(isUserProfile?.organizations !== undefined && isUserProfile?.organizations?.length !== 0) ||
                  (isUserProfile?.groups !== undefined && isUserProfile?.groups?.length !== 0) ? (
                      <div className="organizations-groups-users-votes__org-groups-list">
                        {isUserProfile?.organizations?.length !== 0 && (
                            <>
                              <div
                                  onClick={() => setShowListOrg(!showListOrg)}
                                  className="org-groups-list__select-row-list"
                              >
                                <p className="select-row-list__label">
                                  {
                                    constants.PROFILE_USER
                                        .PROFILE_USER_ACTIVITY_IN_ORGANIZATION
                                  }
                                </p>
                                <img
                                    className={
                                      showListOrg
                                          ? "select-row-list__icon-row active"
                                          : "select-row-list__icon-row"
                                    }
                                    alt={"иконка стрелочка"}
                                    src={iconRowList}
                                />
                              </div>
                              <div
                                  className={
                                    showListOrg
                                        ? "org-groups-list__list-activity-user active"
                                        : "org-groups-list__list-activity-user"
                                  }
                              >
                                {isUserProfile?.organizations?.map((el) => {
                                  return (
                                      <div
                                          key={el?.id}
                                          className="org-groups-list__title-icon-status"
                                      >
                                        <h3
                                            onClick={() => onOrgSettingsClick(el)}
                                            className={activeListOrgEvent ? "title-icon-status__title" : "title-icon-status__title-not-active"}
                                        >
                                          {el?.title}
                                        </h3>
                                        {el?.isAdmin === true && (
                                            <div className="title-icon-status__icon-status-block">
                                              <img
                                                  className="title-icon-status__icon"
                                                  src={iconStarSecretary}
                                                  alt={"иконка звезда"}
                                              />
                                              <span className="title-icon-status__status">
                                    {
                                      constants.PROFILE_USER
                                          .PROFILE_USER_STATUS_SECRETARY
                                    }
                                  </span>
                                            </div>
                                        )}
                                      </div>
                                  );
                                })}
                              </div>
                            </>
                        )}
                        {isUserProfile?.groups?.length !== 0 && (
                            <>
                              <div
                                  onClick={() => setShowListGroup(!showListGroup)}
                                  className="org-groups-list__select-row-list"
                              >
                                <p className="select-row-list__label">
                                  {
                                    constants.PROFILE_USER
                                        .PROFILE_USER_ACTIVITY_IN_USER_GROUPS
                                  }
                                </p>
                                <img
                                    className={
                                      showListGroup
                                          ? "select-row-list__icon-row active"
                                          : "select-row-list__icon-row"
                                    }
                                    alt={"иконка стрелочка"}
                                    src={iconRowList}
                                />
                              </div>
                              <div
                                  className={
                                    showListGroup
                                        ? "org-groups-list__list-activity-user active"
                                        : "org-groups-list__list-activity-user"
                                  }
                              >
                                {isUserProfile?.groups?.map((el) => {
                                  return (
                                      <p
                                          onClick={() => onHandleGroupClick(el?.id)}
                                          key={el?.id}
                                          className="list-activity-user__name-group"
                                      >
                                        {el?.group_title}
                                      </p>
                                  );
                                })}
                                {/*<p className="list-activity-users__show-all-btn">{constants.PROFILE_USER.PROFILE_USER_SHOW_ALL}</p>*/}
                              </div>
                            </>
                        )}
                      </div>
                  ) : (
                      <div>
                        <p className="profile-user__votes-empty-message">
                          {
                            constants.PROFILE_USER
                                .PROFILE_USER_EMPTY_MESSAGE_ORG_GROUPS
                          }
                        </p>
                      </div>
                  )}
                </>
            )}
            {btnActiveVotes && (
                <>
                  {isUserProfile?.events !== undefined && isUserProfile?.events?.length !== 0 ? (
                      <div className="profile-user__votes">
                        {eventItems.map((el, index) => {
                          return (
                              <div
                                  key={index}
                                  className="profile-user__votes__vote-event"
                              >
                                <div className="profile-user__votes__vote-event-name-event">
                                  <p
                                      onClick={() => handleCurrentEvents(el)}
                                      className={activeListOrgEvent ? "profile-user__votes-name-event" : "profile-user__votes-name-event-not-active"}
                                  >
                                    {el?.title}
                                  </p>
                                  <p className="profile-user__votes-name-org">
                                    {el?.owner?.title}
                                  </p>
                                </div>
                                <div className="profile-user__votes__roles-user">
                                  {el?.isVoter === true && (
                                      <div className="profile-user__votes__role-user-voter">
                                        {
                                          constants.PROFILE_USER
                                              .PROFILE_USER_VOTE_STATUS_VOTER
                                        }
                                      </div>
                                  )}
                                  {el?.isObserver === true && (
                                      <div className="profile-user__votes__role-user-observer">
                                        {
                                          constants.PROFILE_USER
                                              .PROFILE_USER_VOTE_STATUS_OBSERVER
                                        }
                                      </div>
                                  )}
                                  {el?.isCounter === true && (
                                      <div className="profile-user__votes__role-user-counter">
                                        {
                                          constants.PROFILE_USER
                                              .PROFILE_USER_VOTE_STATUS_COUNTER
                                        }
                                      </div>
                                  )}
                                </div>
                              </div>
                          );
                        })}
                        {isUserProfile?.events?.length > 10 && (
                            <>
                              <Stack spacing={2}>
                                <Pagination count={countPage} page={page} onChange={handleChangePage} />
                              </Stack>
                            </>
                        )}
                      </div>
                  ) : (
                      <div>
                        <p className="profile-user__votes-empty-message">
                          {constants.PROFILE_USER.PROFILE_USER_EMPTY_MESSAGE_VOTE}
                        </p>
                      </div>
                  )}
                </>
            )}
          </div>
        </div>
      </div>
  );
};
export default ProfileUser;