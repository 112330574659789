import * as Event from "../../Api/Events";
import * as Groups from "../../Api/Groups";
import * as Users from "../../Api/Users";
import * as Templates from "../../Api/Template";
import * as Moderators from "../../Api/Moderators";

export const appRequestsAuthUser = (
  currentUser,
  requestHelper,
  isMounted,
  setAllEvents,
  setAllGroups,
  setAllUsers,
  setAllTemplates,
  setAllModerators,
  firstSubscribeToEvents,
  authAs
) => {
//   const authAs = localStorage.getItem("authAs");

  if (authAs === "ROLE_ADMIN") {
    requestHelper(Event.getEvents)
      .then((data) => {
        if (data.status === "failure") {
          isMounted && setAllEvents([]);
        } else {
          isMounted && setAllEvents(data);
          firstSubscribeToEvents(data);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
    requestHelper(Groups.getGroups)
      .then((data) => {
        if (data.status === "failure") {
          isMounted && setAllGroups([]);
        } else {
          isMounted && setAllGroups(data);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
    requestHelper(Users.getUsers)
      .then((data) => {
        if (data.status === "failure") {
          isMounted && setAllUsers([]);
        } else {
          isMounted && setAllUsers(data);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
    requestHelper(Templates.getTemplates)
      .then((data) => {
        if (data.status === "failure") {
          isMounted && setAllTemplates([]);
        } else {
          isMounted && setAllTemplates(data);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  } else if (authAs === "ROLE_SUPER_ADMIN") {
    requestHelper(Event.getEvents)
      .then((data) => {
        if (data.status === "failure") {
          isMounted && setAllEvents([]);
        } else {
          isMounted && setAllEvents(data);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
    requestHelper(Groups.getGroups)
      .then((data) => {
        if (data.status === "failure") {
          isMounted && setAllGroups([]);
        } else {
          isMounted && setAllGroups(data);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
    requestHelper(Users.getUsers)
      .then((data) => {
        if (data.status === "failure") {
          isMounted && setAllUsers([]);
        } else {
          isMounted && setAllUsers(data);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
    // requestHelper(Moderators.getModerators)
    //   .then((data) => {
    //     if (data.status === "failure") {
    //       isMounted && setAllModerators([]);
    //     } else {
    //       isMounted && setAllModerators(data);
    //     }
    //   })
    //   .catch((err) => {
    //     throw new Error(err.message);
    //   });
  } else if (authAs === "ROLE_COUNTER" || authAs === "observer") {
    requestHelper(Event.getEvents)
      .then((data) => {
        if (data.status === "failure") {
          isMounted && setAllEvents([]);
        } else {
          isMounted && setAllEvents(data);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }
};
