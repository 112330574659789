import axios from "axios";
import { config } from "../config";
import useRefreshToken from "../hooks/useRefreshToken";

const authService = axios.create({
  baseURL: config.auth_api_url,
  headers: {
    "Content-type": "application/json",
  },
});

authService.interceptors.request.use((axiosConfig) => {
  // Do something before request is sent
  let token = localStorage.getItem("accessToken");
  if (token) {
    axiosConfig.headers["Authorization"] = "Bearer " + token;
  }
  return axiosConfig;
});

authService.interceptors.response.use(response => response,
    async (error) => {
      const refresh = useRefreshToken();
      const prevRequest = error?.config;
      if(error?.response?.status === 401 && !prevRequest?.sent) {
        prevRequest.sent = true;
        const newAccessToken = await refresh();
        prevRequest.headers["Authorization"] = `Bearer ${newAccessToken?.accessToken}`;
        return authService(prevRequest);
      }
      return Promise.reject(error);
    }
);

/********************************/

export const updateUser = async (dataForUpdate) => {
  await authService
    .patch(`/users/`, JSON.stringify(dataForUpdate))
    .then((resp) => resp.data);
};

export const updateUserFromSuperAdmin = async (dataForUpdate) => {
    await authService
        .patch(`/users/{userId}?userId=${dataForUpdate.id}`, JSON.stringify(dataForUpdate))
        .then((resp) => resp.data);
};

export const addSingleUser = async (dataForAdd) => {
  await authService
    .post(`/users/`, JSON.stringify(dataForAdd))
    .then((resp) => resp.data)
};

/*Настройки аутентификации*/

export const getAllSettingAuthDomains = async () =>
    await authService
        .get("auth-settings").then((resp) => {
            if (resp.data.status === "failure") {
                console.error(resp.data.text);
            }
            return resp.data;
        });

export const addSettingAuthDomains = async (domain, dataDomains) => {
    await authService
        .post(`auth-types?host=${domain}`, JSON.stringify(dataDomains))
        .then((resp) => resp.data);
};

export const deleteSettingAuthDomains = async (domain) => {
    await authService
        .put(`auth-settings?subdomain=${domain}`)
        .then((resp) => resp.data);
};

export const deleteUser = async (userId) => {
    return await authService
        .delete(`users/${userId}`)
        .then((resp) => resp.data);
};
