import React, {useState, useEffect} from "react";
import optionRow from "../../img/Auth_icon_row_select_role.svg";
import {Validation} from "../../utils/Validation/Validation";
import {useMutation} from "@tanstack/react-query";
import {updateUserFromSuperAdmin} from "../../Api/auth-service";

const ProfileUserUpdateUserData = (props) => {

    const {
        constants,
        allUsers
    } = props;

    const newPass = Validation();
    const [isOptionsOpen, setOptionsOpen] = useState(false);
    const [isDisableBtn, setDisableBtn] = useState(true);
    const [resultForRender, setResultsForRender] = useState([]);
    const [message, setMessage] = useState({
        isError: false,
        text: ''
    });
    const [selectUser, setSelectUser] = useState(
        {
            nameUser: '',
            dataUser: {}
        }
    );

    const updateUserMutation = useMutation({
        mutationFn: ({body}) => {
            return updateUserFromSuperAdmin(body)
        },
        onSuccess: () => {
            setDisableBtn(true);
            setSelectUser({
                nameUser: '',
                dataUser: {}
            });
            newPass.setValue('');
            setMessage({
                isError: false,
                text: 'Пароль сохранен!'
            });
            setTimeout(() => {
                setMessage({
                    isError: false,
                    text: ''
                });
            }, 2000)
        },
        onError: (error) => {
            if(error.response.status === 400) {
                if(error.response.data.text.includes('is not valid')) {
                    setMessage({
                        isError: true,
                        text: 'Ваш пароль должен содержать символы нижнего и верхнего регистра, содержать хотя бы одну цифру и символ и иметь длину не менее 8 символов.'
                    })
                } else {
                    setMessage(
                        {
                            isError: true,
                            text: `${error.response.data.text}`
                        })
                }
            } else {
                setMessage(
                    {
                        isError: true,
                        text: `${error.response.data.text}`
                    })
            }
        }
    });

    useEffect(() => {
        let isMounted = true;

        isMounted && setResultsForRender(allUsers && allUsers.length !== 0 ? allUsers : []);

        return () => {
            isMounted = false;
        }
    },[allUsers])

    function handleOptionsOpen() {
        if (isOptionsOpen) {
            setOptionsOpen(false);
        } else {
            setOptionsOpen(true);
        }
    }

    function onSelectClick(user) {
        setSelectUser({...selectUser,
            nameUser: user.fullName,
            dataUser: user
        });
        setOptionsOpen(false);
        setDisableBtn(false);
        setResultsForRender(allUsers && allUsers.length !== 0 ? allUsers : []);
    }

    function onHandleInput(e) {
        setSelectUser({...selectUser,
            nameUser: e.currentTarget.value
        });

        if (e.currentTarget.value === "") {
            onActiveButton(resultForRender, e.currentTarget.value)
            setOptionsOpen(false);
            setResultsForRender(allUsers && allUsers.length !== 0 ? allUsers : []);
        } else {
            setMessage({
                isError: false,
                text: ""
            });
            setDisableBtn(false);
            const resultsForRender = [];
            allUsers.forEach((user) => {
                if (user.fullName.toLowerCase().includes(selectUser.nameUser.toLowerCase())) {
                    resultsForRender.push(user);
                }
            });
            onActiveButton(resultsForRender, e.currentTarget.value)
            setOptionsOpen(true);
            setResultsForRender(resultsForRender);
        }
    }

    function onActiveButton(results, user) {
        if(results.length === 0 || user === '') {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
    }

    function onHandleSave() {
        if(newPass.value === '') {
            return setMessage({
                isError: true,
                text: 'Необходимо ввести пароль!'})
        }
        const body = {
            id: selectUser.dataUser.id,
            password: newPass.value
        }
        updateUserMutation.mutate({body: body})
    }

    useEffect(() => {
        let isMounted = true;
        if(newPass.value !== '') {
            isMounted && setMessage('');
        }
        return () => {
            isMounted = false;
        }
    },[newPass.value])

    return (
        <div className="profile-user-update-data">
            <p className="edit-main-data-user__title-change-pass">Сброс пароля пользователя</p>
            <div className="edit-main-data-user__time-zone-main-container" style={{marginBottom: '0.88em'}}>
                <p className="edit-main-data-user__time-zone-heading">
                    Выберите пользователя
                    <span className="reg-main-block__red-star-heading_span">*</span>
                </p>
                <div
                    className="edit-main-data-user__time-zone-select-container"
                >
                    <input
                        type="text"
                        className="profile-user-update-data__select-value"
                        value={selectUser.nameUser}
                        onChange={(e) => onHandleInput(e)}
                    />
                    <img
                        onClick={handleOptionsOpen}
                        className="edit-main-data-user__time-zone-select-arrow profile-user-update-data__select-arrow"
                        src={optionRow}
                        alt="Стрелочка открытия меню"
                    />
                    {isOptionsOpen && (
                        <div className="edit-main-data-user__time-zone-options-container">
                            {resultForRender.length !== 0 ? (
                                <>
                                    {resultForRender?.map((user, index) => (
                                        <p
                                            className="edit-main-data-user__time-zone-option"
                                            key={index}
                                            onClick={() => onSelectClick(user)}
                                        >
                                            {user.fullName}
                                        </p>
                                    ))}
                                </>
                            ) : (
                                <p className="profile-user-update-data__not-found-option">
                                    Пользователь не найден!
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <span className="edit-main-data-user__time-zone-main-container__error-message"></span>
            </div>
            <div className="edit-main-data-user__surname">
                <label className="edit-main-data-user__surname-label">Email</label>
                <input className="edit-main-data-user__surname-field-input"
                       placeholder="Email найденного пользователя"
                       value={selectUser.nameUser ? selectUser?.dataUser?.email : ''}
                       disabled={true}
                />
            </div>
            <div className="edit-main-data-user__surname">
                <label className="edit-main-data-user__surname-label">Новый пароль</label>
                <input className="edit-main-data-user__surname-field-input"
                       placeholder="Введите пароль"
                       value={newPass.value}
                       onChange={newPass.onChange}
                />
            </div>
            <button
                disabled={isDisableBtn}
                onClick={onHandleSave}
                className={isDisableBtn ? "edit-main-data-user__button-save profile-user-update-data__button-not-active" : "edit-main-data-user__button-save"}
            >
                {constants.PROFILE_USER.PROFILE_USER_SAVE_BTN}
            </button>
            <p className={message.isError ? "profile-user-update-data__message-error" : "profile-user-update-data__message-success"}>{message.text}</p>
        </div>
    )
}
export default ProfileUserUpdateUserData;