import { useState, useEffect } from "react";

export const useGetComments = (commentsObj, questionId) => {

    const [comments, setComments] = useState([]);

    useEffect(() => {

        let isMounted = true;

        if (commentsObj && Object.values(commentsObj).length !== 0) {
            for (let key in commentsObj) {
                if(key === questionId) {
                    isMounted && setComments(Object.values(commentsObj[key]))
                }
            }
        }

        return () => {
            isMounted = false;
        }
    }, [commentsObj]);

    return comments
}