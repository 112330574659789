import React, {useState} from "react";
import rowIcon from "../../img/drop-down-arrow.svg";

const DetailsVoteResultsComments = (props) => {

    const {
        comment
    } = props;

    const [isActiveOpen, setActiveOpen] = useState(false);

    return (
        <div className="details-vote-results-comment__wrap">
            <div className="details-vote-results-comment-header">
                <div className="details-vote-results-comment-header__label">Особое мнение</div>
                <div className="details-vote-results-comment-header__icon-block">
                    <span className="details-vote-results-comment-header__count">{comment?.length}</span>
                    <img onClick={() => setActiveOpen(!isActiveOpen)}
                         className={isActiveOpen ? "details-vote-results-comment-header__icon active" : "details-vote-results-comment-header__icon"} src={rowIcon} alt="иконка"/>
                </div>
            </div>
            {
                comment.map((el, i) => {
                 return (
                     <div key={i} className={isActiveOpen ? "details-vote-results-comment-content active" : "details-vote-results-comment-content"}>
                        <div className="details-vote-results-comment-content__label">
                            {el?.comment?.anonymous ? (
                                <p className="details-vote-results-comment-content__name">Анонимное мнение</p>
                            ) : (
                                <>
                                    <p className="details-vote-results-comment-content__name">{el?.user?.lastName} {el?.user?.firstName}</p>
                                    <p className="details-vote-results-comment-content__email">{el?.user?.email}</p>
                                </>
                            )}
                        </div>
                         <div className="details-vote-results-comment-content__text-block">
                            <p className="details-vote-results-comment-content__text">{el?.comment?.text}</p>
                             {el?.comment?.hasLink &&
                                 <div className="details-vote-results-comment-content__link">
                                     <a href={el?.comment?.link} target="_blank"
                                        className="details-vote-results-comment-content__link-name" rel="noreferrer">
                                         Ссылка на файл
                                     </a>
                                 </div>
                             }
                         </div>
                     </div>
                 )
                })}
        </div>
    )
}
export default DetailsVoteResultsComments;