import * as Auth from "../Api/Auth";

const useRefreshToken = () => {

    const refreshToken = localStorage.getItem("refreshToken");
    const userData = localStorage.getItem("user");
    const user = JSON.parse(userData);
    const userAuthAs = user?.authorities[0]?.role;

    return async () => {
        try {
            const response = await Auth.login({ type: "refresh-token", token: refreshToken, authAs: userAuthAs })
            if (response.status === 400) {
                console.log('logout')
            } else {
                localStorage.setItem("accessToken", response.accessToken);
                return response;
            }
        } catch (err) {
            console.log(err);
        }
    }
}
export default useRefreshToken;
