import React, {useEffect, useState} from "react";
import Switch from '@mui/material/Switch';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    getAllSettingAuthDomains,
    deleteSettingAuthDomains,
    addSettingAuthDomains,
} from "../../Api/auth-service";
import {Validation} from "../../utils/Validation/Validation";
import optionRow from "../../img/Auth_icon_row_select_role.svg";
import successIcon from "../../img/iconGreenSuccessDomain.svg";

const AuthenticationSettings = (props) => {

    const {
        constants
    } = props;

    const newDomain = Validation();
    const [isActiveSaveButton, setActiveSaveButton] = useState(false);
    const [isErrorMessage, setErrorMessage] = useState("");
    const [isSuccessChangeSettings, setSuccessChangeSettings] = useState(false);
    const [domainSelected, setDomainSelected] = useState( "");
    const [domainValue, setDomainValue] = useState("");
    const [isSelectOptionsOpen, setSelectOptionsOpen] = useState(false);
    const [isShowAddDomain, setShowAddDomain ] = useState({
        isShow: true,
        isEdit: false,
        isDelete: false
    });
    const [authTypes, setAuthTypes] = useState([
        {
            nameType: 'email/password',
            active: false
        },
        {
            nameType: 'phone/password',
            active: false
        },
        {
            nameType: 'email/password/code',
            active: false
        },
        {
            nameType: 'phone/password/code',
            active: false
        }
    ]);

    const queryClient = useQueryClient()

    const getDomains = useQuery({
        queryKey: ['list-auth-org'],
        queryFn: getAllSettingAuthDomains,
    })

    const deleteAuthSettingsDomainMutation = useMutation({
        mutationFn: (domain) => {
            return deleteSettingAuthDomains(domain)
        },
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: ['list-auth-org'] })
            setActiveSaveButton(false);
            setDomainSelected('');
            setSuccessChangeSettings(true)
            setTimeout(() => setSuccessChangeSettings(false), 2000);
        },
    });

    const addAuthSettingsDomainMutation = useMutation({
        mutationFn: ({domain, body}) => {
            return addSettingAuthDomains(domain, body)
        },
        onSuccess: () => {
            // Invalidate and refetch
            if(isShowAddDomain.isEdit) {
                queryClient.invalidateQueries({ queryKey: ['list-auth-org'] })
                setActiveSaveButton(false);
                setSuccessChangeSettings(true);
                setTimeout(() => setSuccessChangeSettings(false), 2000);
            } else {
                queryClient.invalidateQueries({ queryKey: ['list-auth-org'] })
                setActiveSaveButton(false);
                newDomain.setValue('');
                setSuccessChangeSettings(true);
                const updatedAuthTypes = authTypes.map((el) => (
                    {...el, active: false}
                ))
                setAuthTypes(updatedAuthTypes);
                setTimeout(() => setSuccessChangeSettings(false), 2000);
            }
        },
    });

    useEffect(() => {
        let isMounted = true;
            isMounted && setActiveSaveButton(false);
            const updatedAuthTypes = authTypes.map((el) => (
                {...el, active: false}
            ))
            isMounted && setDomainValue('');
            isMounted && setSelectOptionsOpen(false);
            isMounted && setAuthTypes(updatedAuthTypes);
            isMounted && setErrorMessage('');
            isMounted && setDomainSelected('');
            newDomain.setValue('');
        return () => {
            isMounted = false;
        }
    }, [isShowAddDomain]);

    function handleCheckType(value) {
        const findCurrentType = authTypes.find(el => el.nameType === value.nameType)
        if(findCurrentType.active === false) {
            const changedType = {...findCurrentType, active: true};
            const updatedAuthTypes = authTypes.map(el => el.nameType === changedType.nameType ? changedType : el);
            setAuthTypes(updatedAuthTypes);
            setActiveSaveButton(true);
        } else if(findCurrentType.active === true) {
            const changedType = {...findCurrentType, active: false};
            const updatedAuthTypes = authTypes.map(el => el.nameType === changedType.nameType ? changedType : el);
            setAuthTypes(updatedAuthTypes);
            setActiveSaveButton(true);
        }
    }

    function handleActiveOptionsOpen() {
        if (isSelectOptionsOpen) {
            setSelectOptionsOpen(false);
        } else {
            setSelectOptionsOpen(true);
        }
    }

    function onSelectClick(value) {
        if(isShowAddDomain.isEdit) {
            setDomainValue(value.subdomain);
            setDomainSelected(value.subdomain);
            const updatedAuthTypes = authTypes.map((el) => (
                {...el, active: !!value.authTypes.find(item => item === el.nameType) }
            ))
            setAuthTypes(updatedAuthTypes);
        } else if(isShowAddDomain.isDelete) {
            setDomainValue(value.subdomain);
            setDomainSelected(value.subdomain);
            setActiveSaveButton(true);
        }
    }

    function onAddDomain() {
        const filterActiveTypes = authTypes.filter(el => el.active === true).map(item => item.nameType)
        const defaultBody = ["authorization-token","refresh-token"];
        const body = [...defaultBody, ...filterActiveTypes];

        if(newDomain.value === '') {
            setErrorMessage('Необходимо ввести домен!')
        } else {
            addAuthSettingsDomainMutation.mutate({domain: newDomain.value, body: body})
        }
    }

    function onEditDomain() {
        const filterActiveTypes = authTypes.filter(el => el.active === true).map(item => item.nameType)
        const defaultBody = ["authorization-token", "refresh-token"];
        const body = [...defaultBody, ...filterActiveTypes]
        addAuthSettingsDomainMutation.mutate({domain: domainValue, body: body})
    }

    function onDeleteDomain() {
        deleteAuthSettingsDomainMutation.mutate(domainValue)
    }

    function saveChanges(isShowAddDomain) {
        if (isShowAddDomain.isShow) {
            onAddDomain();
        }

        if (isShowAddDomain.isEdit) {
            onEditDomain();
        }

        if (isShowAddDomain.isDelete) {
            onDeleteDomain();
        }
    }

    return (
        <div className="subscription-settings__permissions-main-container auth-settings">
            <div className="subscription-settings-header">
                <h2 className="subscription-settings__heading">
                    {constants.ORG_SETTINGS.AUTH_SETTINGS_TITLE}
                </h2>
            </div>
            <div className="auth-settings">
                <div className="auth-settings-switch">
                    <Switch
                        checked={isShowAddDomain.isShow}
                        onChange={() => setShowAddDomain({...isShowAddDomain, isShow: true, isEdit: false, isDelete: false})}
                        inputProps={{'aria-label': 'controlled'}}
                    />
                    <span onClick={() => setShowAddDomain({...isShowAddDomain, isShow: true, isEdit: false, isDelete: false})} className="auth-settings-label">{constants.ORG_SETTINGS.AUTH_SETTINGS_ADD_TYPES}</span>
                </div>
                <div className="auth-settings-switch">
                    <Switch
                        checked={isShowAddDomain.isEdit}
                        onChange={() => setShowAddDomain({...isShowAddDomain, isShow: false, isEdit: true, isDelete: false})}
                        inputProps={{'aria-label': 'controlled'}}
                    />
                    <span onClick={() => setShowAddDomain({...isShowAddDomain, isShow: false, isEdit: true, isDelete: false})} className="auth-settings-label">{constants.ORG_SETTINGS.AUTH_SETTINGS_EDIT_TYPES}</span>
                </div>
                <div className="auth-settings-switch">
                    <Switch
                        checked={isShowAddDomain.isDelete}
                        onChange={() => setShowAddDomain({...isShowAddDomain, isShow: false, isEdit: false, isDelete: true})}
                        inputProps={{'aria-label': 'controlled'}}
                    />
                    <span onClick={() => setShowAddDomain({...isShowAddDomain, isShow: false, isEdit: false, isDelete: true})} className="auth-settings-label">{constants.ORG_SETTINGS.AUTH_SETTINGS_DELETE_TYPES}</span>
                </div>
            </div>
            {isShowAddDomain.isShow && (
                <>
                    <div className="subscription-settings__permissions-main-container auth-settings-input-block">
                        <div className="subscription-settings__permission-container">
                            <p className="subscription-settings__permission-heading">
                                {constants.ORG_SETTINGS.ADD_DOMAIN}
                            </p>
                            <input
                                type="text"
                                className="subscription-settings__permission-input"
                                name="domain-name"
                                value={newDomain.value}
                                onChange={newDomain.onChange}
                            />
                        </div>
                        {isSuccessChangeSettings && <img alt="иконка" src={successIcon}  className="auth-settings-success-icon"/>}
                    </div>
                    <div>
                        {authTypes.map((el, i) => {
                            return (
                                <div
                                    onClick={() => handleCheckType(el)}
                                    key={i}
                                    className="subscription-settings__message-checkbox-container"
                                    style={{marginTop: 15}}
                                >
                                    <div
                                        className={`subscription-settings__message-checkbox ${
                                            el.active &&
                                            "subscription-settings__message-checkbox_active"
                                        }`}
                                    />
                                    <p className="subscription-settings__message-checkbox-text">
                                        {el.nameType}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
            {isShowAddDomain.isEdit && (
                <>
                    <div className="subscription-settings__permissions-main-container auth-settings-input-block">
                        <div className="edit-main-data-user__time-zone-main-container auth-settings-select-block">
                            <p className="subscription-settings__permission-heading">
                                {constants.ORG_SETTINGS.SELECTED_DOMAIN}
                                {/*<span className="reg-main-block__red-star-heading_span">*</span>*/}
                            </p>
                            <div
                                className="edit-main-data-user__time-zone-select-container auth-settings-select-input"
                                onClick={handleActiveOptionsOpen}
                            >
                                <p className="edit-main-data-user__time-zone-select-value auth-settings-option">
                                    {domainSelected}
                                </p>
                                <img
                                    className="edit-main-data-user__time-zone-select-arrow auth-settings-icon-row"
                                    src={optionRow}
                                    alt="Стрелочка открытия меню"
                                />
                                {isSelectOptionsOpen && (
                                    <div className="edit-main-data-user__time-zone-options-container">
                                        {getDomains?.data?.map((item, index) => (
                                            <p
                                                className="edit-main-data-user__time-zone-option auth-settings-option"
                                                key={index}
                                                onClick={() => onSelectClick(item)}
                                            >
                                                {item.subdomain}
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <span className="edit-main-data-user__time-zone-main-container__error-message"></span>
                        </div>
                        {isSuccessChangeSettings && <img alt="иконка" src={successIcon}  className="auth-settings-success-icon"/>}
                    </div>
                    <div>
                        {domainSelected &&
                            <>
                                {authTypes.map((el, i) => {
                                    return (
                                        <div
                                            onClick={() => handleCheckType(el)}
                                            key={i}
                                            className="subscription-settings__message-checkbox-container"
                                            style={{marginTop: 15}}
                                        >
                                            <div
                                                className={`subscription-settings__message-checkbox ${
                                                    el.active &&
                                                    "subscription-settings__message-checkbox_active"
                                                }`}
                                            />
                                            <p className="subscription-settings__message-checkbox-text">
                                                {el.nameType}
                                            </p>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                </>
            )}
            {isShowAddDomain.isDelete &&
                <div className="subscription-settings__permissions-main-container auth-settings-input-block">
                    <div className="edit-main-data-user__time-zone-main-container auth-settings-select-block">
                        <p className="subscription-settings__permission-heading">
                            {constants.ORG_SETTINGS.SELECTED_DOMAIN}
                            {/*<span className="reg-main-block__red-star-heading_span">*</span>*/}
                        </p>
                        <div
                            className="edit-main-data-user__time-zone-select-container auth-settings-select-input"
                            onClick={handleActiveOptionsOpen}
                        >
                            <p className="edit-main-data-user__time-zone-select-value auth-settings-option">
                                {domainSelected}
                            </p>
                            <img
                                className="edit-main-data-user__time-zone-select-arrow auth-settings-icon-row"
                                src={optionRow}
                                alt="Стрелочка открытия меню"
                            />
                            {isSelectOptionsOpen && (
                                <div className="edit-main-data-user__time-zone-options-container">
                                    {getDomains?.data?.map((item, index) => (
                                        <p
                                            className="edit-main-data-user__time-zone-option auth-settings-option"
                                            key={index}
                                            onClick={() => onSelectClick(item)}
                                        >
                                            {item.subdomain}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                        <span className="edit-main-data-user__time-zone-main-container__error-message"></span>
                    </div>
                    {isSuccessChangeSettings && <img alt="иконка" src={successIcon}  className="auth-settings-success-icon"/>}
                </div>
            }
            {isActiveSaveButton && (
                <div className="subscription-settings-save-changes-button-errpr-message">
                    <button
                        className="subscription-settings__save-changes-button"
                        onClick={() => saveChanges(isShowAddDomain)}
                    >
                        {constants.ORG_SETTINGS.BUTTON_SAVE_ADMINS_CHANGE}
                    </button>
                    {isErrorMessage && <p className="subscription-settings__error-message">
                        {isErrorMessage}
                    </p>}
                </div>
            )}
        </div>
    )
}

export default AuthenticationSettings;