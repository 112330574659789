export const constants = {
  AUTH_OPTIONS: [
    {
      name: "Секретарь",
      value: "ROLE_ADMIN",
    },
    {
      name: "Наблюдатель",
      value: "ROLE_OBSERVER",
    },
    {
      name: "Член счетной комиссии",
      value: "ROLE_COUNTER",
    },
    {
      name: "Супер секретарь",
      value: "ROLE_SUPER_ADMIN",
    },
  ],

  AUTH: {
    AUTH_TITLE: "Авторизация",
    AUTH_LABEL_SELECT_ROLE: "Войти как",
    AUTH_LOGIN: "Имя пользователя",
    AUTH_PASS: "Пароль",
    AUTH_PASS_PLACEHOLDER: "Введите пароль",
    AUTH_FORGET_PASS: "Забыли пароль?",
    AUTH_REMEMBER_ME: "Запомнить меня",
    AUTH_ENTER_BTN: "Войти",
    AUTH_ENTER_BTN_LOADING: "Загрузка...",
    AUTH_ENTER_LINK_GOSUSLUGI: "Войти через ЕСИА",
    AUTH_TITLE_LOGO_BLOCK: "Панель управления системой электронных голосований",
    AUTH_QUESTION_ACCOUNT: "Еще нет аккаунта?",
    AUTH_REG_BTN: "Зарегистрироваться",
    AUTH_ERROR_MESSAGE: "Неверно введено имя пользователя или пароль",
    AUTH_ERROR_MESSAGE_REQUIRED: "Заполните все обязательные поля",
    AUTH_ROLE_ADMIN: "Секретарь",
    AUTH_ROLE_OBSERVER: "Наблюдатель",
    AUTH_ROLE_COUNTER: "Член счетной комиссии",
    AUTH_ROLE_SUPER_ADMIN: "Супер секретарь",
  },
  MAIN_PAGE: {
    MAIN_PAGE_COUNT_VOTES_TITLE: "Количество голосующих",
    MAIN_PAGE_ALL_COUNT: "Всего проголосовало",
    MAIN_PAGE_LINK_VOTES_PAGE_TEXT:
        "Чтобы посмотреть подробнее, перейдите в список голосований!",
    MAIN_PAGE_LINK_BTN: "Список голосований",
  },
  HEADER: {
    HEADER_ORG: "Организации",
    HEADER_MAIN: "Главная",
    HEADER_USERS: "Пользователи",
    HEADER_VOTE: "Голосования",
    HEADER_GROUP_USERS: "Группы пользователей",
    HEADER_USERS_LIST: "Список пользователей",
    HEADER_MODERATORS_LIST: "Список модераторов",
    HEADER_LIST_VOTES: "Список голосований",
    HEADER_TEMPLATE_VOTES: "Шаблоны голосований",
    HEADER_ADD_BTN: "Добавить",
    HEADER_ADD_ORG: "Организацию",
    HEADER_ADD_VOTE: "Голосование",
    HEADER_ADD_TEMPLATE_VOTE: "Шаблон голосования",
    HEADER_ADD_USER: "Пользователя",
    HEADER_ADD_GROUP_USERS: "Группу пользователей",
    HEADER_MYPROFILE_MODAL: "Мой профиль",
    HEADER_MYPROFILE_MODAI_EXIT: "Выйти",
    HEADER_BURGER_ADD_BTN: "Добавить",
    HEADER_BURGER_ORG: "Организации",
    HEADER_BURGER_ADD_ORG: "Организацию",
    HEADER_BURGER_POLITIC: "Политика конфидициальности",
    HEADER_BURGER_EXIT: "Выйти",
  },
  FOOTER: {
    FOOTER_COPI_INFO_PART1: "Система",
    FOOTER_COPI_INFO_PART2: "разработана Центром",
    FOOTER_COPI_INFO_PART3: "технологий распределенных",
    FOOTER_COPI_INFO_PART4: "реестров СПбГУ.",
    FOOTER_COPI_INFO_PART5: "Все права защищены.",
    FOOTER_MAP_SITE: "Карта сайта",
    FOOTER_MAIN_PAGE: "Главная",
    FOOTER_VOTES: "Голосования",
    FOOTER_LIST_VOTES: "Список голосований",
    FOOTER_TEMPLATE_VOTES: "Шаблоны голосований",
    FOOTER_USERS: "Пользователи",
    FOOTER_GROUP_USERS: "Группы пользователей",
    FOOTER_LIST_USERS: "Список пользователей",
    FOOTER_ORG: "Организации",
    FOOTER_MYPROFILE: "Мой профиль",
    FOOTER_SETTINGS: "Настройки",
    FOOTER_LANG: "Язык",
    FOOTER_SELECT_LANG_RU: "Русский",
    FOOTER_SELECT_LANG_EN: "Английский",
    FOOTER_SIZE_FONT: "Размер шрифта",
    FOOTER_SELECT_SIZE: "Стандартный",
    FOOTER_TIME_ZONE: "Часовой пояс",
    FOOTER_SELECT_TIME_ZONE: "GMT+03:00",
    FOOTER_SUPPORT: "Поддержка",
    FOOTER_POLITIC: "Политика конфиденциальности",
    FOOTER_MAIL_SUPPORT: "support@dltc.spbu.ru",
  },
  GENERAL_TITLE: {
    GENERAL_TITLE_TITLENAME_ORG: "Список организаций",
    GENERAL_TITLE_TITLENAME_VOTES: "Голосования",
    GENERAL_TITLE_TITLENAME_DETAILS_VOTE: "Детали голосования",
    GENERAL_TITLE_TITLENAME_GROUP_USER: "Группы пользователей",
    GENERAL_TITLE_TITLENAME_TEMPLATES_VOTE: "Шаблоны голосований",
    GENERAL_TITLE_ADD_ORG: "Добавить организацию",
    GENERAL_TITLE_TITLENAME_ADD_VOTE: "Добавление нового голосования",
    GENERAL_TITLE_TITLENAME_ADD_ORG: "Добавление новой организации",
    GENERAL_TITLE_TITLENAME_ADD_GROUP_USERS: "Добавление группы пользователей",
    GENERAL_TITLE_TITLENAME_ADD_TEMPLATES_VOTE: "Добавление нового шаблона",
    GENERAL_TITLE_TITLENAME_LIST_USERS: "Список пользователей",
    GENERAL_TITLE_TITLENAME_PROFILE: "Профиль пользователя",
    GENERAL_TITLE_FIRTSLETTER: "АдминистраторКриптовече",
    GENERAL_TITLE_SECONDLETTER_ORG: "Организации",
    GENERAL_TITLE_SECONDLETTER_ORG_MANY: "Организация",
    GENERAL_TITLE_SECONDLETTER_GROUP_USERS: "Группы пользователей",
    GENERAL_TITLE_SECONDLETTER_LIST_USERS: "Список пользователей",
    GENERAL_TITLE_SECONDLETTER_LIST_MODERATORS: "Список модераторов",
    GENERAL_TITLE_SECONDLETTER_VOTES: "Голосования",
    GENERAL_TITLE_SECONDLETTER_DETAILS_VOTE: "Детали голосования",
    GENERAL_TITLE_SECONDLETTER_ADD_VOTE: "Добавить голосование",
    GENERAL_TITLE_SECONDLETTER_TEMPLATES_VOTE: "Шаблоны голосований",
    GENERAL_TITLE_SECONDLETTER_ADD_TEMPLATES_VOTE: "Добавить шаблон",
  },
  PAGINATION: {
    PAGINATION_SHOW_LABEL: "Показать",
    PAGINATION_PAGE_FROM_PAGE: "из",
    PAGINATION_SEARCH: "Поиск",
  },
  ORGANIZATIONS_LIST: {
    ORGANIZATIONS_LIST_NAME_ORG: "Название организации",
    ORGANIZATIONS_LIST_SECRETARY: "Секретари",
    ORGANIZATIONS_LIST_GROUP_USERS: "Группы пользователей",
    ORGANIZATIONS_LIST_VOTES: "Голосования",
    ORGANIZATIONS_LIST_ACTIVE_VOTES: "Активные голосования",
    ORGANIZATIONS_LIST_TEMPLATES: "Шаблоны",
    ORGANIZATIONS_LIST_ACTIONS: "Действия",
    ORGANIZATIONS_LIST_EDIT_BTN: "РЕДАКТИРОВАТЬ",
    ORGANIZATIONS_LIST_EDIT_BTN_MOBILE: "Редактировать",
    ORGANIZATIONS_LIST_EMPTY_LIST: "Список организаций пуст"
  },
  ADD_NEW_ORG: {
    ADD_NEW_ORG_NAME_ORG: "Название организации",
    ADD_NEW_ORG_PLACEHOLDER_NAME: "Введите название организации",
    ADD_NEW_ORG_IMPORT_EXCEL_BTN: "Импорт пользователей из Excel",
    ADD_NEW_ORG_ADD_USERS_MAIL_BTN: "Добавить пользователей по E-mail",
    ADD_NEW_ORG_ADD_USERS_MAIL_BTN_MOBILE: "Добавить пользователей",
    ADD_NEW_ORG_MAIL_ADDRESS_USERS: "Почтовые адреса пользователей",
    ADD_NEW_ORG_DOWNLOAD_FILE_LABEL:
        "Загрузить xlsx документ со списком пользователей",
    ADD_NEW_ORG_LOAD_BTN: "Загрузить",
    ADD_NEW_ORG_SUCCESS_INFO: "Файл успешно загружен",
    ADD_NEW_ORG_ERROR_FILE: "Ошибка загрузки файла",
    ADD_NEW_ORG_MAIL_ADDRESS_USERS_RULE:
        "(по одному на каждую строчку для каждого пользователя)",
    ADD_NEW_ORG_SEARCH_USERS_BASE_BTN: "Найти в базе пользователей",
    ADD_NEW_ORG_E_MAIL_SUPPORT: "E-mail поддержки",
    ADD_NEW_ORG_E_MAIL_SUPPORT_PLACEHOLDER: "Введите E-mail поддержки",
    ADD_NEW_ORG_NOTE: "Примечание",
    ADD_NEW_ORG_INPUT_NOTE: "Введите примечание",
    ADD_NEW_ORG_SAVE_BTN: "Сохранить",
    ADD_NEW_ORG_SUPERUSER: "Суперпользователь",
    ADD_NEW_ORG_DELETE_BTN: "УДАЛИТЬ",
    ADD_NEW_ORG_DELETE_BTN_PROCESS: "УДАЛЕНИЕ...",
    ADD_NEW_ORG_DELETE_BTN_MOBILE: "Удалить",
    ADD_NEW_ORG_DELETE_BTN_MOBILE_PROCESS: "Удаление...",
    ADD_NEW_ORG_BLOCK_BTN: "Заблокировать",
    ADD_NEW_ORG_UNBLOCK_BTN: "Разблокировать",
    ADD_NEW_ORG_SHOW_MORE_BTN: "ПОКАЗАТЬ ЕЩЁ",
    ADD_NEW_ORG_SELECT_FILE: "Выберите файл",
    ADD_NEW_ORG_NO_USERS: "Список пользователей пуст",
    ADD_NEW_ORG_DEFAULT_FIRST_NAME: "Пользователь",
    ADD_NEW_ORG_DEFAULT_LAST_NAME: "",
    ADD_NEW_ORG_DEFAULT_SECOND_NAME: "",
    ADD_NEW_ORG_DEFAULT_MAIL: "E-mail не используется",
    ADD_NEW_ORG_EMAIL_ERROR: "Введён некорректный формат почты",
    ADD_NEW_ORG_SUPPORT_EMAIL_ERROR: "Некорректный E-mail поддержки",
    ADD_NEW_ORG_SAVE_ERROR: "Название организации не может быть пустым",
    ADD_NEW_ORG_FAILURE_ERROR: "Что-то пошло не так...",
  },
  ADD_NEW_GROUP_USERS: {
    ADD_NEW_GROUP_USERS_SELECT_ORG: "Выбор организации",
    ADD_NEW_GROUP_USERS_ORG: "Организация",
    ADD_NEW_GROUP_NEXT_BTN: "Далее",
    ADD_NEW_GROUP_USERS_NAME_GROUP_USERS: "Название группы пользователей",
    ADD_NEW_GROUP_USERS_PLACEHOLDER_NAME: "Введите название группы",
    ADD_NEW_GROUP_USERS_IMPORT_EXCEL_BTN: "Импорт пользователей из Excel",
    ADD_NEW_GROUP_USERS_ADD_GROUP_BTN: "Добавить в группу пользователей",
    ADD_NEW_GROUP_MAIL_ADDRESS_USERS: "Почтовые адреса пользователей",
    ADD_NEW_GROUP_MAIL_ADDRESS_USERS_RULE:
        "(по одному на каждую строчку для каждого пользователя)",
    ADD_NEW_GROUP_SEARCH_USERS_BASE_BTN: "Найти в базе пользователей",
    ADD_NEW_GROUP_USERS_DELETE_BTN: "УДАЛИТЬ",
    ADD_NEW_GROUP_USERS_SAVE_BTN: "Сохранить",
    ADD_NEW_GROUP_USERS_SHOW_MORE_BTN: "ПОКАЗАТЬ ЕЩЁ",
    ADD_NEW_GROUP_USERS_LOAD_BTN: "Загрузить",
    ADD_NEW_GROUP_USERS_DOWNLOAD_FILE_LABEL:
        "Загрузить xls/xlsx документ со списком пользователей",
    ADD_NEW_GROUP_SUCCESS_INFO: "Файл успешно загружен",
    ADD_NEW_GROUP_SAVE_ERROR: "Название группы не может быть пустым",
  },
  PROFILE_USER: {
    PROFILE_USER_EDIT_BTN: "Редактировать профиль",
    PROFILE_USER_ORG_GROUP_USERS: "Организации и группы пользователя",
    PROFILE_USER_ORG_GROUP_USERS_MOBILE: "Орг-ции и группы",
    PROFILE_USER_ACTIVITY_IN_USER_GROUPS: "Членство в группах пользователей",
    PROFILE_USER_ACTIVITY_IN_ORGANIZATION:
        "Членство в организациях (в качестве секретаря)",
    PROFILE_USER_VOTES: "Голосования",
    PROFILE_USER_STATUS_VOTING: "Голосующий",
    PROFILE_USER_STATUS_OBSERVER: "Наблюдатель",
    PROFILE_USER_STATUS_SECRETARY: "Секретарь",
    PROFILE_USER_SHOW_ALL: "ПОКАЗАТЬ ПОЛНОСТЬЮ",
    PROFILE_USER_SAVE_BTN: "Сохранить изменения",
    PROFILE_USER_NOT_UTC_TIMEZONE: "Часовой пояс не установлен",
    PROFILE_USER_CANCEL_BTN: "Отмена",
    PROFILE_USER_CHANGE_PERSONAL_DATA: "Изменить личные данные",
    PROFILE_USER_SURNAME: "Фамилия",
    PROFILE_USER_NAME: "Имя",
    PROFILE_USER_MIDDLE_NAME: "Отчество",
    PROFILE_USER_CHANGE_TIMEZONE: "Изменить часовой пояс",
    PROFILE_USER_CHANGE_PASS: "Изменить пароль",
    PROFILE_USER_PASS: "Пароль",
    PROFILE_USER_NEW_PASS: "Новый пароль",
    PROFILE_USER_REPEAT_PASS: "Повторите новый пароль",
    PROFILE_USER_EMPTY_MESSAGE_VOTE:
        "Голосования в которых участвовал пользователь не найдены!",
    PROFILE_USER_EMPTY_MESSAGE_ORG_GROUPS:
        "Организации и группы в которых состоит пользователь не найдены!",
    PROFILE_USER_VOTE_STATUS_VOTER: "Голосующий",
    PROFILE_USER_VOTE_STATUS_OBSERVER: "Наблюдатель",
    PROFILE_USER_VOTE_STATUS_COUNTER: "Счетная комиссия",
    PROFILE_USER_CHANGE_DATA_ERROR_WRONG_FORMAT: "Неверный формат",
    PROFILE_USER_CHANGE_DATA_ERROR_PASSWORD_MISMATCH: "Пароли не совпадают",
    PROFILE_USER_CHANGE_DATA_ERROR_RULE_PASS:
        "Пароль должен содержать от 8 до 64 символов, состоять из латинских букв верхнего, нижнего регистра и цифр",
  },
  GROUP_USERS: {
    GROUP_USERS_NAME_GROUP_USERS: "Название группы пользователей",
    GROUP_USERS_MEMBERS: "Участники",
    GROUP_USERS_ACTIONS: "Действия",
    GROUP_USERS_SHOW_GROUP: "ПОСМОТРЕТЬ ГРУППУ",
    GROUP_USERS_EDIT_BTN: "РЕДАКТИРОВАТЬ",
    GROUP_USERS_BLOCK_BTN: "ЗАБЛОКИРОВАТЬ",
    GROUP_USERS_UNBLOCK_BTN: "РАЗБЛОКИРОВАТЬ",
    GROUP_USERS_DELETE_BTN: "УДАЛИТЬ",
    GROUP_USERS_DELETE_BTN_SMALL: "Удалить",
    GROUP_USERS_ADD_USER_BTN_MOBILE: "Добавить пользователей",
    GROUP_USERS_FIND_USERS_IN_BASE: "Найти в базе пользователей",
    GROUP_USERS_SAVE_BUTTON: "Сохранить",
    GROUP_USERS_SELECT_BTN_SELECT: "Выбрать",
    GROUP_USERS_MODAL_SELECTED: "Выбрано",
    GROUP_USERS_MODAL_NUMBER_GROUP: "группа",
    GROUP_USERS_MODAL_NUMBER_GROUPS: "группы",
    GROUP_USERS_MODAL_NUMBER_SEVERAL_GROUP: "групп",
    GROUP_USERS_MODAL_CANCEL: "Отмена",
    GROUP_USERS_MODAL_MARKED_FOR_DELETION: "Помечено на удаление",
    GROUP_USERS_MODAL_DELETE_SELECTED: "Удалить выбранное",
    GROUP_USERS_MODAL_SELECT_TYPE_DELETE: "Выберите тип удаления",
    GROUP_USERS_MODAL_DELETE_SOME: "Удалить несколько",
    GROUP_USERS_EMPTY_TEXT_NO_GROUPS: "Список групп пуст",
  },
  LIST_USERS: {
    LIST_USERS_TABLE_EMAIL: "E-mail",
    LIST_USERS_USERNAME: "ФИО",
    LIST_USERS_ACTIONS: "Действия",
    LIST_USERS_WATCH_PROFILE: "ПОСМОТРЕТЬ ПРОФИЛЬ",
    LIST_USERS_SHOW_MORE: "ПОКАЗАТЬ ЕЩЁ",
  },
  LIST_MODERATORS: {
    LIST_MODERATORS_TITLE: "Список модераторов",
    LIST_MODERATORS_ADD_MODERATOR: "Добавить модератора",
  },
  FILTER_SORT_BLOCK: {
    FILTER_SORT_BLOCK_FILTERS: "Фильтры",
    FILTER_SORT_BLOCK_SORTING: "Сортировка",
    FILTER_SORT_BLOCK_BY_VOTING_STATUS: "По статусу голосования",
    FILTER_SORT_BLOCK_STATUS_VOTING: "Статус голосования",
    FILTER_SORT_BLOCK_BY_VOTING_TYPE: "По типу голосования",
    FILTER_SORT_BLOCK_TYPE_VOTING: "Тип голосования",
    FILTER_SORT_BLOCK_BY_REG_START_DATE: "По дате начала регистрации",
    FILTER_SORT_BLOCK_REG_START_DATE: "Дата начала регистрации",
    FILTER_SORT_BLOCK_BY_VOTE_START_DATE: "По дате начала голосования",
    FILTER_SORT_BLOCK_VOTE_START_DATE: "Дата начала голосования",
    FILTER_SORT_BLOCK_FROM: "c",
    FILTER_SORT_BLOCK_TO: "по",
    FILTER_SORT_BLOCK_VOTING_IN_PROGRESS: "Идет голосование",
    FILTER_SORT_BLOCK_REG_IN_PROGRESS: "Идет регистрация",
    FILTER_SORT_BLOCK_VOTING_AND_REG: "Регистрация и голосование",
    FILTER_SORT_BLOCK_WAITING_VOTE: "Ожидание голосования",
    FILTER_SORT_BLOCK_WAITING_REG: "Ожидание регистрации",
    FILTER_SORT_BLOCK_VOTE_END: "Голосование завершено",
    FILTER_SORT_BLOCK_QUORUM_NOT_REACHED: "Кворум не достигнут",
    FILTER_SORT_BLOCK_OPEN: "Открытое",
    FILTER_SORT_BLOCK_CLOSE: "Тайное",
    FILTER_SORT_BLOCK_APPLY_FILTER: "Применить фильтр",
    FILTER_SORT_BLOCK_RESET_FILTERS: "Сбросить все фильтры",
    FILTER_SORT_BLOCK_EVENT_NAME: "Названию события",
    FILTER_SORT_BLOCK_ORG_NAME: "Названию организации",
    FILTER_SORT_BLOCK_TIME_START_REG: "Времени начала регистрации",
    FILTER_SORT_BLOCK_TIME_START_VOTE: "Времени начала голосования",
    FILTER_SORT_BLOCK_TIME_END_REG: "Времени окончания регистрации",
    FILTER_SORT_BLOCK_TIME_END_VOTE: "Времени окончания голосования",
    FILTER_SORT_BLOCK_ALT_BUTTON_CLOSE_WINDOW: "кнопка-крестик-закрыть окно",
    FILTER_SORT_BLOCK_ALT_BUTTON_SORT_LOW: "иконка сортировки на убывание",
    FILTER_SORT_BLOCK_ALT_BUTTON_ALT_SORT_UP: "иконка сортировки на увеличение",
    FILTER_SORT_BLOCK_ALT_RED_CIRCLE: "красная точка",
    FILTER_SORT_BLOCK_ALT_ICON_FILTER: "иконка фильтр",
    FILTER_SORT_BLOCK_ALT_ICON_SORT: "иконка сортировка",
  },
  VOTES_PAGE: {
    VOTES_PAGE_SWITCH_ACTIVE_BTN: "Активные",
    VOTES_PAGE_SWITCH_ACTIVE_BTN_MOBILE: "голосования",
    VOTES_PAGE_SWITCH_ARCHIVE_BTN: "Архивные",
    VOTES_PAGE_SWITCH_ARCHIVE_BTN_MOBILE: "голосования",
    VOTES_PAGE_SHOW_MORE_BTN: "ПОКАЗАТЬ ЕЩЁ",
    VOTES_PAGE_SHOW_INFO_DO_NOT_VOTE: "У вас пока нет доступных голосований!",
    VOTES_PAGE_SHOW_VOTE_BTN: "ПОСМОТРЕТЬ",
    VOTES_PAGE_EDIT_VOTE_BTN: "РЕДАКТИРОВАТЬ",
    VOTES_PAGE_DELETE_VOTE_BNT: "УДАЛИТЬ",
    VOTES_PAGE_STATUS_VOTE_WAIT_REG: "Ожидание регистрации",
    VOTES_PAGE_STATUS_VOTE_PROGRESS_REG: "Идет регистрация",
    VOTES_PAGE_STATUS_VOTE_WAIT_VOTE: "Ожидание голосования",
    VOTES_PAGE_STATUS_VOTE_PROGRESS_VOTE: "Идет голосование",
    VOTES_PAGE_STATUS_VOTE_REG_VOTE: "Регистрация и голосование",
    VOTES_PAGE_STATUS_VOTE_VOTE_END: "Голосование завершено",
    VOTES_PAGE_STATUS_VOTE_QUORUM_FAILURE: "Кворум не достигнут",
    VOTES_PAGE_ALT_ICON_CALENDAR: "иконка календарь",
    VOTES_PAGE_ALT_ICON_TIME: "иконка часы",
    VOTES_PAGE_ALT_ROW_OPEN: "Стрелочка открытия меню",
    VOTES_PAGE_MATERIALS_VOTE: "Материалы голосования",
    VOTES_PAGE_EMPTY_MESSAGE_NOT_FOUND_VOTES: "Голосования не найдены!",
  },
  ADD_NEW_VOTE: {
    ADD_NEW_VOTE_GENERAL_SETTINGS_TITLE: "Общие настройки",
    ADD_NEW_VOTE_SELECT_ORG_STEP_MOBILE: "Шаг 1: Выбор организации",
    ADD_NEW_VOTE_GENERAL_SETTINGS_TITLE_MOBILE: "Шаг 2: Настройки",
    ADD_NEW_VOTE_QUESTION_TITLE_MOBILE: "Шаг 3: Вопросы",
    ADD_NEW_VOTE_QUESTION_TITLE: "Вопросы",
    ADD_NEW_VOTE_NAME_VOTING: "Название голосования",
    ADD_NEW_VOTE_NAME_VOTING_PLACEHOLDER: "Введите название голосования",
    ADD_NEW_VOTE_CLOSE_VOTING_BTN: "Тайное голосование",
    ADD_NEW_VOTE_OPEN_VOTING_BTN: "Открытое голосование",
    ADD_NEW_VOTE_QUORUM_CONDITION: "Условие кворума",
    ADD_NEW_VOTE_QUORUM_ANY_NUMBERS: "Любое количество",
    ADD_NEW_VOTE_START_REG: "Начало регистрации",
    ADD_NEW_VOTE_END_REG: "Окончание регистрации",
    ADD_NEW_VOTE_START_VOTE: "Начало голосования",
    ADD_NEW_VOTE_END_VOTE: "Окончание голосования",
    START_REG_AND_VOTE: "Начало регистрации и голосования",
    END_REG_AND_VOTE: "Окончание регистрации и голосования",
    ADD_NEW_VOTE_POSSIBLE_CANCEL_ONLINE_REG:
        "Возможность отмены электронной регистрации",
    ADD_NEW_VOTE_POSSIBLE_CHANGE_VOTE: "Возможность изменения голоса",
    ADD_NEW_VOTE_SETTINGS_USERS: "Настройки пользователей",
    ADD_NEW_VOTE_CLOSE_LISTS: "Закрытые списки",
    ADD_NEW_VOTE_OPEN_LISTS: "Открытые списки",
    ADD_NEW_VOTE_ADD_GROUP_BTN: "Добавить из группы",
    ADD_NEW_VOTE_ADD_USERS_BTN: "Добавить из списка",
    ADD_NEW_VOTE_GROUP_USERS: "Список групп",
    ADD_NEW_VOTE_LIST_USERS: "Список пользователей",
    SELECT_LIST_USERS: "Выберите пользователя из списка",
    SELECT_LIST_GROUP: "Выберите группу из списка",
    ADD_NEW_VOTE_ALLOW_JOIN_LINK_VOTE:
        "Разрешить другим пользователям присоединяться к голосованию по ссылке",
    ADD_NEW_VOTE_WEIGHT_VOTE: "Весовое голосование",
    ADD_NEW_VOTE_USERNAME_TABLE: "ФИО",
    ADD_NEW_VOTE_WEIGHT_VOTE_TABLE: "Вес голоса",
    ADD_NEW_VOTE_ACTION_TABLE: "Действие",
    ADD_NEW_VOTE_DELETE_BTN_TABLE: "УДАЛИТЬ",
    ADD_NEW_VOTE_MAX_NUMBERS_MEMBERS: "Максимальное количество участников",
    ADD_NEW_VOTE_AFTER_CREATE_VOTE_AVAILABLE_LINK:
        "После создания голосования вам будет доступна пригласительная ссылка",
    ADD_NEW_VOTE_ADD_QUESTION_BTN: "Добавить вопрос",
    SAVE_CHANGES: "Сохранить изменения",
    ADD_NEW_VOTE_ADD_ANOTHER_QUESTION_BTN: "ДОБАВИТЬ ЕЩЁ ВОПРОС",
    ADD_NEW_VOTE_SAVE_AS_TEMPLATE_BTN: "Сохранить как шаблон",
    ADD_NEW_VOTE_ADD_VOTE_BTN: "Добавить голосование",
    ADD_NEW_VOTE_NEXT_GO_TO_QUESTION_BLOCK: "Далее",
    ADD_NEW_VOTE_ERROR_MESSAGE:
        "Чтобы создать голосование, добавьте минимум 1 пользователя.",
    ADD_NEW_VOTE_QUESTION_YNQ: "Голосование по вопросу",
    ADD_NEW_VOTE_QUESTION_NONE: "Произвольный вопрос",
    ADD_NEW_VOTE_QUESTION_POSITION_SINGLE: "На позицию (Одна кандидатура)",
    ADD_NEW_VOTE_QUESTION_POSITION_MULTIPLE:
        "На позицию (Несколько кандидатур)",
    ADD_NEW_VOTE_QUESTION_SAME_POSITIONS:
        "На несколько позиций (Несколько кандидатур)",
    ADD_NEW_VOTE_QUESTION_GRID: "Сетка",
    ADD_NEW_VOTE_QUESTION_RADIO_GRID: "Радио-сетка",
    QUESTION_TYPE_NAME_QUESTION: "Заголовок вопроса",
    QUESTION_TYPE_VARIANTS_ANSWER: "Варианты ответа",
    QUESTION_TYPE_SELECT_TYPE_QUESTION: "Выберите тип вопроса",
    QUESTION_TYPE_VARIANTS_ANSWER_PLACEHOLDER_ENTER_VARIANT:
        "Введите вариант ответа",
    QUESTION_TYPE_VARIANTS_ANSWER_PLACEHOLDER_USERNAME: "ФИО претендента",
    QUESTION_TYPE_NAME_QUESTION_PLACEHOLDER_ENTER_YOUR_QUESTION:
        "Введите ваш вопрос",
    QUESTION_TYPE_NAME_QUESTION_PLACEHOLDER_SELECTION_POSITION:
        "Отбор на позицию профессора",
    QUESTION_TYPE_NAME_QUESTION_PLACEHOLDER_NUMBER_OF_POSITIONS:
        "Количество должностных позиций",
    QUESTION_TYPE_GRID_ROWS: "Строки",
    QUESTION_TYPE_GRID_COLUMNS: "Столбцы",
    QUESTION_TYPE_GRID_ENTER_VALUES: "Введите значение",
    QUESTION_TYPE_GRID_NOTE: "Все строки обязательны для заполнения",
    QUESTION_TYPE_RULES_TITLE: "ДОБАВИТЬ ПРАВИЛО",
    QUESTION_TYPE_RULES: "Правило",
    QUESTION_TYPE_RULES_SELECT_CHOOSE_EXACTLY: "Выбрать ровно",
    QUESTION_TYPE_RULES_SELECT_CHOOSE_LESS_THAN: "Выбрать меньше чем",
    QUESTION_TYPE_RULES_SELECT_CHOOSE_MORE_THAN: "Выбрать больше чем",
    QUESTION_TYPE_RULES_SELECT_CHOOSE_LESS_OR_EXACTLY:
        "Выбрать меньше или ровно",
    QUESTION_TYPE_RULES_SELECT_CHOOSE_MORE_OR_EXACTLY:
        "Выбрать больше или ровно",
    QUESTION_TYPE_RULES_SELECT_SELECT_INTERVAL_FROM: "Выбрать интервал от",
    QUESTION_TYPE_RULES_SELECT_SELECT_INTERVAL_BEFORE: "До",
    QUESTION_TYPE_RULES_SELECT_SELECT_INTERVAL_ANSWER_OPTIONS:
        "вариантов ответов",
    QUESTION_TYPE_ADD_QUESTION_BTN: "Добавить вопрос",
    ADD_MATERIALS_VOTE_TITLE: "Добавить материалы к голосованию",
    ADD_MATERIALS_VOTE_TITLE_QUESTION_TYPE: "Добавить материалы к вопросу",
    ADD_MATERIALS_VOTE_PLACEHOLDER: "Заголовок вспомогательного материала",
    ADD_MATERIALS_VOTE_DOCUMENT: "Документ",
    ADD_MATERIALS_VOTE_LINK: "Ссылка",
    ADD_MATERIALS_SUCCESS_INFO: "Готово!",
    EXPAND_LIST_TITLE: "РАСШИРИТЬ СПИСОК",
    EXPAND_LIST_MAIL_ADDRESSES_USERS: "Почтовые адреса пользователей",
    EXPAND_LIST_NOTE: "(по одному на каждую строчку для каждого пользователя)",
    EXPAND_LIST_SEARCH_USERS_BTN: "Найти в базе пользователей",
    EXPAND_LIST_IMPORT_EXCEL: "ИМПОРТ ДАННЫХ ИЗ EXCEL",
    CREATED_QUESTION_EDIT_BTN: "РЕДАКТИРОВАТЬ",
    CREATED_QUESTION_DELETE_BTN: "УДАЛИТЬ",
    ADD_OBSERVERS_TITLE_OBSERVER: "ДОБАВИТЬ НАБЛЮДАТЕЛЕЙ",
    ADD_OBSERVERS_TITLE_COUNTING_MEMBERS: "ДОБАВИТЬ ЧЛЕНОВ СЧЕТНОЙ КОМИССИИ",
    ADD_TEMPLATES_NAME_TEMPLATE: "Название шаблона",
    ADD_TEMPLATES_NAME_TEMPLATE_PLACEHOLDER: "Введите название шаблона",
    ADD_TEMPLATES_SAVE_TEMPLATE_BTN: "Сохранить шаблон",
    SELECT_ORG: "Выберите организацию",
    EVENT_NAME_ERR: "Отсутствует наименование голосования",
    COMPARE_RS_RE:
        "Окончание регистрации не может предшествовать началу регистрации",
    COMPARE_ES_EE:
        "Окончание голосования не может предшествовать началу голосования",
    COMPARE_RE_EE:
        "Окончание голосования не может предшествовать окончанию регистрации",
    COMPARE_EE_RS:
        "Окончание голосования не может предшествовать началу регистрации",
    COMPARE_ES_RS:
        "Начало голосования не может предшествовать началу регистрации",
    SKIP_REG: "Исключить этап регистрации",
    SHOW_STATISTICS_FOR_USERS: "Возможность вывода статистики для голосующих",
    EVENT_MATERIALS_ERR: "Некорректно заполнены материалы к голосованию",
    QUESTION_MATERIALS_ERR: "Некорректно заполнены материалы",
    VOTERS_ERR: "Список голосующих пуст",
    USERS_IN_GROUP: "Количество участников",
    NO_GROUPS: "Нет групп для выбора",
    BUTTON_ADD: "Добавить",
    VOTERS_EXPANDABLE_ERR:
        "Максимальное количество участников заполнено некорректно",
    FOR: "За",
    AGAINST: "Против",
    ABSTAIN: "Воздержаться",
    QUESTION_TITLE_ERROR: "Заполните заголовок вопроса",
    QUESTION: "Вопрос",
    QUESTIONS_ERR: "Нет добавленных вопросов",
    EVENT_LIMIT_ERR: "Превышен лимит голосований для организации",
    SOMETHING_WENT_WRONG_ERR: "Что-то пошло не так...",
    ROWS_ERR: "Заполните все варианты ответа",
    TEMPLATE_TITLE_PROTOCOL: "Шаблон протокола",
    TEMPLATE_SELECT_PROTOCOL: "Выбор шаблона протокола",
  },
  ORG_SETTINGS: {
    PLACEHOLDER: "Введите название компании",
    BUTTON_SAVE: "Сохранить",
    BUTTON_SAVE_NEW_ADMINS: "Добавить пользователей",
    BUTTON_CANCEL: "Отменить",
    ADMINS_LIST: "Список секретарей",
    USERS_LIST: "Список пользователей",
    STATISTICS_ORG: "Статистика голосований",
    USER_LIST_USERNAME_DATA: "ФИО",
    USER_LIST_USERNAME_EMAIL: "E-mail",
    USERS_LIST_USERNAME_ROLE_ADMIN: "Секретарь",
    USERS_LIST_USERNAME_ROLE_SUPERUSER: "Суперпользователь",
    GENERAL_SETTINGS: "Общие настройки",
    PROTOCOL_SETTINGS: "Настройки протокола подсчета голосов",
    VOTE_CONSTRUCOR: "Конструктор создания голосования",
    MAILING_SETTINGS: "Управление рассылкой",
    SUBSCRIPTION_SETTINGS: "Подписка",
    AUTH_SETTINGS_TITLE: "Настройка аутентификации",
    AUTH_SETTINGS_ADD_TYPES: "Добавить",
    AUTH_SETTINGS_EDIT_TYPES: "Редактировать",
    AUTH_SETTINGS_DELETE_TYPES: "Удалить",
    SUBSCRIPTION_SETTINGS_NOT_DATA_SUBSCRIPTION:
        "Данные по подписке отсутвуют!",
    SETTINGS_BACK: "К меню настроек",
    ADD_ADMIN: "добавить секретаря",
    BUTTON_SAVE_ADMINS_CHANGE: "Сохранить изменения",
    BUTTON_LOADING: "Загрузка...",
    ADD_OBSERVERS: "Добавить наблюдателей",
    ADD_COUNTERS: "Добавить счетную комиссию",
    ADD_TEMPLATES: "Добавить шаблоны голосований",
    INVALID_BALLOTS: "Разрешить отправку недействительных бюллетеней",
    PROLONG: "Разрешить продление времени голосования",
    STATISTIC: "Частота обновления статистики",
    MINUTES: "минут",
    TIME_ZONE: "Временная зона",
    SUPPORT_EMAIL: "E-mail службы поддержки",
    SUPPORT_EMAIL_PLACEGOLDER: "Введите E-mail службы поддержки",
    DESCRIPTION: "Примечание",
    DESCRIPTION_PLACEGOLDER: "Введите примечание",
    BLOCK_ORG: "Заблокировать организацию",
    UNLOCK_ORG: "Разблокировать организацию",
    DELETE_ORG: "Удалить организацию",
    FORM_PROTOCOL: "Добавить выбор шаблона протокола",
    CURRENT_PROTOCOL: "Используемые шаблоны протоколов",
    DOWNLOAD_NEW_TEMPLATE_PROTOCOL: "Загрузить новый шаблон протокола",
    LOAD_BUTTON: "Загрузить",
    LOAD_READY: "Готово",
    VOTE_TYPE: "Отображать элемент выбора типа голосования",
    QUORUM: "Отображать элемент выбора условия кворума",
    CANCEL_REG: "Отображать элемент возможности отмены регистрации",
    CHANGE_VOTE: "Отображать элемент изменения голоса",
    COMBINE_TIME:
        "Совместить временные интервалы фазы регистрации и голосования",
    DEFAULT_STATE: "Выберите значение по умолчанию",
    SECRET_VOTE: "Тайное голосование",
    OPEN_VOTE: "Открытое голосование",
    QUORUM_ANY_VALUE: "Любое количество",
    ALLOW: "Разрешить",
    PROHIBIT: "Запретить",
    MAILING_FOR_E_MAIL: "Рассылка по e-mail",
    MAILING_FOR_PHONES: "Рассылка по sms",
    STANDART_ALERTS: "Стандартные оповещения",
    CREATE_VOTE: "О создании голосования",
    START_VOTE: "О начале голосования",
    END_VOTE: "Об окончании голосования",
    START_REG: "О начале регистрации",
    END_REG: "Об окончании регистрации",
    INTERIM_ALERTS: "Промежуточные оповещения",
    AFTER_START: "После начала голосования",
    BEFORE_END: "Перед окончанием голосования",
    MINUTE: "минут",
    SAVE_NOTIFICATION_ERROR: "Необходимо заполнить все поля оповещений",
    ADD_NOTIFICATION: "добавить оповещение",
    DATE_START: "Начало подписки",
    DATE_END: "Окончание подписки",
    DATE_FROM: "с",
    DATE_TO: "по",
    VOTERS_LIMIT: "Количество пользователей(макс.)",
    EVENTS_LIMIT: "Количество голосований(макс.)",
    MESSAGE_CHECKBOX: "Добавить sms",
    MESSAGE_INPUT: "Доступное количество сообщений",
    SELECT_OPTIONS: "Выбрать опции",
    ADD_DOMAIN: "Введите имя домена и выберите тип аутентификации",
    SELECTED_DOMAIN: "Выберите домен",
    SUBSCRIBE_ERROR_START_DATE_MESSAGE:
        "Начало даты подписки не может быть позже окончания даты подписки",
    SUBSCRIBE_ERROR_EMPTY_FIELDS_MESSAGE: "Некорректный формат данных",
    DELETE_ORG_MODAL_MESSAGE:
        "Вы действительно хотите удалить эту организацию?",
    DELETE_ORG_MODAL_DELETE_BTN: "Удалить",
    DELETE_ORG_MODAL_CANCEL_BTN: "Отмена",
    STATISTIC_ORG_COUNT_EVENT: "Количество голосований",
    STATISTIC_ORG_TYPE_VOTE: "Тип голосования",
    STATISTIC_ORG_TYPE_VOTE_OPEN: "Открытое",
    STATISTIC_ORG_TYPE_VOTE_CLOSE: "Тайное",
    STATISTIC_ORG_QUORUM_CONDITION: "Условие кворума",
    STATISTIC_ORG_QUORUM_CONDITION_NONE: "Отсутсвует",
    STATISTIC_ORG_QUORUM_CONDITION_50: "50%",
    STATISTIC_ORG_QUORUM_CONDITION_50_1: "50% + 1",
    STATISTIC_ORG_QUORUM_CONDITION_2_3: "2/3",
    STATISTIC_ORG_POSSIBLE_CANCEL_REG: "Возможность отмены регистрации",
    STATISTIC_ORG_POSSIBLE_CANCEL_REG_ALLOWED: "Разрешено",
    STATISTIC_ORG_POSSIBLE_CANCEL_REG_FORBIDDEN: "Запрещено",
    STATISTIC_ORG_POSSIBLE_CHANGE_VOICE: "Возможность изменения голоса",
    STATISTIC_ORG_POSSIBLE_CHANGE_VOICE_ALLOWED: "Разрешено",
    STATISTIC_ORG_POSSIBLE_CHANGE_VOICE_FORBIDDEN: "Запрещено",
    STATISTIC_ORG_SYNC_TIME_INTERVALS: "Совмещение временных интервалов",
    STATISTIC_ORG_SYNC_TIME_INTERVALS_ALIGNED: "Совмещены",
    STATISTIC_ORG_SYNC_TIME_INTERVALS_INTERSECT: "Пересекаются",
    STATISTIC_ORG_SYNC_TIME_INTERVALS_NOT_INTERSECT: "Не пересекаются",
    STATISTIC_ORG_POSSIBILITY_JOIN_LINK: "Возможность присоединения по ссылке",
    STATISTIC_ORG_POSSIBILITY_JOIN_LINK_ALIGNED: "Разрешено",
    STATISTIC_ORG_POSSIBILITY_JOIN_LINK_FORBIDDEN: "Запрещено",
    STATISTIC_ORG_AVAILABILITY_VOTING_MATERIALS:
        "Наличие материалов к голосованию",
    STATISTIC_ORG_AVAILABILITY_VOTING_MATERIALS_YES: "Да",
    STATISTIC_ORG_AVAILABILITY_VOTING_MATERIALS_NOT: "Нет",
    STATISTIC_ORG_AVAILABILITY_VOTING_QUESTIONS:
        "Наличие материалов к вопросам",
    STATISTIC_ORG_AVAILABILITY_VOTING_QUESTIONS_YES: "Да",
    STATISTIC_ORG_AVAILABILITY_VOTING_QUESTIONS_NOT: "Нет",
    STATISTIC_ORG_QUESTIONS_BY_TYPE: "Вопросы по типам",
    STATISTIC_ORG_QUESTIONS_BY_TYPE_YNQ: "По вопросу",
    STATISTIC_ORG_QUESTIONS_BY_TYPE_NONE: "Произвольный",
    STATISTIC_ORG_QUESTIONS_BY_TYPE_POSITION_SINGLE: "1 место 1 кандидат",
    STATISTIC_ORG_QUESTIONS_BY_TYPE_POSITION_MULTIPLE: "1 место N кандидатов",
    STATISTIC_ORG_QUESTIONS_BY_TYPE_POSITION: "Кандидатурное",
    STATISTIC_ORG_QUESTIONS_BY_TYPE_GRID: "Сетка",
    STATISTIC_ORG_QUESTIONS_BY_TYPE_RADIO_GRID: "Радио-сетка",
    STATISTIC_ORG_NUMBER_OF_MATERIALS_TO_VOTE:
        "Количество материалов к голосованию",
    STATISTIC_ORG_NUMBER_OF_MATERIALS_FOR_QUESTIONS:
        "Количество материалов к вопросам",
    STATISTIC_ORG_AVERAGE_REG_TIME: "Среднее время регистрации",
    STATISTIC_ORG_AVERAGE_VOTE_TIME: "Среднее время голосования",
    STATISTIC_ORG_PRESENCE_OF_OBSERVERS: "Наличие наблюдателей",
    STATISTIC_ORG_PRESENCE_OF_OBSERVERS_YES: "Да",
    STATISTIC_ORG_PRESENCE_OF_OBSERVERS_NOT: "Нет",
    STATISTIC_ORG_PRESENCE_OF_COUNTERS: "Наличие счетной коммисии",
    STATISTIC_ORG_PRESENCE_OF_COUNTERS_YES: "Да",
    STATISTIC_ORG_PRESENCE_OF_COUNTERS_NOT: "Нет",
    STATISTIC_ORG_AVERAGE_TURNOUT: "Средняя явка",
    STATISTIC_ORG_AVERAGE_NUMBER_ADD_TO_VOTE:
        "Среднее количество добавленных в голосование",
    PHONE_AUTH: "Разрешить вход/регистрацию по телефону",
  },
  ADD_TEMPLATES: {
    ADD_TEMPLATES_NAME_TEMPLATE: "Название шаблона",
    ADD_TEMPLATES_DATE_CREATE: "Дата создания",
    ADD_TEMPLATES_DATE_CREATE_MOBILE: "Дата создания:",
    ADD_TEMPLATES_ACTION: "Действия",
    ADD_TEMPLATES_ADD_TEMPLATE_BTN: "Добавить шаблон",
    ADD_TEMPLATES_SHOW_MORE_BTN: "ПОКАЗАТЬ ПОЛНОСТЬЮ",
    ADD_TEMPLATES_EDIT_MOBILE_BTN: "Редактировать",
    ADD_TEMPLATES_DELETE_MOBILE_BTN: "Удалить",
    ADD_TEMPLATES_NOT_FIND_MESSAGE: "Шаблоны не найдены",
  },
  DETAILS_VOTE: {
    INFORMATION_MENU_GENERAL_INFO: "Общая информация",
    INFORMATION_MENU_STATISTIC_VOTE: "Статистика голосования",
    INFORMATION_MENU_VOTING: "Голосующие",
    INFORMATION_MENU_OBSERVERS: "Наблюдатели",
    INFORMATION_MENU_KNOW_QUESTIONS: "Ознакомиться с вопросами",
    INFORMATION_MENU_ROW_BACK_VOTE_LIST: "Назад к списку голосований",
    INFORMATION_MENU_ROW_BACK_MENU: "Назад в меню",
    GENERAL_INFO_TITLE: "Общая информация",
    GENERAL_INFO_STATUS_VOTING_IN_PROGRESS: "Идет голосование",
    GENERAL_INFO_STATUS_WAITING_FOR_VOTE: "Ожидание голосования",
    GENERAL_INFO_STATUS_WAITING_FOR_REG: "Ожидание регистрации",
    GENERAL_INFO_STATUS_REG_IN_PROGRESS: "Идет регистрация",
    GENERAL_INFO_STATUS_VOTING_END: "Голосование завершено",
    GENERAL_INFO_STATUS_QUORUM_NOT_REACHED: "Кворум не достигнут",
    GENERAL_INFO_STATUS_REG_AND_VOTE: "Регистрация и голосование",
    GENERAL_INFO_TYPE_VOTE_OPEN: "Открытое",
    GENERAL_INFO_TYPE_VOTE_CLOSE: "Тайное",
    GENERAL_INFO_POSSIBLE_REVOTING: "Возможность переголосования:",
    GENERAL_INFO_POSSIBLE_CANCEL_REG: "Возможность отмены регистрации:",
    GENERAL_INFO_START_REG: "Начало регистрации:",
    GENERAL_INFO_END_REG: "Конец регистрации:",
    GENERAL_INFO_START_VOTE: "Начало голосования:",
    GENERAL_INFO_END_VOTE: "Конец голосования:",
    GENERAL_INFO_START_VOTE_BTN: "Начать голосование",
    GENERAL_INFO_END_VOTE_BTN: "Завершить голосование",
    GENERAL_INFO_START_REG_BTN: "Начать регистрацию",
    GENERAL_INFO_END_REG_BTN: "Завершить регистрацию",
    GENERAL_INFO_DELETE_VOTE_BTN: "Удалить событие",
    GENERAL_INFO_RESTORE_VOTE_BTN: "Восстановить событие",
    GENERAL_INFO_OPTION_PROLONG_VOTE_BTN: "Опции продления голосования",
    GENERAL_INFO_OPTION_PROLONG_MODAL_TITLE: "ВАРИАНТЫ ПРОДЛЕНИЯ ГОЛОСОВАНИЯ",
    GENERAL_INFO_OPTION_PROLONG_MODAL_INTERVAL_PROLONG:
        "Интервал для продления",
    GENERAL_INFO_OPTION_PROLONG_MODAL_REG: "Регистрация",
    GENERAL_INFO_OPTION_PROLONG_MODAL_VOTE: "Голосование",
    GENERAL_INFO_OPTION_PROLONG_MODAL_REG_AND_VOTE: "Регистрация и голосование",
    GENERAL_INFO_OPTION_PROLONG_MODAL_SAVE_BTN: "Сохранить",
    GENERAL_INFO_OPTION_PROLONG_MODAL_CANCEL_BTN: "Отмена",
    GENERAL_INFO_LINK_JOIN_TITLE: "Пригласительная ссылка",
    GENERAL_INFO_LINK_SUCCESS_MESSAGE:
        "Ссылка успешно скопирована в буфер обмена!",
    STATISTICS_TITLE_MOBILE: "Статистика",
    STATISTIC_TITLE_MOVE: "Ход",
    STATISTIC_TITLE_AND: "и",
    STATISTIC_TITLE_REGISTRATION: "регистрации",
    STATISTIC_TITLE_VOTED: "голосования",
    STATISTIC_LATEST_UPDATE: "Последнее обновление:",
    STATISTIC_REGISTRED_MEMBERS: "Зарегистрированы",
    STATISTIC_NOT_REGISTRED_MEMBERS: "Не зарегистрированы",
    STATISTIC_VOTED: "Проголосовали",
    STATISTIC_NOT_VOTED: "Не проголосовали",
    STATISTIC_REG_GENERAL_MEMBERS: "Зарегистрировано",
    STATISTIC_VOTED_GENERAL_MEMBERS: "Проголосовало",
    STATISTIC_QUORUM_CONDITION: "Условие кворума",
    STATISTIC_STATUS_EVENT: "Статус события",
    STATISTIC_QUORUM_SUCCESS: "Кворум достигнут",
    STATISTIC_QUORUM_NOT_SUCCESS: "Кворум не достигнут",
    STATISTIC_ELECTRONIC_VISIT: "Электронная явка",
    STATISTIC_SHOW_MORE_BTN: "ПОКАЗАТЬ ПОЛНОСТЬЮ",
    STATISTIC_UPDATE_STATISTIC_EVENT_BTN: "Обновить статистику события",
    STATISTIC_DYNAMIC_VISIT: "Динамика явки",
    STATISTIC_GENERAL_VISIT: "Общая явка",
    STATISTIC_VISIT_VOTING: "Явка голосующих",
    STATISTIC_TABLE_TIME: "Время",
    STATISTIC_TABLE_VISIT_TIME: "Явка за время",
    STATISTIC_HIDE_BTN: "СВЕРНУТЬ",
    STATISTIC_EMPTY_DATA_VISIT_VOTERS:
        "Данные по динамике общей явки не получены с сервера!",
    STATISTIC_EMPTY_DATA_VISIT_VOTERS_TABLE:
        "Данных по динамике общей явки пока ещё нет!",
    STATISTIC_QUORUM_CONDITION_NONE: "Условие кворума отсутствует",
    STATISTIC_QUORUM_CONDITION_HALF_MEMBERS:
        "Необходимо участие в голосовании половины участников",
    STATISTIC_QUORUM_CONDITION_MORE_THAN_HALF_MEMBERS:
        "Необходимо участие в голосовании более чем половины участников",
    STATISTIC_QUORUM_CONDITION_INVOLVEMENT:
        "Необходимо участие в голосовании 2/3 участников",
    VOTING_TITLE: "Список голосующих",
    VOTING_TITLE_MOBILE: "Голосующие",
    VOTING_EXPORT_EXCEL: "ЭКСПОРТ В EXCEL",
    VOTING_STATUS_REGISTRED: "Зарегистрирован",
    VOTING_STATUS_NO_REGISTRED: "Не зарегистрирован",
    VOTING_STATUS_VOTED: "Проголосовал",
    VOTING_STATUS_NO_VOTED: "Не проголосовал",
    VOTING_EMPTY_INFO_OBSERVERS:
        "Кажется, в этом голосовании не участвовали наблюдатели! Если вы хотите, чтобы в следующем голосовании были наблюдатели, пожалуйста, добавьте их на этапе",
    VOTING_EMPTY_INFO_VOTERS:
        "Кажется, в этом голосовании не участвовали голосующие! Если вы хотите, чтобы в следующем голосовании были голосующие, пожалуйста, добавьте их на этапе",
    VOTING_EMPTY_INFO_COUNTERS:
        "Кажется, в этом голосовании не участвовали члены счетной комиссии! Если вы хотите, чтобы в следующем голосовании были члены счетной комиссии, пожалуйста, добавьте их на этапе",
    VOTING_EMPTY_INFO_LINK: "создания голосования.",
    VOTING_SEND_NOTIFY_MESSAGE: "Отправить почтовые уведомления пользователям",
    OBSERVERS_TITLE: "Список наблюдателей",
    OBSERVERS_TITLE_MOBILE: "Наблюдатели",
    COUNTING_TITLE: "Счетная комиссия",
    COUNTING_TITLE_MOBILE: "Счетная комиссия",
    COUNTING_STATUS_SIGNED: "Подписал",
    COUNTING_STATUS_NO_SIGNED: "Не подписал",
    QUESTIONS_TITLE: "Вопросы для голосования",
    QUESTIONS_TITLE_MOBILE: "Ознакомиться с вопросами",
    RESULTS_TITLE: "Результаты",
    RESULTS_QUESTIONS_VOTE: "Вопросы для голосования",
    RESULTS_RECREATE_PROTOCOL: "Пересоздать протокол",
    RESULTS_EXPORT_DOC: "Экспорт в docx",
    RESULTS_SELECT_VARIANTS_ANSWER: "Выберите один из вариантов ответа",
    RESULTS_SIGN_PROTOCOL_VOTE: "Подписать протокол подсчета голосов",
    RESULTS_SEND_MY_OPINION:
        "Оставить особое мнение с занесением в итоговый протокол",
    RESULTS_RESULTS_SEND_OPINION_MODAL_TITLE: "Особое мнение",
    RESULTS_RESULTS_SEND_OPINION_MODAL_SEND_BTN: "Отправить",
    RESULTS_RESULTS_SEND_OPINION_MODAL_PLACEHOLDER: "Оставьте особое мнение",
    RESULTS_RESULTS_SEND_OPINION_MODAL_CANCEL_BTN: "Отмена",
  },
  NO_MATCH_ROUTES: {
    NO_MATCH_ROUTES_PAGE_NOT_FOUND: "Страница не найдена",
    NO_MATCH_ROUTES_BACK_MAINPAGE: "Назад на главную",
    NO_MATCH_ROUTES_MESSAGE:
        "Кажется что-то пошло не так! Страница, которую вы запрашиваете, не существует.",
  },
  GENERAL: {
    ALT_ICON: "Иконка",
  },
};
