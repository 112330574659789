import React, { useEffect, useState } from "react";
import {fixedColors} from "../../utils/ColorArrayForGraph/colorArryaForGraph"
import CardQuestionGraphNameColumnRow from "./CardQuestionGraphNameColumn/CardQuestionGraphNameColumnRow";
import CardQuestionHorizontalGraphRow from "./CardQuestionHorizontalGraph/CardQuestionHorizontalGraphRow";
import CardQuestionVerticalGraphRow from "./CardQuestionVerticalGraph/CardQuestionVerticalGraphRow";

const DetailsVotesPageResultVotesCardQuestionGraphRow = (props) => {
    const stub = false; // 2) Без испорченых биллютеней - опция организации 

  const { answersTemplateRow, numInvalid, votedUsers } = props;

  const [showGraphTypeVertical, setShowGraphTypeVertical] = useState(true);
  const [showGraphTypeHorizontal, setShowGraphTypeHorizontal] = useState(false);

  function randomColorRow(resultItems) {
    const colorArray = [];
    let i = 0;

    function findDublicateColor(array, value) {
      for(let i = 0; i < array.length; i++) {
        if(array[i] === value) return 1;
      }
      return 0;
    }

    if(resultItems < 1) {
      return
    } else {
      while( i < resultItems) {
          let color = '#'+(Math.random() * 0x1000000 | 0x1000000).toString(16).slice(1);
          if(findDublicateColor(colorArray, color) === 0) {
            colorArray[i] = color;
            i++;
          }
      }
    }
    return colorArray;
  }

  const resultWithColor = answersTemplateRow.map(function (item, i) {
    return i > 12
          ? { ...item, color: randomColorRow(answersTemplateRow.length)[i] }
          : { ...item, color: fixedColors[i] }
  });

  useEffect(() => {
    let isMounted = true;
    if (true || answersTemplateRow.length > 3) {
      isMounted && setShowGraphTypeHorizontal(true);
      isMounted && setShowGraphTypeVertical(false);
    } else {
      isMounted && setShowGraphTypeHorizontal(false);
      isMounted && setShowGraphTypeVertical(true);
    }
    return () => {
      isMounted = false;
    }
  }, [answersTemplateRow.length]);

  return (
    <div className={"details-votes-page-result-card-graph__wrapper"}>
      {showGraphTypeHorizontal && (
        <CardQuestionHorizontalGraphRow
          resultVote={resultWithColor}
          numInvalid={numInvalid}
          votedUsers={votedUsers}
        />
      )}
      {showGraphTypeVertical && (
        <CardQuestionVerticalGraphRow
          resultVote={resultWithColor}
          numInvalid={numInvalid}
          votedUsers={votedUsers}
        />
      )}
      <div className={"details-votes-page-result-card-graph__column-list"}>
        {resultWithColor.map((item, i) => {
          return (
            <CardQuestionGraphNameColumnRow
              key={i}
              nameColumn={item.title}
              colorSquare={item.color}
              votedPercent={
                item.columns[0].favor > 0
                  ? ((item.columns[0].favor / votedUsers) * 100).toFixed(1)
                  : 0
              }
            />
          );
        })}

        {stub && (
          <CardQuestionGraphNameColumnRow
            nameColumn={"Недействительные бюллетени"}
            colorSquare={"#9FA1A8"}
            votedPercent={0}
          />
        )}
      </div>
    </div>
  );
};
export default DetailsVotesPageResultVotesCardQuestionGraphRow;
