import { useState, useEffect } from "react";
import sorting_modal_close_button from "../../img/VotesPageBlockFilterModal_close_button.svg";
import { Validation } from "../../utils/Validation/Validation";
import { useMutation } from "@tanstack/react-query";
import { addSingleUser } from "../../Api/auth-service";

const AddNewUser = ({ requestHelper, setNotFoundedUsers }) => {

  const [addNewUser, setAddNewUser] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const userEmail = Validation();
  const userName = Validation();
  const userSurname = Validation();
  const userLastName = Validation();

  useEffect(() => {
    setDisableSubmit(false)
    setFormErrorMessage("")
  }, []);

  useEffect(() => {
    if(disableSubmit === true) {
      setFormErrorMessage("")
    }
  },[disableSubmit])

  const addSingleUserMutation = useMutation({
    mutationFn: (dataUser) => {
      return addSingleUser(dataUser)
    },
    onError: (data) => {
      if(data.response.status !== 200) {
        if(data.response.data.text.includes('already exist')) {
          setDisableSubmit(false);
          setFormErrorMessage('Данный пользователь уже добавлен в систему')
        } else if(data.response.data.text.includes('is not correct')) {
          setDisableSubmit(false);
          setFormErrorMessage('Введены некорректные данные');
        } else {
          setDisableSubmit(false);
          setFormErrorMessage(`Ошибка при добавлении пользователя. Код ошибки: ${data.response.status}`);
        }
      }
    },
  });

  const addNewUserHandler = () => {
    setDisableSubmit(true)
    const body = {
      first_name: userName.value,
      last_name: userSurname.value,
      second_name: userLastName.value ? userLastName.value : "",
      email: userEmail.value ? userEmail.value : null,
      registration_type: userEmail.value ? "EMAIL" : "NAME",
      realm: "REALM_CRYPTOVECHE",
    };

    if (userName.value === "") {
      userName.setErrorMessage("Заполните это поле.");
    }

    if (userSurname.value === "") {
      userSurname.setErrorMessage("Заполните это поле.");
    }

    if (userName.value === "" || userSurname.value === "") {
      setDisableSubmit(false);
      return;
    }

    addSingleUserMutation.mutate(body)

    setNotFoundedUsers && setNotFoundedUsers("");
  };

  useEffect(() => {
    if (addNewUser) {
      document.body.classList.add("body-overlay");
      return;
    }

    document.body.classList.remove("body-overlay");
  }, [addNewUser]);

  const onHandleCloseModal = () => {
    setAddNewUser(false);
    setDisableSubmit(false);
    setFormErrorMessage('');
    userEmail.setValue('');
    userName.setValue('');
    userSurname.setValue('');
    userLastName.setValue('');
    userName.setErrorMessage("");
    userSurname.setErrorMessage("");
    userEmail.setErrorMessage("");
  }

  const onHandleAlseCreateNewUser = () => {
    setDisableSubmit(false);
    setFormErrorMessage('');
    userEmail.setValue('');
    userName.setValue('');
    userSurname.setValue('');
    userLastName.setValue('');
    userName.setErrorMessage("");
    userSurname.setErrorMessage("");
    userEmail.setErrorMessage("");
  }

  return (
      <>
        <div style={{ display: "flex", marginBottom: 20 }}>
          <button
              onClick={() => {
                setAddNewUser(!addNewUser);
              }}
              className="group-users-select-name-group__button"
          >
            Добавить нового пользователя
          </button>
        </div>

        {addNewUser && (
            <div className="group-users-select-name-group__add-users-form form-add-user">
              <div className="group-users-select-name-group__add-users-form-title">
                <h2>
                  Добавить нового пользователя
                </h2>
                <img
                    alt={""}
                    src={sorting_modal_close_button}
                    onClick={onHandleCloseModal}
                    className="group-users-select-name-group__add-users-form-icon-close"
                />
              </div>
              <div className="add-new-vote__name-new-vote">
                <label className="add-new-vote__name-new-vote-label">Email</label>
                <input
                    type="email"
                    className="add-new-vote__name-new-vote-field"
                    placeholder="Введите почту"
                    name="user-name"
                    value={userEmail.value}
                    onChange={userEmail.onChange}
                    required
                />
                {userEmail.errorMessage && (
                    <p className="error-text">{userEmail.errorMessage}</p>
                )}
              </div>

              <div className="add-new-vote__name-new-vote">
                <label className="add-new-vote__name-new-vote-label">
                  Фамилия
                  <span className="add-new-vote__red-star"> *</span>
                </label>
                <input
                    type="text"
                    className="add-new-vote__name-new-vote-field"
                    placeholder="Введите фамилию"
                    name="user-surname"
                    value={userSurname.value}
                    onChange={userSurname.onChange}
                />
                {userSurname.errorMessage && (
                    <p className="error-text">{userSurname.errorMessage}</p>
                )}
              </div>

              <div className="add-new-vote__name-new-vote">
                <label className="add-new-vote__name-new-vote-label">
                  Имя
                  <span className="add-new-vote__red-star"> *</span>
                </label>
                <input
                    type="text"
                    className="add-new-vote__name-new-vote-field"
                    placeholder="Введите имя"
                    name="user-name"
                    value={userName.value}
                    onChange={userName.onChange}
                />
                {userName.errorMessage && (
                    <p className="error-text">{userName.errorMessage}</p>
                )}
              </div>

              <div className="add-new-vote__name-new-vote">
                <label className="add-new-vote__name-new-vote-label">
                  Отчество
                </label>
                <input
                    type="text"
                    className="add-new-vote__name-new-vote-field"
                    placeholder="Введите отчество"
                    name="user-lastname"
                    value={userLastName.value}
                    onChange={userLastName.onChange}
                />
                {userLastName.errorMessage && (
                    <p className="error-text">{userLastName.errorMessage}</p>
                )}
              </div>

              {formErrorMessage && (
                  <p
                      className="error-text"
                      style={{marginBottom: 25, fontSize: 14}}
                  >
                    {formErrorMessage}
                  </p>
              )}

              {disableSubmit ? <p className="add-new-user-button-success-message">Пользователь успешно добавлен!</p> :
                  <button
                      onClick={addNewUserHandler}
                      type="button"
                      className={"add-new-vote-question-type__add-vote-btn"}
                      style={{width: "100%"}}
                  >
                    Добавить
                  </button>}
              <span
                  className={disableSubmit ? "add-new-user-button-add-also-user active" : "add-new-user-button-add-also-user"}
                  onClick={onHandleAlseCreateNewUser}>
            Добавить нового пользователя
          </span>
              {/* {addNewError && (
            <p style={{ marginTop: 15, color: "red" }}>Что-то пошло не так</p>
          )} */}
            </div>
        )}
      </>
  );
};

export default AddNewUser;