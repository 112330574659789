import React, { useEffect, useState } from "react";
import {fixedColors} from "../../utils/ColorArrayForGraph/colorArryaForGraph"
import CardQuestionVerticalGraphGrid from "./CardQuestionVerticalGraph/CardQuestionVerticalGraphGrid";
import CardQuestionGraphNameColumnGrid from "./CardQuestionGraphNameColumn/CardQuestionGraphNameColumnGrid";
import CardQuestionHorizontalGraphGrid from "./CardQuestionHorizontalGraph/CardQuestionHorizontalGraphGrid";

const DetailsVotesPageResultVotesCardQuestionGraphGrid = (props) => {

  const { answersTemplateGrid } = props;

  const [showGraphTypeVertical, setShowGraphTypeVertical] = useState(true);
  const [showGraphTypeHorizontal, setShowGraphTypeHorizontal] = useState(false);
  const [hideChangePlaceColumns, setHideChangePlaceColumns] = useState(true);
  const [showChangePlaceColumns, setShowChangePlaceColumns] = useState(false);

  function randomColorGrid(resultItems) {
    const colorArray = [];
    let i = 0;

    function findDublicateColor(array, value) {
      for(let i = 0; i < array.length; i++) {
        if(array[i] === value) return 1;
      }
      return 0;
    }

    if(resultItems < 1) {
      return
    } else {
      while( i < resultItems) {
          let color = '#'+(Math.random() * 0x1000000 | 0x1000000).toString(16).slice(1);
          if(findDublicateColor(colorArray, color) === 0) {
            colorArray[i] = color;
            i++;
          }
      }
    }
    return colorArray;
  }

  const getAllColumn = answersTemplateGrid.map((el) => el.columns);
  const getNameColumnWithColor = getAllColumn[0].map(function (item, i) {
    return i > 12
        ? { ...item, color: randomColorGrid(answersTemplateGrid.length)[i] }
        : { ...item, color: fixedColors[i] }
  });
  const resultWithColor = answersTemplateGrid.map(function (item, i) {
    return i > 12
       ? { ...item, color: randomColorGrid(answersTemplateGrid.length)[i] }
       : { ...item, color: fixedColors[i] }
  });

  useEffect(() => {
    if (true || answersTemplateGrid.length > 3 || getAllColumn.length > 3) {
      setShowGraphTypeHorizontal(true);
      setShowGraphTypeVertical(false);
      setShowChangePlaceColumns(true);
      setHideChangePlaceColumns(false);
    } else {
      setShowGraphTypeHorizontal(false);
      setShowGraphTypeVertical(true);
      setShowChangePlaceColumns(false);
      setHideChangePlaceColumns(true);
    }
  }, [answersTemplateGrid.length, getAllColumn.length]);

  return (
    <div className={"details-votes-page-result-card-graph__wrapper"}>
      {showGraphTypeHorizontal && (
        <CardQuestionHorizontalGraphGrid resultVote={resultWithColor} />
      )}
      {showGraphTypeVertical && (
        <CardQuestionVerticalGraphGrid
          resultVote={resultWithColor}
          getNameColumnColor={getNameColumnWithColor}
        />
      )}
      <div className={"details-votes-page-result-card-graph__column-list"}>
        {hideChangePlaceColumns &&
          getNameColumnWithColor.map((item) => {
            return (
              <CardQuestionGraphNameColumnGrid
                key={item.id}
                nameColumn={item.value}
                colorSquare={item.color}
              />
            );
          })}
        {showChangePlaceColumns &&
          resultWithColor.map((el) => {
            return (
              <CardQuestionGraphNameColumnGrid
                key={el.id}
                nameColumn={el.title}
                colorSquare={el.color}
              />
            );
          })}
      </div>
    </div>
  );
};
export default DetailsVotesPageResultVotesCardQuestionGraphGrid;
