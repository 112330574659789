import {createContext, useReducer, useState} from 'react';

const CurrentUserContext = createContext({});

const UPDATE_DATA_EVENTS = 'updateDataEvents';
const RESET_STATE_ADD_COMPONENT_VOTE_TEMPLATE = 'resetStateAddVoteTemplate';
const CLEAN_STATE_ADD_COMPONENT_VOTE_TEMPLATE = 'resetStateAddVoteTemplate';

const initialState = {
    updateDataEvents: false,
    resetStateAddVoteTemplate: ''
}

function reducer(state, action) {
    switch (action.type) {
        case UPDATE_DATA_EVENTS :
            return { ...state, updateDataEvents: !state.updateDataEvents};
        case RESET_STATE_ADD_COMPONENT_VOTE_TEMPLATE :
            return {...state, resetStateAddVoteTemplate: action.resetStateAddVoteTemplate};
        case CLEAN_STATE_ADD_COMPONENT_VOTE_TEMPLATE :
            return {...state, resetStateAddVoteTemplate: ''}
        default: return state
    }
}

export const CurrentUserContextProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState({});
    const [stateReducer, dispatch] = useReducer(reducer, initialState);
    const [twoFactorAuthLoginDate, setTwoFactorAuthLoginDate] = useState({});

    const runUpdateDataEvents = () => dispatch({type: UPDATE_DATA_EVENTS});
    const resetStateAddEvents = (resetStateAddVoteTemplate) => dispatch({type: RESET_STATE_ADD_COMPONENT_VOTE_TEMPLATE, resetStateAddVoteTemplate});
    const clearStateAddEvents = () => dispatch({type: CLEAN_STATE_ADD_COMPONENT_VOTE_TEMPLATE})

    return (
        <CurrentUserContext.Provider value={{
            currentUser,
            setCurrentUser,
            stateReducer,
            twoFactorAuthLoginDate,
            setTwoFactorAuthLoginDate,
            runUpdateDataEvents,
            resetStateAddEvents,
            clearStateAddEvents
        }}>
            {children}
        </CurrentUserContext.Provider>
    )
}
export default CurrentUserContext;