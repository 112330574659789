import React, { useEffect, useRef, useState } from "react";
import iconTime from "../../img/VotesPageActiveVotes_time_icon.svg";
import iconDate from "../../img/DetailsVoteStatisitcsVoteIconDate.svg";
import iconGreenCircle from "../../img/DetailsVoteStaticticsIconGreenCircle.svg";
import iconYellowCircle from "../../img/DetailsVoteStaticticsIconYellowCircle.svg";
import iconGrayCircle from "../../img/DetailsVoteStaticticsIconGrayCircle.svg";
import iconGreenDash from "../../img/DetailsVoteStaticticsIconGreenDash.svg";
import iconYellowDash from "../../img/DetailsVoteStaticticsIconYellowDash.svg";
import iconBlueDashGraph from "../../img/DetailsVoteStaticticsIconBlueDashGraph.svg";
import iconGreenDashGraph from "../../img/DetailsVoteStaticticsIconGreenDashGraph.svg";
import iconQuorumReached from "../../img/AddNewOrgSuccessIcon.svg";
import iconNotQuorumReached from "../../img/DetailsVoteVotingIconNoVote.svg";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import useSectorCircleReg from "../../utils/CustomHooks/useSectorCircleReg/useSectorCircleReg";
import useSectorCircleVote from "../../utils/CustomHooks/useSectorCircleVote/useSectorCircleVote";
import { setConditionQuorum } from "../../utils/SetConditionQuorum/setConditionQuorum";
import * as Events from "../../Api/Events";
import { useNavigate } from "react-router-dom";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const DetailsVoteStatisticsVote = (props) => {
  const {
    constants,
    formatDate,
    formatTime,
    currentEventData,
    statisticsVoteTable,
    requestHelper,
    withLink = false,
    isStatisticsUpdate,
    setIsStatisticsUpdate,
    withRegData,
  } = props;

  const { id } = useParams();

  const [voteTableState, setVoteTableState] = useState(statisticsVoteTable);
  const [eventStatistics, setEventStatistics] = useState(
    currentEventData.statistics
  );

  useEffect(() => {
    setVoteTableState(statisticsVoteTable);
  }, [statisticsVoteTable]);

  useEffect(() => {
    setEventStatistics(currentEventData.statistics);

    console.log(
      "votedUsersPercentage: ",
      eventStatistics?.votedUsersPercentage
    );
  }, [currentEventData]);

  useEffect(() => {
    if (isStatisticsUpdate) {
      const promise1 = requestHelper(Events.getStatistics, { id: id })
        .then((data) => {
          if (data) {
            setVoteTableState(data);
          } else {
            setVoteTableState([]);
          }
        })
        .catch((err) => {
          throw new Error(err.message);
        });

      const promise2 = requestHelper(Events.getNetworkStatistics, { id: id })
        .then((data) => {
          if (data) {
            setEventStatistics(data);
            console.log(
              "network data votedUsersPercentage: ",
              data.votedUsersPercentage
            );
          } else {
            setEventStatistics([]);
          }
        })
        .catch((err) => {
          throw new Error(err.message);
        });

      Promise.all([promise1, promise2]).then(() => {
        setIsStatisticsUpdate(false);
        window.getNetworkStatistics_started = 0;
      });
    }
  }, [isStatisticsUpdate]);

  const { runUpdateDataEvents } = useCurrentUserContext();
  const navigate = useNavigate();
  const [activeDynamicsVisit, setActiveDynamicsVisit] = useState(false);
  const [activeGraphVisit, setActiveGraphVisit] = useState(true);
  const [activeTableVisit, setActiveTableVisit] = useState(false);
  const [sectorCircleReg, setSectorCircleReg] = useSectorCircleReg(
    eventStatistics?.registeredUsersPercentage
  );
  const [sectorCircleVote, setSectorCircleVote] = useSectorCircleVote(
    eventStatistics?.votedUsersPercentage
  );
  const progressBarRegRef = useRef(null);
  const progressBarVoteRef = useRef(null);
  const isChartSumPercentage =
    voteTableState.length !== 0
      ? voteTableState.statistics?.map((el) => el?.cumSumPercentage)
      : [];
  const isChartTurnoutPercentage =
    voteTableState.length !== 0
      ? voteTableState.statistics?.map((el) => el?.turnoutPercentage)
      : [];
  const [isMessageUpdateStatistics, setMessageUpdateStatistics] = useState("");

  const onChangeGraphVisit = () => {
    setActiveGraphVisit(true);
    setActiveTableVisit(false);
  };

  const onChangeTableVisit = () => {
    setActiveTableVisit(true);
    setActiveGraphVisit(false);
  };

  const calculateNotRegistration = (value) => {
    return 100 - value;
  };

  const calculateNotVote = (value) => {
    return 100 - value;
  };

  function textUpdateStatisticsVote() {
    setTimeout(() => {
      setMessageUpdateStatistics("");
    }, 1000);
  }

  useEffect(() => {
    if (
      eventStatistics?.registeredUsersPercentage !== 0 &&
      Object.keys(currentEventData).length !== 0
    ) {
      progressBarRegRef.current.style.width = `${eventStatistics?.registeredUsersPercentage}%`;
    } else {
      return (progressBarRegRef.current.style.width = "0%");
    }
  }, [eventStatistics?.registeredUsersPercentage, currentEventData]);

  // const [withRegData, setWithRegData] = useState(
  //   currentEventData.highlight_registered_in_statistics
  // );

  // useEffect(() => {
  //   setWithRegData(currentEventData.highlight_registered_in_statistics);
  // }, [currentEventData]);

  function drawCircles({
    circleRadius = 22,
    circleReg,
    circleVote,
    colorsArray = ["#F1F1F1", "#F9C521", "#4ED4A9"],
  }) {
    const circleFull = 2 * Math.PI * circleRadius;

    // const circleReg = 0.66;
    // const circleVote = 0.33;

    const GeneralCircle = {
      radius: circleRadius,
      width: 1,
      color: colorsArray[0],
    };

    const InnerCircle = {
      radius: circleRadius - 0.5 * GeneralCircle.width, // radius - 0.5 смещение, чтобы шел поверх общего круга,
      width: GeneralCircle.width * 2,
      color: withRegData ? colorsArray[1] : colorsArray[2], // делаем желтый круг зеленым, если отключен показ регистрации
    };

    const OuterCircle = {
      radius: circleRadius - (InnerCircle.width + 0.5 * GeneralCircle.width), // radius - 2.5
      width: GeneralCircle.width * 2,
      color: colorsArray[2],
      circleFull: 2 * Math.PI * (circleRadius - InnerCircle.width),
    };

    const test = 0.12;
    return (
      <>
        {circleReg &&
          circleVote &&
          typeof circleReg === "number" &&
          typeof circleVote === "number" && (
            <svg
              className={"diagramm-circle"}
              viewBox={"0 0 50 50"}
              style={{ transform: "rotate(-90deg)" }}
            >
              {/* Общий круг участников General Circle */}
              <circle
                fill="none"
                strokeWidth={GeneralCircle.width}
                r={GeneralCircle.radius}
                cx={"50%"}
                cy={"50%"}
                stroke={GeneralCircle.color}
                strokeDasharray={circleFull}
                strokeDashoffset={0}
              />

              {/* Круг внутренний Inner Circle - заполняется поверх общего круга */}
              <circle
                fill="none"
                strokeWidth={
                  (withRegData &&
                    eventStatistics?.registeredUsersPercentage > 0) ||
                  (!withRegData && eventStatistics?.votedUsersPercentage > 0)
                    ? InnerCircle.width
                    : 0
                }
                r={InnerCircle.radius}
                cx={"50%"}
                cy={"50%"}
                stroke={InnerCircle.color}
                strokeDasharray={`${
                  circleFull * (withRegData ? circleReg : circleVote)
                } ${
                  circleFull -
                  circleFull * (withRegData ? circleReg : circleVote)
                }`}
                // strokeDasharray={`${circleFull * test} ${
                //   circleFull - circleFull * test
                // }`}
                strokeDashoffset={0}
              />

              {/* Круг дополнительный - идет внутри  */}

              {withRegData && (
                <circle
                  fill="none"
                  strokeWidth={
                    eventStatistics?.votedUsersPercentage > 0
                      ? OuterCircle.width
                      : 0
                  }
                  r={OuterCircle.radius}
                  cx={"50%"}
                  cy={"50%"}
                  stroke={OuterCircle.color}
                  strokeDasharray={`${OuterCircle.circleFull * circleVote} ${
                    OuterCircle.circleFull - OuterCircle.circleFull * circleVote
                  }`}
                  strokeDashoffset={0}
                />
              )}
            </svg>
          )}
      </>
    );
  }

  return (
    <div className="details-vote-statistics-vote__container">
      {withLink && (
        <Link
          style={{ marginBottom: "15px" }}
          to="/statistics"
          target="_blank"
          className="details-vote-statistics-vote-update-statistics-vote__button"
        >
          Открыть в отдельном окне
        </Link>
      )}
      <h3 className="details-vote-statistics-vote__title-mobile">
        {constants.DETAILS_VOTE.STATISTICS_TITLE_MOBILE}
      </h3>
      <div className="details-vote-statistics-vote-title-block">
        <h3 className="details-vote-statistics-vote__title-and">
          {constants.DETAILS_VOTE.STATISTIC_TITLE_MOVE}
        </h3>
        {withRegData && (
          <>
            <h3 className="details-vote-statistics-vote__title">
              {constants.DETAILS_VOTE.STATISTIC_TITLE_REGISTRATION}
            </h3>
            <h3 className="details-vote-statistics-vote__title-and">
              {constants.DETAILS_VOTE.STATISTIC_TITLE_AND}
            </h3>
          </>
        )}
        <h3 className="details-vote-statistics-vote__title">
          {constants.DETAILS_VOTE.STATISTIC_TITLE_VOTED}
        </h3>
      </div>
      <div className="details-vote-statistics-vote__last-update">
        <p className="details-vote-statistics-vote__last-update-label">
          {constants.DETAILS_VOTE.STATISTIC_LATEST_UPDATE}
        </p>
        <img src={iconDate} alt={constants.GENERAL.ALT_ICON} />
        <p className="details-vote-statistics-vote__last-update-icon-label">
          {eventStatistics?.updatedTime !== undefined
            ? formatDate(eventStatistics?.updatedTime)
            : ""}
        </p>
        <img src={iconTime} alt={constants.GENERAL.ALT_ICON} />
        <p className="details-vote-statistics-vote__last-update-icon-label">
          {eventStatistics?.updatedTime !== undefined
            ? formatTime(eventStatistics?.updatedTime)
            : ""}
        </p>
      </div>
      <div className="details-vote-general-info__results-diagramm-block">
        <div className="details-vote-statistics-vote__result-voted-registred-block">
          <div className="details-vote-statistics-vote__result-registred">
            <div className="details-vote-statistics-vote__result-registred-items">
              <img
                className="details-vote-statistics-vote__result-registred-icon"
                src={iconGreenCircle}
                alt={constants.GENERAL.ALT_ICON}
              />
              <p className="details-vote-statistics-vote__result-voted-label">
                {constants.DETAILS_VOTE.STATISTIC_VOTED} (
                {eventStatistics?.votedUsersPercentage}%)
              </p>
            </div>

            {withRegData && (
              <div className="details-vote-statistics-vote__result-registred-items">
                <img
                  className="details-vote-statistics-vote__result-registred-icon"
                  src={iconYellowCircle}
                  alt={constants.GENERAL.ALT_ICON}
                />
                <p className="details-vote-statistics-vote__result-registred-label">
                  {constants.DETAILS_VOTE.STATISTIC_REGISTRED_MEMBERS} (
                  {eventStatistics?.registeredUsersPercentage}%)
                </p>
              </div>
            )}

            <div className="details-vote-statistics-vote__result-registred-items">
              <img
                className="details-vote-statistics-vote__result-registred-icon"
                src={iconGrayCircle}
                alt={constants.GENERAL.ALT_ICON}
              />
              <p className="details-vote-statistics-vote__result-registred-label">
                {withRegData
                  ? `${
                      constants.DETAILS_VOTE.STATISTIC_NOT_REGISTRED_MEMBERS
                    } (${calculateNotRegistration(
                      eventStatistics?.registeredUsersPercentage
                    )}%)`
                  : `${constants.DETAILS_VOTE.STATISTIC_NOT_VOTED} (${
                      100 - eventStatistics?.votedUsersPercentage
                    }%)`}
              </p>
            </div>
          </div>
        </div>
        <div className="details-vote-statistics-vote__diagramm-block">
          {drawCircles({
            circleReg: sectorCircleReg,
            circleVote: sectorCircleVote,
          })}
          <div className="details-vote-statistics-vote__diagramm-block-numbers-reg-voting">
            <p className="details-vote-statistics-vote__diagramm-num-reg">
              {withRegData
                ? eventStatistics?.numRegisteredUsers
                : eventStatistics?.numVotedUsers}
            </p>
            <p className="details-vote-statistics-vote__diagramm-label-reg">
              {withRegData
                ? constants.DETAILS_VOTE.STATISTIC_REG_GENERAL_MEMBERS
                : constants.DETAILS_VOTE.STATISTIC_VOTED_GENERAL_MEMBERS}
            </p>
          </div>
        </div>
        <div className="details-vote-statistics-vote__status-event-quorum-condition">
          <div className="details-vote-statistics-vote__quorum">
            <p className="details-vote-statistics-vote__quorum-label">
              {constants.DETAILS_VOTE.STATISTIC_QUORUM_CONDITION}
            </p>
            <p className="details-vote-statistics-vote__quorum-note">
              {setConditionQuorum(currentEventData?.quorum, constants)}
            </p>
          </div>
          <div className="details-vote-statistics-vote__status-event">
            <p className="details-vote-statistics-vote__status-event-label">
              {constants.DETAILS_VOTE.STATISTIC_STATUS_EVENT}
            </p>
            {eventStatistics?.quorumReached === true ? (
              <p className="details-vote-statistics-vote__status-event-icon">
                <img src={iconQuorumReached} alt={constants.GENERAL.ALT_ICON} />
                {constants.DETAILS_VOTE.STATISTIC_QUORUM_SUCCESS}
              </p>
            ) : (
              <p className="details-vote-statistics-vote__status-event-icon-not-reached">
                <img
                  src={iconNotQuorumReached}
                  alt={constants.GENERAL.ALT_ICON}
                />
                {constants.DETAILS_VOTE.STATISTIC_QUORUM_NOT_SUCCESS}
              </p>
            )}
          </div>
          <div className="details-vote-statistics-vote__progress-visit-vote">
            <p className="details-vote-statistics-vote__progress-visit-vote-label">
              {constants.DETAILS_VOTE.STATISTIC_ELECTRONIC_VISIT} (
              {eventStatistics?.registeredUsersPercentage}%)
            </p>
            <div className="details-vote-statistics-vote__progress">
              <div
                ref={progressBarRegRef}
                className="details-vote-statistics-vote__progress-bar"
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="details-vote-general-info__results-diagramm-block">
        <div className="details-vote-statistics-vote__result-voted-registred-block">
          <div className="details-vote-statistics-vote-title-block-voted">
            <h3 className="details-vote-statistics-vote__title-and">
              {constants.DETAILS_VOTE.STATISTIC_TITLE_MOVE}
            </h3>
            <h3 className="details-vote-statistics-vote__title">
              {constants.DETAILS_VOTE.STATISTIC_TITLE_VOTED}
            </h3>
          </div>
          <div className="details-vote-statistics-vote__result-voted">
            <div className="details-vote-statistics-vote__result-voted-items">
              <img
                className="details-vote-statistics-vote__result-voted-icon"
                src={iconGreenDash}
                alt={constants.GENERAL.ALT_ICON}
              />
              <p className="details-vote-statistics-vote__result-voted-label">
                {constants.DETAILS_VOTE.STATISTIC_VOTED} (
                {eventStatistics?.votedUsersPercentage}%){" "}
              </p>
            </div>
            <div className="details-vote-statistics-vote__result-voted-items">
              <img
                className="details-vote-statistics-vote__result-voted-icon"
                src={iconYellowDash}
                alt={constants.GENERAL.ALT_ICON}
              />
              <p className="details-vote-statistics-vote__result-voted-label">
                {constants.DETAILS_VOTE.STATISTIC_NOT_VOTED} (
                {calculateNotVote(
                  eventStatistics?.votedUsersPercentage
                )}
                %)
              </p>
            </div>
          </div>
        </div>
        <div className="details-vote-statistics-vote__diagramm-block">
          {drawCircles(22, sectorCircleVote, 0.1, [
            "#4ED4A9",
            "#F9C521",
            "#49B3FF",
          ])}
          <div className="details-vote-statistics-vote__diagramm-block-numbers-voting">
            <p className="details-vote-statistics-vote__diagramm-num-reg">
              {eventStatistics?.numVotedUsers}
            </p>
            <p className="details-vote-statistics-vote__diagramm-label-reg">
              {constants.DETAILS_VOTE.STATISTIC_VOTED_GENERAL_MEMBERS}
            </p>
          </div>
        </div>
        <div className="details-vote-statistics-vote__status-event-quorum-condition">
          <div className="details-vote-statistics-vote__quorum">
            <p className="details-vote-statistics-vote__quorum-label">
              {constants.DETAILS_VOTE.STATISTIC_QUORUM_CONDITION}
            </p>
            <p className="details-vote-statistics-vote__quorum-note">
              {setConditionQuorum(currentEventData?.quorum, constants)}
            </p>
          </div>
          <div className="details-vote-statistics-vote__status-event">
            <p className="details-vote-statistics-vote__status-event-label">
              {constants.DETAILS_VOTE.STATISTIC_STATUS_EVENT}
            </p>
            {eventStatistics?.quorumReached === true ? (
              <p className="details-vote-statistics-vote__status-event-icon">
                <img src={iconQuorumReached} alt={constants.GENERAL.ALT_ICON} />
                {constants.DETAILS_VOTE.STATISTIC_QUORUM_SUCCESS}
              </p>
            ) : (
              <p className="details-vote-statistics-vote__status-event-icon-not-reached">
                <img
                  src={iconNotQuorumReached}
                  alt={constants.GENERAL.ALT_ICON}
                />
                {constants.DETAILS_VOTE.STATISTIC_QUORUM_NOT_SUCCESS}
              </p>
            )}
          </div>
          <div className="details-vote-statistics-vote__progress-visit-vote">
            <p className="details-vote-statistics-vote__progress-visit-vote-label">
              {constants.DETAILS_VOTE.STATISTIC_ELECTRONIC_VISIT} (
              {eventStatistics?.votedUsersPercentage}%)
            </p>
            <div className="details-vote-statistics-vote__progress">
              <div
                ref={progressBarVoteRef}
                className="details-vote-statistics-vote__progress-bar"
              ></div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="details-vote-statistics-vote-update-statistics-vote">
        {/* <button
          onClick={updateStatisticsVote}
          className="details-vote-statistics-vote-update-statistics-vote__button"
        >
          {constants.DETAILS_VOTE.STATISTIC_UPDATE_STATISTIC_EVENT_BTN}
        </button> */}
        <p className="details-vote-statistics-vote-update-statistics-vote__message">
          {isMessageUpdateStatistics}
        </p>
      </div>
      {voteTableState.length !== 0 ? (
        <>
          <p
            onClick={() => setActiveDynamicsVisit(true)}
            className={
              activeDynamicsVisit
                ? "details-vote-statistics-vote__show-more-btn hidden"
                : "details-vote-statistics-vote__show-more-btn"
            }
          >
            {constants.DETAILS_VOTE.STATISTIC_SHOW_MORE_BTN}
          </p>
          <div
            className={
              activeDynamicsVisit
                ? "details-vote-statistics-vote__dynamics-visit"
                : "details-vote-statistics-vote__dynamics-visit hidden"
            }
          >
            <p className="details-vote-statistics-vote__dynamics-visit-title">
              {constants.DETAILS_VOTE.STATISTIC_DYNAMIC_VISIT}
            </p>
            <div className="details-vote-statistics-vote__dynamics-visit-change-graph-table">
              <div
                onClick={() => onChangeGraphVisit()}
                className={
                  activeGraphVisit
                    ? "details-vote-statistics-vote__change-graph-btn active"
                    : "details-vote-statistics-vote__change-graph-btn"
                }
              ></div>
              <div
                onClick={() => onChangeTableVisit()}
                className={
                  activeTableVisit
                    ? "details-vote-statistics-vote__change-table-btn active"
                    : "details-vote-statistics-vote__change-table-btn"
                }
              ></div>
            </div>
            {activeGraphVisit && (
              <div className="details-vote-statistics-vote__dynamics-visit-graph">
                <div className="details-vote-statistics-vote__visit-graph-explanations">
                  <div className="details-vote-statistics-vote__visit-graph-explanations-general-visit">
                    <img
                      src={iconBlueDashGraph}
                      alt={constants.GENERAL.ALT_ICON}
                    />
                    <p>{constants.DETAILS_VOTE.STATISTIC_GENERAL_VISIT}</p>
                  </div>
                  <div className="details-vote-statistics-vote__visit-graph-explanations-voting-visit">
                    <img
                      src={iconGreenDashGraph}
                      alt={constants.GENERAL.ALT_ICON}
                    />
                    <p>{constants.DETAILS_VOTE.STATISTIC_VISIT_VOTING}</p>
                  </div>
                </div>
                <div className="details-vote-statistics-vote__visit-graph">
                  <div className="details-vote-statistics-vote__hidden-block"></div>
                  <Chart
                    width={"914px"}
                    height={"453px"}
                    type={"line"}
                    data={{
                      labels:
                        voteTableState.statistics?.length === 1
                          ? [
                              formatTime(
                                voteTableState.statistics[0]?.startTime
                              ),
                              formatTime(voteTableState.statistics[0]?.endTime),
                            ]
                          : formatTime(voteTableState.statistics[0]?.startTime),
                      datasets: [
                        {
                          label: "Общая явка",
                          data:
                            voteTableState.statistics?.length === 1
                              ? [0, isChartSumPercentage]
                              : isChartSumPercentage,
                          fill: false,
                          borderColor: "#0084FE",
                          tension: 0.5,
                          pointRadius: 0.8,
                          pointBackgroundColor: "#0084FE",
                          borderWidth: 2,
                          backgroundColor: "#0084FE",
                        },
                        {
                          label: "Явка голосующих",
                          data:
                            voteTableState.statistics?.length === 1
                              ? [0, isChartSumPercentage]
                              : isChartTurnoutPercentage,
                          fill: false,
                          borderColor: "#4ED4A9",
                          tension: 0.5,
                          pointRadius: 0.8,
                          pointBackgroundColor: "#4ED4A9",
                          borderWidth: 2,
                          backgroundColor: "#4ED4A9",
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            )}
            {activeTableVisit && (
              <div className="details-vote-statistics-vote__dynamics-visit-table">
                {/*<PaginationBlock/>*/}
                <div className="details-vote-statistics-vote__visit-table">
                  <div className="details-vote-statistics-vote__visit-table-header">
                    <p className="details-vote-statistics-vote__visit-table-header-time">
                      {constants.DETAILS_VOTE.STATISTIC_TABLE_TIME}
                    </p>
                    <p className="details-vote-statistics-vote__visit-table-header-visit-time">
                      {constants.DETAILS_VOTE.STATISTIC_TABLE_VISIT_TIME}
                    </p>
                    <p className="details-vote-statistics-vote__visit-table-header-general-visit">
                      {constants.DETAILS_VOTE.STATISTIC_GENERAL_VISIT}
                    </p>
                  </div>
                  {voteTableState.statistics?.length !== 0 ? (
                    <>
                      {voteTableState.statistics?.map((el, i) => (
                        <div
                          key={i}
                          className="details-vote-statistics-vote__visit-table-row"
                        >
                          <p className="details-vote-statistics-vote__visit-table-row-colimn-time">
                            {formatTime(el?.startTime)} -{" "}
                            {formatTime(el?.endTime)}
                          </p>
                          <p className="details-vote-statistics-vote__visit-table-row-colimn-visit-time">
                            {el?.turnoutPercentage}%
                          </p>
                          <p className="details-vote-statistics-vote__visit-table-row-colimn-general-visit">
                            {el?.cumSumPercentage}%
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="details-vote-statistics-vote__empty-table-date">
                      <p>
                        {
                          constants.DETAILS_VOTE
                            .STATISTIC_EMPTY_DATA_VISIT_VOTERS_TABLE
                        }
                      </p>
                    </div>
                  )}
                </div>
                {/*<PaginationBlock/>*/}
              </div>
            )}
          </div>
        </>
      ) : (
        <p className="details-vote-statistics-vote__empty-data-visit-voters">
          {constants.DETAILS_VOTE.STATISTIC_EMPTY_DATA_VISIT_VOTERS}
        </p>
      )}
      <p
        onClick={() => setActiveDynamicsVisit(false)}
        className={
          activeDynamicsVisit
            ? "details-vote-statistics-vote__roll-up-btn"
            : "details-vote-statistics-vote__roll-up-btn hidden"
        }
      >
        {constants.DETAILS_VOTE.STATISTIC_HIDE_BTN}
      </p>
    </div>
  );
};
export default DetailsVoteStatisticsVote;
