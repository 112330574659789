import qrcode from "qrcode-generator";
import "./Qr.css";

export const Qr = ({ addData = "https://v2.cryptoveche.ru/details-vote/00baaa87-9353-403b-b13d-35860803ba35?code=eyJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYXV0aC10b2tlbiIsInN1YiI6IjQxMmEwZmU0LWU4MTQtNDUwZi1hOGM1LTQwZGIwOGY5YTFlMiIsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6Nzc3NyIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6Nzc3NyIsImlhdCI6MTY5ODkyNjM1NiwiZXhwIjoxNjk4OTI5OTU2LCJhdXRob3JpdHkiOlsiUk9MRV9WT1RFUiJdLCJzY29wZSI6WyJBTEwiXX0.l8MjiI6Vm0ezmuLjGd9y3lM86iu3Ipq_PazX6tJGi98" }) => {
  const qr = qrcode(0, "L");
  qrcode.stringToBytes = qrcode.stringToBytesFuncs["UTF-8"];

  qr.addData(addData);

  qr.make();

  return (
    <div
      className="qr"
      dangerouslySetInnerHTML={{ __html: qr.createImgTag(6)}}
    ></div>
  );
};