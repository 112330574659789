import React, { useEffect, useRef, useState } from "react";
import * as excel from "xlsx";
import UsersTable from "../UsersTable/UsersTable";
import iconExcel from "../../img/AddNewOrgIconExcel.svg";
import iconEmail from "../../img/AddNewOrgIconMail.svg";
import iconExcelActive from "../../img/AddNewOrgExcelActiveIcon.svg";
import iconEmailActive from "../../img/AddNewOrgIconMailActiveIcon.svg";
import iconSuccessLoad from "../../img/AddNewOrgSuccessIcon.svg";
import iconError from "../../img/delete-icon.svg";
import * as Users from "../../Api/Users";
import { Validation } from "../../utils/Validation/Validation";
import AddNewUser from "../AddNewUser/AddNewUser";
import AddNewUser2 from "../AddUsersNEW/AddUsers";

const AddUsers = (props) => {
  const { constants, requestHelper, changeUsersToAddArr, usersToAdd } = props;

  const usersTextarea = Validation();
  const [activeMailBtn, setActiveMailBtn] = useState(false);
  const [activeExcelBtn, setActiveExcelBth] = useState(false);
  const [usersToFind, setUsersToFind] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState(
    constants.ADD_NEW_ORG.ADD_NEW_ORG_SELECT_FILE
  );
  const [isExcelFileSelected, setExcelFileSelected] = useState(false);
  const [isExcelFileSelectedError, setExcelFileSelectedError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const deleteUserButtonText = constants.ADD_NEW_ORG.ADD_NEW_ORG_DELETE_BTN;
  const deleteUserButtonTextMobile =
    constants.ADD_NEW_ORG.ADD_NEW_ORG_DELETE_BTN_MOBILE;
  const deleteUserId = "";
  const regex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  const [notFoundedUsers, setNotFoundedUsers] = useState("");
  const [currentGroupUsers, setCurrentGroupUsers] = useState([]);
 
  const onExcelBtnClick = () => {
    setActiveMailBtn(false);
    setActiveExcelBth(true);
    setEmailErrorMessage('');
  };

  const onEmailBtnClick = () => {
    setActiveMailBtn(true);
    setActiveExcelBth(false);
    setSelectedFileName(constants.ADD_NEW_ORG.ADD_NEW_ORG_SELECT_FILE);
    setExcelFileSelected(false);
    setExcelFileSelectedError(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (usersToFind.length !== 0) {
      requestHelper(Users.findUsers, usersToFind)
        .then((data) => {
  
          let users = [];
    
          const notFoundedArray = [];

          window.DEBUG_DATA = data;

          var my_promises = [];
         
          for (let [email, user] of Object.entries(data)) {
            if (user === null) {
              //CreateUserFromLoaderExcelMap(email, users);
              if (window.LoadedExcelMap[email]) {
                user = {};
                Object.assign(user, window.LoadedExcelMap[email]);
                var my_promise = requestHelper(
                  Users.addNewUser,
                  window.LoadedExcelMap[email]
                ).then((res) => {
                    console.log('res', res)
                    if (res?.status && !res?.ok) {
                      console.log("Ошибка при добавлении пользователя");
                    } else {
                      //window.LoadedExcelMap[email]["isAdmin"] =  false;
                      //window.LoadedExcelMap[email]["isChecked"] =false;

                      //isMounted && users.push(window.LoadedExcelMap[email]);

                      //user =  window.LoadedExcelMap[email];
                      console.log("User props:");
                      const newUser = {
                        id: res.id,
                        email: user.email,
                        firstName: user.first_name
                          ? user.first_name
                          : `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}`,
                        lastName: user.last_name
                          ? user.last_name
                          : `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}`,
                        secondName: user.second_name
                          ? user.second_name
                          : `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}`,
                        isAdmin: false,
                        isChecked: false,
                        userFields: user.userFields,
                      };
                      users.push(newUser);
                    }
                  })
                  .catch((err) => {
                    throw new Error(err.message);
                  });
              } else {
                  setEmailErrorMessage('Есть пользователи, которые не добавлены в систему!')
                  return users
              }
              my_promises.push(my_promise);
            } else {
              const newUser = {
                id: user.id,
                email: user.email,
                firstName: user.lastName
                  ? user.firstName
                  : `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}`,
                lastName: user.lastName
                  ? user.lastName
                  : `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}`,
                secondName: user.lastName
                  ? user.secondName
                  : `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}`,
                isAdmin: false,
                isChecked: false,
                userFields: user.userFields,
              };
              users.push(newUser);
            }
          }
          if (my_promises.length > 0) {
            Promise.all(my_promises).then(() => {
              const filtredArray = [];
              users.forEach((item) => {
                const findItem = currentGroupUsers.find(
                  (el) => el.id === item.id
                );
                if (findItem === undefined) {
                  isMounted && filtredArray.push(item);
                }
              });

              setNotFoundedUsers(notFoundedArray.join(", "));
              isMounted && setCurrentGroupUsers([...currentGroupUsers, ...filtredArray]);
              changeUsersToAddArr(filtredArray);
              
            });
          } else {
            const filtredArray = [];
            users.forEach((item) => {
              const findItem = currentGroupUsers.find(
                (el) => el.id === item.id
              );
              if (findItem === undefined) {
                filtredArray.push(item);
              } else {
                filtredArray.push(findItem)
              }
            });
            setNotFoundedUsers(notFoundedArray.join(", "));
            isMounted && setCurrentGroupUsers([...new Set([...currentGroupUsers, ...filtredArray])]);
            changeUsersToAddArr([...new Set([...usersToAdd, ...filtredArray])]);
          }
        })
        .catch((err) => {
          setEmailErrorMessage('Есть пользователи, которые не добавлены в систему!')
          throw new Error(err?.message);
        });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [usersToFind]);

  function CreateUserFromLoaderExcelMap(email, users) {
    if (window.LoadedExcelMap[email]) {
      requestHelper(Users.addNewUser, window.LoadedExcelMap[email])
        .then((res) => {
          if (res.status && !res.ok) {
            console.log("Ошибка при добавлении пользователя");
          } else {
            users.push(res);
          }
        })
        .catch((err) => {
          throw new Error(err.message);
        });
    }
  }

  function onSelectFileHandler(e) {
    const files = e.target.files,
      f = files[0];
    setSelectedFileName(files[0]?.name);
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      const workbook = excel.read(data, { type: "binary" });
      const wsname = workbook.SheetNames[0];
      const ws = workbook.Sheets[wsname];
      const dataWs = excel.utils.sheet_to_json(ws, { header: 1 });

      const resultArr = dataWs.reduce(function (accum, currentVal) {
        if (!currentVal[0] || !currentVal[1]) return accum;

        currentVal = {
          email: currentVal[0],
          name: currentVal[1],
        };

        const fioItem = currentVal.name.split(" ");
        accum[currentVal.email] = {
          email: currentVal.email,
          first_name: fioItem[1],
          last_name: fioItem[0],
          second_name: fioItem[2],
        };
        return accum;
      }, {});

      //Не знаю реакт, соответвенно кладу объект глобально, что бы когда мы не найдем, можно было создать.
      window.LoadedExcelMap = resultArr;

      const uniqArray = Object.keys(resultArr);

      if(uniqArray.length !== 0) {
        setExcelFileSelected(true);
      } else {
        setExcelFileSelectedError(true);
      }
      setUsersToFind(uniqArray);
    };
    reader.readAsBinaryString(f);
  }


  /*
  function onSelectFileHandler(e) {
    var files = e.target.files,
      f = files[0];
    setSelectedFileName(files[0].name);
    setExcelFileSelected(true);
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      var workbook = excel.read(data, { type: "binary" });
      const uploadedUsers = workbook.Strings.map((user) => user.h);
      const filteredUsers = uploadedUsers.filter((user) => user !== undefined);
      const validUsersEmails = [];
      filteredUsers.forEach((user) => {
        if (regex.test(String(user).toLowerCase())) {
          validUsersEmails.push(user);
        }
      });
      const uniqArray = [...new Set(validUsersEmails)];
      setUsersToFind(uniqArray);
    };
    reader.readAsBinaryString(f);
  }

*/

  function findUsers() {
    const usersArray = usersTextarea.value.trim().split(/(?:\n| |,)+/);
    const validUsersEmails = [];
    usersArray.forEach((user) => {
      if (regex.test(String(user).toLowerCase())) {
        validUsersEmails.push(user);
      }
    });
    const uniqArray = [...new Set(validUsersEmails)];
    if (uniqArray.length !== 0) {
      setUsersToFind(uniqArray);
      setEmailErrorMessage("");
    } else {
      setEmailErrorMessage(constants.ADD_NEW_ORG.ADD_NEW_ORG_EMAIL_ERROR);
    }
  }

  function onRemoveUserClick(userForRemove) {
    const filteredUsers = usersToAdd.filter(
      (user) => user.id !== userForRemove.id
    );
    changeUsersToAddArr(filteredUsers);
    setCurrentGroupUsers(filteredUsers)
  }

  function handleChangeSuperUser(userForChange) {
    const foundUser = usersToAdd.find((user) => user.id === userForChange.id);
    const filteredUsers = usersToAdd.filter(
      (user) => user.id !== userForChange.id
    );
    if (foundUser.isAdmin === false) {
      foundUser.isAdmin = true;
    } else {
      foundUser.isAdmin = false;
    }
    filteredUsers.push(foundUser);
    changeUsersToAddArr(filteredUsers);
  }

  // return <AddNewUser2 requestHelper={requestHelper} />;

  return (
    <div className="add-users">
      <AddNewUser requestHelper={requestHelper} />

      <div className="add-new-organization__import-excel-add-email-buttons">
        <div
          onClick={onExcelBtnClick}
          className={
            activeExcelBtn
              ? "import-excel-add-email-buttons__import-excel-btn active"
              : "import-excel-add-email-buttons__import-excel-btn"
          }
        >
          <img
            alt={constants.GENERAL.ALT_ICON}
            src={activeExcelBtn ? iconExcelActive : iconExcel}
            className="import-excel-btn__excel-icon"
          />
          <p>{constants.ADD_NEW_ORG.ADD_NEW_ORG_IMPORT_EXCEL_BTN}</p>
        </div>
        <div
          onClick={onEmailBtnClick}
          className={
            activeMailBtn
              ? "import-excel-add-email-buttons__add-email-btn active"
              : "import-excel-add-email-buttons__add-email-btn"
          }
        >
          <img
            alt={constants.GENERAL.ALT_ICON}
            src={activeMailBtn ? iconEmailActive : iconEmail}
            className="add-email-btn__email-icon"
          />
          <p className="add-email-btn__button-desktop">
            {constants.ADD_NEW_ORG.ADD_NEW_ORG_ADD_USERS_MAIL_BTN}
          </p>
          <p className="add-email-btn__button-mobile">
            {constants.ADD_NEW_ORG.ADD_NEW_ORG_ADD_USERS_MAIL_BTN_MOBILE}
          </p>
        </div>
      </div>
      {activeMailBtn && (
        <div className="add-new-organization__mail-addresses-users">
          <label className="mail-addresses-users__label-mail">
            {constants.ADD_NEW_ORG.ADD_NEW_ORG_MAIL_ADDRESS_USERS}
            <span className="name-org-input__red-star"> *</span>
          </label>
          <textarea
            className="mail-addresses-users__input-field"
            type="text"
            onChange={usersTextarea.onChange}
            value={usersTextarea.value}
          />
          <p className="add-new-organization__error-message">
            {emailErrorMessage}
          </p>
          <button
            className="mail-addresses-users__search-base-users"
            onClick={findUsers}
          >
            {constants.ADD_NEW_ORG.ADD_NEW_ORG_SEARCH_USERS_BASE_BTN}
          </button>
        </div>
      )}
      {activeExcelBtn && (
        <div className="add-new-organization__download-file-load-button">
          <label className="download-file-load-button__label">
            {constants.ADD_NEW_ORG.ADD_NEW_ORG_DOWNLOAD_FILE_LABEL}
            <span className="name-org-input__red-star"> *</span>
          </label>
          <div className="add-new-organization__excel-add-container">
            <input
              className="add-new-organization__excel-add-input"
              id="excel__file"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => onSelectFileHandler(e)}
            />
            <label
              htmlFor="excel__file"
              className="add-new-organization__excel-add-input-container"
            >
              <div className="add-new-organization__excel-add-input-file-name-container">
                <p
                  className={`add-new-organization__excel-add-input-file-name-text ${
                    isExcelFileSelected &&
                    "add-new-organization__excel-add-input-file-name-text_selected"
                  }`}
                >
                  {selectedFileName}
                </p>
              </div>
              <div className="add-new-organization__excel-add-input-button">
                <p className="add-new-organization__excel-add-input-button-text">
                  {constants.ADD_NEW_ORG.ADD_NEW_ORG_LOAD_BTN}
                </p>
              </div>
            </label>
            {isExcelFileSelected && (
                  <div className="field-file-load-button__success-info">
                    <img
                        className="field-file-load-button__icon-success"
                        src={iconSuccessLoad}
                        alt={iconSuccessLoad}
                    />
                    <p className="field-file-load-button__label">
                      {constants.ADD_NEW_ORG.ADD_NEW_ORG_SUCCESS_INFO}
                    </p>
                  </div> )}
            {isExcelFileSelectedError && (
                <div
                    className="field-file-load-button__success-info">
                  <img
                      className="field-file-load-button__icon-success"
                      src={iconError}
                      alt={iconError}
                  />
                  <p className="field-file-load-button__label-error">
                    {constants.ADD_NEW_ORG.ADD_NEW_ORG_ERROR_FILE}
                  </p>
                </div>)}
          </div>
        </div>
      )}
      <UsersTable
          constants={constants}
          onRemoveUserClick={onRemoveUserClick}
          handleChangeSuperUser={handleChangeSuperUser}
          users={usersToAdd}
          deleteUserButtonText={deleteUserButtonText}
          deleteUserButtonTextMobile={deleteUserButtonTextMobile}
          deleteUserId={deleteUserId}
          adminId={""}
      />
    </div>
  );
};

export default AddUsers;
