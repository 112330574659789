import React from "react";
import { useQuery } from "@tanstack/react-query";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {getAllSettingAuthDomains} from "../../Api/auth-service";

const AuthenticationSettingsTable = () => {

    const getDomains = useQuery({
        queryKey: ['list-auth-org'],
        queryFn: getAllSettingAuthDomains,
    })

    return (
        <div className="subscription-settings-table">
            <p className="subscription-settings-table__header">Список доменов</p>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} size="large" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Имя домена</TableCell>
                            <TableCell align="center">email/password</TableCell>
                            <TableCell align="center">phone/password</TableCell>
                            <TableCell align="center">email/password/code</TableCell>
                            <TableCell align="center">phone/password/code</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getDomains?.data?.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row?.subdomain}
                                </TableCell>
                                <TableCell align="center">
                                    <div
                                        className={`subscription-settings__message-checkbox-not-check subscription-settings-table-checkbox ${
                                            row.authTypes.find(el => el === "email/password") &&
                                            "subscription-settings__message-checkbox_active-not-check"
                                        }`}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <div
                                        className={`subscription-settings__message-checkbox-not-check subscription-settings-table-checkbox ${
                                            row.authTypes.find(el => el === "phone/password") &&
                                            "subscription-settings__message-checkbox_active-not-check"
                                        }`}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <div
                                        className={`subscription-settings__message-checkbox-not-check subscription-settings-table-checkbox ${
                                            row.authTypes.find(el => el === "email/password/code") &&
                                            "subscription-settings__message-checkbox_active-not-check"
                                        }`}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <div
                                        className={`subscription-settings__message-checkbox-not-check subscription-settings-table-checkbox ${
                                            row.authTypes.find(el => el === "phone/password/code") &&
                                            "subscription-settings__message-checkbox_active-not-check"
                                        }`}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
export default AuthenticationSettingsTable;