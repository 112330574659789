import React, { useState, useEffect } from "react";
import logo from "../../img/Auth_logo_crypto_veche.svg";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import "./Authorization.scss";
import { useNavigate } from "react-router-dom";
import { Validation } from "../../utils/Validation/Validation";
// import { config } from "../../config";
import row_input_select_role from "../../img/Auth_icon_row_select_role.svg";
import errorPage from "../../img/DetailsVoteObserversIconEmptyComponent.svg";
import { useAuthTypes } from "../../hooks/useAuthTypes";
import AuthByEmail from "./AuthByEmail";
import AuthByPhone from "./AuthByPhone";

import * as Auth from "../../Api/Auth";
import * as Events from "../../Api/Events";
import AuthorizationTwoFactor from "../AuthorizationTwoFactor/AuthorizationTwoFactor";

import { constants } from "../../utils/Localization/Ru/constants";

const Authorization = (props) => {
  const {
    authError,
    preLoaderBtn,
    config,
    setAuthError,
    requestHelper,
    setLoggedIn,
    addCurrentUser,
    createUserName,
    setOffset,
    joinEvent,
    joinId,
    eventWaitingIdByLink,
    eventQuestionsIdByLink,
    eventResultIdByLink,
    handleCurrentEvents,
    showEventResult,
    authErrorMessage,
    subscribeToNewEvents,
    setUserAuthAs,
  } = props;

  const { setTwoFactorAuthLoginDate } = useCurrentUserContext();

  const [optionValue, setOptionValue] = useState(
      constants.AUTH.AUTH_ROLE_ADMIN
  );
  const [isAuthOptionsActive, setAuthOptionsActive] = useState(false);

  function handleShowAuthOptions() {
    if (isAuthOptionsActive) {
      setAuthOptionsActive(false);
    } else {
      setAuthOptionsActive(true);
    }
  }

  const navigate = useNavigate();

  const [isRememberMe, setIsRememberMe] = useState(true);
  const [authAs, setAuthAs] = useState("ROLE_ADMIN");

  const [activeBtnDisactiveSMS, setActiveBtnDisactiveSMS] = useState(false);

  const [disactiveInputPhone, setDisactiveInputPhone] = useState(false);

  const [activeSMSFieldConfirmBtn, setActiveSMSFieldConfirmBtn] =
      useState(false);
  const authTypes = useAuthTypes();
  const isLoadedTypes = !!Object.keys(authTypes).length;
  const [emailView, setEmailView] = useState(authTypes.email);
  const phoneView = !emailView; // вкладка телефона активна, только если у нас нет входа по почте
  const [isTwoFactor, setTwoFactor] = useState(false);
  const [isNotActiveBtn, setActiveBtn] = useState(false);

  useEffect(() => {
    let isMounted = true;
    isMounted && setEmailView(authTypes.email);
    return () => {
      isMounted = false;
    }
  }, [authTypes, isLoadedTypes]);

  const [authPhoneCodeError, setAuthPhoneCodeError] = useState("");

  const activeTypeDisplay = config.type;

  const email = Validation();
  const emailPassword = Validation();
  const emailCode = Validation();

  const phone = Validation();
  const phonePassword = Validation();
  const phoneCode = Validation();

  const [passwordType, setPasswordType] = useState("password");

  const getPassword = () => {
    if (passwordType === "password") {
      return emailView ? emailPassword.value : phonePassword.value;
    }

    if (passwordType === "temporary-code") {
      return emailView ? emailCode.value : phoneCode.value;
    }

    return "";
  };

  const onMethodEnterTwoFactor = async () => {
    const getPass = getPassword();

    let dataLogin = {
      username: emailView ? email.value : phone.value,
      password: getPass,
      type: `${emailView ? "email" : "phone"}/${passwordType}`,
      authAs: authAs,
    }

    const loginResponse = await Auth.getVerificationCode({
      username: emailView ? email.value : phone.value,
      password: getPass,
      type: `${emailView ? "email" : "phone"}/${passwordType}`,
      authAs: authAs,
    });

    if (loginResponse?.status === 400 || loginResponse?.status === 403) {
      setAuthError("Неверный логин или пароль");
      // setPreloaderAuthBtn(false);
      return;
    }

    if (loginResponse?.status === 401) {
      setAuthError("Неверный логин или пароль");
      // setPreloaderAuthBtn(false);
      return;
    }

    if (loginResponse?.status === 429) {
      setAuthError('Превышен лимит отправляемых запросов, попробуйте позже');
      return;
    }

    if (loginResponse?.status) {
      setAuthError(`Ошибка сервера: ${loginResponse.status}`);
      return;
    }

    setActiveBtn(true);
    setAuthError('');
    setTwoFactorAuthLoginDate(dataLogin)
    setTwoFactor(true);
  }

  const onMethodEnterLogin = async () => {
    setAuthError("");
    const loginResponse = await Auth.login({
      username: emailView ? email.value : phone.value,
      password: getPassword(),
      type: `${emailView ? "email" : "phone"}/${passwordType}`,
      authAs: authAs,
    });

    if (loginResponse?.status === 401) {
      setAuthError("Неверный логин или пароль");
      return;
    }

    if (loginResponse?.status === 403) {
      setAuthError("Выбранная роль для вас недоступна");
      return;
    }

    if (loginResponse?.status) {
      setAuthError(`Ошибка с кодом ${loginResponse?.status}`);
      return;
    }

    setAuthError('');
    await onSuccessEnter(loginResponse);

  }

  const onResetCloseModal = () => {
    setActiveBtn(false);
    setTwoFactor(false);
  }

  const onSuccessEnter = async (loginResponse) => {

    // setPreloaderAuthBtn(false);

    const userData = await Auth.getMe(loginResponse.accessToken);

    if (loginResponse.accessToken && loginResponse.refreshToken) {
      localStorage.setItem("accessToken", loginResponse.accessToken);
      localStorage.setItem("refreshToken", loginResponse.refreshToken);
    }

    const user = JSON.stringify(userData);

    isRememberMe && localStorage.setItem("user", user);
    setLoggedIn(true);
    addCurrentUser(userData);
    createUserName(userData);
    setOffset(userData.utcOffset);
    setUserAuthAs(userData.authorities[0].role);
    localStorage.setItem("authAs", userData.authorities[0].role);

    const subUserId = `${userData.authorities[0].role + userData.id}`;

    subscribeToNewEvents(subUserId, config);
    navigate("/");

    if (joinId) {
      joinEvent(joinId);
      navigate("/");
      return;
    }

    if (eventWaitingIdByLink) {
      handleCurrentEvents({ id: eventWaitingIdByLink }, true);
      return;
    }

    if (eventQuestionsIdByLink) {
      const data = { id: eventQuestionsIdByLink };
      requestHelper(Events.getEvent, data)
          .then((res) => {
            // тут вопросик мб не правильная проверка в конце
            handleCurrentEvents(
                data,
                res.status === "ended" ||
                res.status === "quorum_unpresant" ||
                !res.isRegistered
            );
          })
          .catch((err) => {
            throw new Error(err?.message || "Неизвестная ошибка");
          });
      return;
    }

    if (eventResultIdByLink) {
      showEventResult({ id: eventResultIdByLink });
      return;
    }
    // navigate("/");
  };

  function onSelectAuthOption(name, value) {
    setOptionValue(name);
    setAuthAs(value);
  }

  const onEnterHandler = (evt) => {
    evt.preventDefault();
    if(authTypes.emailPasswordAndCodeTwoFactor || authTypes.phonePasswordAndCodeTwoFactor) {
      onMethodEnterTwoFactor();
    } else {
      onMethodEnterLogin();
    }
  };
  return (
      isLoadedTypes ? (
          <div className="wrapper-auth">
            <div className="container-auth">
              <div className="main-block">
                <div className="main-block__auth">
                  <div className="auth__title">
                    <h3>Авторизация</h3>
                    {/*<div><span>РУС</span><span>ENG</span></div>*/}
                  </div>
                  <form className="auth__form">
                    <div className="auth-form__select-role-container">
                      <label className="select-role__label">
                        {constants.AUTH.AUTH_LABEL_SELECT_ROLE}
                      </label>
                      <div
                          className="select-role__time-zone-select-container"
                          onClick={handleShowAuthOptions}
                      >
                        <p className="select-role__time-zone-select-value">
                          {optionValue}
                        </p>
                        <img
                            className="select-role__time-zone-select-arrow"
                            src={row_input_select_role}
                            alt="Стрелочка открытия меню"
                        />
                        <div
                            className={`select-role__time-zone-options-container ${
                                isAuthOptionsActive &&
                                "select-role__time-zone-options-container_active"
                            }`}
                        >
                          {constants.AUTH_OPTIONS.map((option, index) => (
                              <p
                                  key={index}
                                  className="select-role__time-zone-option"
                                  onClick={() =>
                                      onSelectAuthOption(option.name, option.value)
                                  }
                              >
                                {option.name}
                              </p>
                          ))}
                        </div>
                      </div>
                    </div>
                    {authTypes.emailAndPhone && (
                        <div className="auth-form__select-email-phone-container  form__views">
                          Выполнить вход по:
                          <span
                              onClick={() => setEmailView(false)}
                              className={
                                emailView
                                    ? "auth-form__select-email active"
                                    : "auth-form__select-email"
                              }
                          >
                      E-mail
                    </span>
                          /
                          <span
                              onClick={() => setEmailView(true)}
                              className={
                                phoneView
                                    ? "auth-form__select-phone active"
                                    : "auth-form__select-phone"
                              }
                          >
                      Телефону
                    </span>
                        </div>
                    )}

                    {((authTypes && emailView) || authTypes.emailOnly) && (
                        <AuthByEmail
                            authError={authError}
                            authTypes={authTypes}
                            email={email}
                            emailPassword={emailPassword}
                            emailCode={emailCode}
                            setPasswordType={setPasswordType}
                        />
                    )}

                    {((authTypes && !emailView) || authTypes.phoneOnly) && (
                        <AuthByPhone
                            authError={authError}
                            setAuthError={setAuthError}
                            authTypes={authTypes}
                            phone={phone}
                            phonePassword={phonePassword}
                            phoneCode={phoneCode}
                            setPasswordType={setPasswordType}
                        />
                    )}

                    <div className="form__checkbox">
                      <div className="checkbox__link-forget-pass-login-secretary">
                        {activeTypeDisplay && (
                            <a
                                href={`${config.client_url}/forget`}
                                className="link-forget-pass__link-page-forget"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                              {constants.AUTH.AUTH_FORGET_PASS}
                            </a>
                        )}
                      </div>
                      <label className="auth-form__checkbox-container">
                        <div className="checkbox_container">
                          <input
                              type="checkbox"
                              checked={isRememberMe}
                              onChange={() => setIsRememberMe(!isRememberMe)}
                          />
                          <span className="checkmark" />
                        </div>
                        <span className="auth__remember-me-checklabel">
                      Запомнить меня
                    </span>
                      </label>
                    </div>
                    <div
                        className={
                          activeTypeDisplay ? "form__button" : "form__button-spbu"
                        }
                    >
                      <button
                          disabled={isNotActiveBtn}
                          className={
                            preLoaderBtn
                                ? "form__button-input-btn active"
                                : "form__button-input-btn"
                          }
                          onClick={onEnterHandler}
                          type="submit"
                      >
                        {preLoaderBtn ? "Загрузка..." : "Войти"}
                      </button>
                      <div className="form__error-phone-code">
                        {authPhoneCodeError}
                      </div>
                      {config.enable_esia && (
                          <a
                              className="form__button__link-gosuslugi"
                              href="https://esia.gosuslugi.ru/login/"
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                            Войти через ЕСИА
                          </a>
                      )}
                    </div>

                    {activeTypeDisplay && (
                        <div className="main-block__mobile-link-reg">
                          <span>Ещё нет аккаунта?</span>
                          <a
                              href={`${config.client_url}/registration`}
                              target="_blank"
                          >
                            Зарегистрироваться
                          </a>
                        </div>
                    )}
                  </form>
                </div>
                <div className="main-block__reg">
                  <div className="reg__logo">
                    <img className="reg__logo-main" src={logo} alt="Логотип" />
                  </div>
                  <div className="reg__title">
                    Панель управления системой электронных голосований
                  </div>
                  {activeTypeDisplay && (
                      <div className="reg__button">
                        <span>Еще нет аккаунта?</span>
                        <a href={`${config.client_url}/registration`} target="_blank">
                          Зарегистрироваться
                        </a>
                      </div>
                  )}
                </div>
              </div>
              {isTwoFactor && <AuthorizationTwoFactor
                  onSuccessEnter={onSuccessEnter}
                  isTwoFactor={isTwoFactor}
                  onResetCloseModal={onResetCloseModal}
                  authAs={authAs}
              />}
            </div>
          </div>
      ) : (
          <div className="auth-error-page">
            <img className="auth-error-page--img" src={errorPage} alt={'иконка'} />
            <p className="auth-error-page--label">Ошибка получения данных с сервера!</p>
          </div>
      )
  );
};

export default Authorization;