export const fixedColors = [
    "#4ED4A9",
    "#F9C521",
    "#FF4970",
    "#0084FE",
    "#373C4E",
    "#bdb76b",
    "#FF00FF",
    "#ffa500",
    "#00ffff",
    "#808000",
    "#4b0082",
    "#979A9A",
    "#A04000",
    "#5499C7"
];