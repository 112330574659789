import { config } from "../config";

const API_URL = config.java_api_url;
const API_URL_AUTH = config.auth_api_url;

export const getNewTokens = (refreshToken) => {
  return fetch(`${API_URL}/refresh_tokens`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const authorize = (email, password, authAs) => {
  return fetch(`${API_URL}/auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
      authAs: authAs,
    }),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      if (data.jwt) {
        localStorage.setItem("jwt", JSON.stringify(data.jwt));
      }
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      }
    });
};

// new OAUTH2

export const login = async ({
  username = "",
  password = "",
  type,
  confirmationCode = "",
  token = "",
  authAs = "",
}) => {
  return await fetch(`${API_URL_AUTH}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
      type: type,
      token: token,
      confirmationCode: confirmationCode,
      authAs: authAs,
      realm: config.realm,
    }),
  })
    .then((res) => {
      return res.ok ? res.json() : res;
    })

    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      }
    });
};

export const getMe = async (accessToken) => {
  return fetch(`${API_URL_AUTH}/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getAuthType = async () => {
  return await fetch(`${API_URL_AUTH}/auth-types`, {
    method: "GET",
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

// чето старое?
export const getPhoneCodeAuth = async (phone) => {
  return fetch(`${API_URL}/auth/generate_phone_code/${phone}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};
//
/*---Для двухфакторной авторизации---*/

export const getVerificationCode = async ({
                                            username = "",
                                            password = "",
                                            type,
                                            token = "",
                                            authAs = ""
                                          }) => {
  return await fetch(`${API_URL_AUTH}/getVerificationCode`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
      type: type,
      authAs: authAs,
      realm: config.realm,
    }),
  })
      .then((res) => {
        return res.ok ? res.json() : res;
      })

      .then((data) => {
        return data;
      })
      .catch((err) => {
        if (err.status === 500) {
          throw new Error("Сервер временно недоступен");
        }
      });
};
